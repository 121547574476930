/*----------------------------------------*/
/*  02. Header CSS
/*----------------------------------------*/
header.header {
    background-color: $white;
    position: static;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    
    // Sticky Header
    &.is-sticky {
        position: fixed;
        box-shadow: 0 5px 10px rgba($black, 0.1);
        animation-duration:1s;
        animation-fill-mode:both;
        animation-name: slideInDown;
        animation-duration: 0.5s;
        & .header-top {
            display: none;
        }
        & .header-middle {
            display: none;
        }
        & .main-menu.main-menu-two{
            position: static;
            margin-bottom: 0;
        }
    }
}

/*-- Header Top --*/
.header-top {
    background-color: #222;
    & > .container {
        & > .row {
            & > .col {
                flex-grow: 0;
                margin: 6px 0;
                display: flex;
                &:first-child {
                    flex-grow: 1;
                }
                // Responsive
                @media #{$small-mobile}{
                    flex: 1 0 100%;
                    justify-content: center;
                    margin: 0;
                    &:first-child {
                        margin-top: 10px;
                        margin-bottom: 5px;
                    }
                    &:last-child {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
/*-- Header Top Links --*/
.header-top-links {
    & ul {
        display: flex;
        & li {
            font-size: 14px;
            line-height: 26px;
            margin-right: 20px;
            display: flex;
            align-items: center;
            color: $white;
            &:last-child {
                margin-right: 0;
            }
            & a {
                display: block;
                &:hover{
                    color: $white;
                }
            }
        }
    }
}
.header-top-links-three{
    & ul {
        display: flex;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            left: -43%;
            top: 0;
            background-color: #111111;
        }
        & li {
            line-height: 26px;
            padding-left: 20px;
            padding-right: 30px;
            display: flex;
            align-items: center;
            color: rgba(255,255,255,.6);
            position: relative;
            &::before, &::after{
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 25px 0 25px 20px;
                border-color: transparent;
                position: absolute;
                top: 0;
            }
            &::before{
                border-left-color: #111111;
                right: 0;
                z-index: 9;
            }
            &::after{
                border-left-color: rgba(255,255,255,.1);
                right: -2px;
                z-index: 1;
            }
            & i {
                margin-right: 10px;
                line-height: 26px;
                padding-top: 2px;
                color: $theme-color;
            }
            & a {
                display: block;
            }
            // Responsive
            @media #{$extra-small-mobile}{
                font-size: 12px;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
/*-- Header Top Social --*/
.header-top-menu {
    & > ul{
        text-align: right;
        & > li{
            display: inline-block;
            padding: 9px 30px;
            border-left: 1px solid rgba(238,238,238,.1);
            position: relative;
            text-align: left;
            &:hover{
                & .ht-dropdown{
                    top: 100%;
                    opacity: 1;
                    visibility: visible;
                }
            }
            & > a{
                font-size: 14px;
                display: block;
                color: $white;
                &:hover{
                    color: $white;
                }
            }
        }
    }
}
/* Header top Dropdown */
.ht-dropdown{
    position: absolute;
    top: 120%;
    left: 0;
    width: 200px;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: all .5s cubic-bezier(.215,.61,.355,1);
    & > li{
        padding: 5px 10px;
        border-top: 1px solid rgba(255,255,255,.2);
        background-color: #222;
        & a{
            font-size: 14px;
            display: block;
            color: $white;
            &:hover{
                color: $white;
            }
        }
    }
}
/* Header Middle */
.header-middle{
    background-color:#111;
    position: relative;
}
/* Header Bottom */
.header-bottom.menu-right{
    position: relative;
}
.logo{
    &.tringle-none{
        background-color: #111;
        &::before{
            display: none;
        }
        &::after{
            display: none;
        }
    }
    padding-top: 47px;
    padding-bottom: 47px;
    padding-left: 15px;
    padding-right: 20px;
    position: relative;
    z-index: 9;
    background-color: $theme-color;
    // Responsive
    @media #{$desktop-device}{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    @media #{$tablet-device}{
        padding-top: 25px;
        padding-bottom: 25px;
    }
    @media #{$large-mobile}{
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 0;
        padding-right: 0;
    }
    &::before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1000px;
        width: 1000px;
        z-index: -1;
        background-color: $theme-color;
    }
    &::after{
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 65px 0 65px 40px;
        border-color: transparent;
        border-left-color: $theme-color;
        position: absolute;
        top: 0;
        right: -40px;
        // Responsive
        @media #{$tablet-device}{
            border-width: 43px 0 43px 25px;
            right: -25px;
        }
        @media #{$large-mobile}{
            border-width: 38px 0 38px 20px;
            right: -20px;
        }
        @media #{$extra-small-mobile}{
            border-width: 35px 0 35px 20px;
            right: -20px;
        }
    }
    & a{
        display: block;
        img{
            margin-left: -20px;
            max-width: none;
            // Responsive
            @media #{$desktop-device}{
                margin-left: 0;
                width: 100%;
            }
            @media #{$extra-small-mobile}{
                margin-left: 0;
                width: 100%;
            }
        }
    }
}
.single-emergncy-contact{
    &.theme-color{
        // Responsive
        @media #{$small-mobile}{
            margin-bottom: 30px;
        }
        & .contact-icon{
            & .icon{
                width: auto;
                height: auto;
                margin-right: 20px;
                & i{
                    color: $theme-color;
                    font-size: 32px;
                }
            }
        }
        & .content{
            & h3{
                // Responsive
                @media #{$large-mobile}{
                    font-size: 16px;
                }
            }
        }
    }
    &.icon-black{
        & .contact-icon{
            & .icon{
                & i{
                    color: $heading-color;
                }
            }
        }
    }
    &.content-black{
        & .content{
            & h3{
                color: $heading-color;
            }
            & span{
                color: $heading-color;
            }
        } 
    }
    display: flex;
    align-items: center;
    & .contact-icon{
        & .icon{
            width: 30px;
            height: 45px;
            & i{
                font-size: 18px;
                margin-right: 0px;
                color: $white;
            }
        }
    }
    & .icon{
        & i{
            font-size: 32px;
            margin-right: 20px;
            color: $theme-color;
        }
    }
    & .content{
        & h3{
            font-size: 18px;
            line-height: 1.2;
            letter-spacing: normal;
            color: $white;
            font-weight: 700;
            margin-bottom: 0;
        }
        & span{
            color: rgba(255,255,255,.8);
        }
    }
}
/*-- Header Search --*/
.header-search {
    margin-left: 50px;
    position: relative;
    // Responsive
    @media #{$desktop-device}{
        margin-left: 20px;
    }
    @media #{$tablet-device}{
        margin-left: 0;
    }
    @media #{$large-mobile}{
        margin-left: 0;
    }
    @media #{$extra-small-mobile}{
        margin-left: 0;
    }
}
.header-search-toggle {
    background-color: transparent;
    border: none;
    color: $theme-color;
    padding: 0;
    line-height: 1;
    width: 30px;
    height: 30px;
    & i {
        font-size: 16px;
    }
    &.open {
        & i {
            font-size: 20px;
        }
    }
}
/*-- Search Form --*/
.header-search-form {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    background-color: $white;
    box-shadow: 0 2px 5px rgba($black, 0.15);
    z-index: 9;
    // Responsive
    @media #{$extra-small-mobile}{
        right: -85px;
    }
    & form {
        display: flex;
        & input {
            width: 250px;
            border: none;
            background-color: transparent;
            color: $body-color;
            line-height: 24px;
            padding: 13px 20px;
            // Responsive
            @media #{$extra-small-mobile}{
                width: 216px;
            }
        }
        & button {
            line-height: 24px;
            padding: 13px 15px;
            border: none;
            background-color: $theme-color;
            display: flex;
            color: $white;
            & i {
                font-size: 20px;
                line-height: 20px;
                
            }
        }
    }
}

/*-- Header Cart --*/
.header-cart {
    margin-left: 10px;
    position: relative;
    & > a {
        background-color: transparent;
        border: none;
        color: $theme-color;
        padding: 0;
        line-height: 1;
        width: 30px;
        height: 30px;
        position: relative;
        & i {
            font-size: 20px;
            margin-top: 2px;
        }
        & span {
            position: absolute;
            right: -12px;
            top: -16px;
            background-color: #2e2e2e;
            width: 20px;
            height: 20px;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            border-radius: 50%;
            color: $white;
        }
    }
    &:hover{
        & .minicart-box{
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
}
/* minicart */
.minicart-box{
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 99999;
    overflow: auto;
    padding: 30px 20px 27px;
    max-height: 700px;
    width: 360px;
    border-bottom: 3px solid $theme-color;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0 37px rgba(0,0,0,.07);
    transform: translateY(30px);
    transition: all 0.5s ease-in-out;

}
.minicart-product-wrapper{
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;  
    .single-minicart-product{
        display: flex;
        margin-bottom: 20px;
        &__image{
            flex-basis: 80px;
            border: 1px solid #ddd;
        }
        &__content{
            flex-basis: calc(100% - 80px);
            margin-left: 15px;
            position: relative;
            .close-icon{
                position: absolute;
                right: 0;
                top: 0;
                font-weight: 700;
                color: #888;
                
                i{
                     font-size: 12px;

                }

                &:hover{
                    color: $theme-color;
                }
            }

            .title{
                font-size: 16px;
                font-weight: 700;
                line-height: 1.2;
                &:hover{
                    color: $theme-color;
                }
            }

            .quantity{
                font-size: 15px;
                color: #888;

                .price{
                    font-weight: 600;
                    color: $theme-color;
                }
            }
        }
    }

}

.minicart-calculations{
    font-size: 16px;
    font-weight: 600;
    color: $heading-color;
    margin-bottom: 30px;
    text-align: center;
    .value{
        color: $theme-color;
        font-size: 18px;
    }
}

.minicart-buttons{
    text-align: center;
}

.minicart-link{
    display: inline-block;
    margin: 0 5px;

    &--cart{
        &:hover{
            background-color: $theme-color;
            color: $white;
        }
    }
    &--checkout{
        background-color: $theme-color;
        color: $white;
        &:hover{
            background-color: $theme-color;
            color: $white;
            border-color: $theme-color;
        }
    }
}
/*-- Header Social --*/
.social-menu{
    background-color: $theme-color;
    position: absolute;
    right: 0;
    padding-right: 15%;
    bottom: -29px;
    z-index: 1;
    // Responsive
    @media #{$desktop-device}{
        padding-right: 6%;
    }
}
.social-menu-top{
    position: relative;
    display: flex;
    &::before,
    &::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 29px 15px 29px 0;
        position: absolute;
        top: 0;
        z-index: 1;
    }
    &::before{
        border-color: transparent rgba(255,255,255,.2) transparent transparent;
        right: 2px;
    }
    &::after{
        border-color: transparent;
        border-right-color: #fbd232;
        right: 0;
    }
    & li{
        position: relative;
        width: auto;
        background-color: $theme-color;
        padding: 15px 30px 15px 15px;
        margin: 0;
        transition: all .5s cubic-bezier(.215,.61,.355,1);
        &:first-child{
            &::before{
                display: none;
            }
        }
        &::before,
        &::after{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 29px 15px 29px 0;
            position: absolute;
            top: 0;
            z-index: 1;
            -webkit-transition: all .5s cubic-bezier(.215,.61,.355,1);
            -moz-transition: all .5s cubic-bezier(.215,.61,.355,1);
            -o-transition: all .5s cubic-bezier(.215,.61,.355,1);
            transition: all .5s cubic-bezier(.215,.61,.355,1);
        }
        &::before{
            border-color: transparent rgba(255,255,255,.2) transparent transparent;
            left: -17px;
        }
        &::after{
            border-color: transparent;
            left: -15px;
            border-right-color: #fbd232;
        }
        & a{
            display: block;
            color: #111;
            font-size: 20px;
        }
        &:hover{
            background-color: #111111;
            &::after{
                border-right-color: #111111;
            }
            & a{
                color: $theme-color;
            }
        }
    }
}
.header-top-social {
    justify-content: flex-end;
    & a {
        margin-right: 15px;
        color: rgba(255, 255, 255, 0.8);
        &:last-child {
            margin-right: 0;
        }
        & i {
            font-size: 16px;
            display: block;
            line-height: 1;
        }
        &:hover {
            color: $theme-color;
        }
    }
}
/*-- Header Bottom --*/
.header-bottom {
    &.menu-right {
        & > .container {
            & > .row {
                align-items: center;
                position: relative;
                // Responsive
                @media #{$tablet-device}{
                    align-items: inherit;
                }
                @media #{$large-mobile}{
                    align-items: inherit;
                }
                & > .col {
                    flex-grow: 0;
                    position: static;
                    &:first-child {
                        flex-grow: 1;
                    }
                }
            }
        }
    }
}
/*-- Main Menu --*/
.main-menu {
    &.main-menu-two{
        position: relative;
        margin-bottom: -32px;
        background-color: #fff;
        z-index: 9;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: -62%;
            width: 100%;
            height: 100%;
            background-color: #fff;
            z-index: -1;
        }
        & > ul{
            & > li{
                border-bottom: 0;
                &::before{
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -20px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 35px 0 35px 20px;
                    border-color: transparent;
                    border-left-color: rgba(255,255,255,1);
                    z-index: 2;
                }
                & a{
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
    &.main-menu-three{
        & > ul{
            & > li{
                border-bottom: 0;
                display: flex;
                & > a{
                    // Responsive
                    @media #{$desktop-device}{
                        padding-left: 15px;
                        padding-right: 12px;
                        font-size: 13px;
                    }
                    &::after{
                        display: none;
                    }
                    &::before{
                        content: '';
                        width: 0;
                        height: 4px;
                        display: inline-block;
                        position: absolute;
                        left: 50%;
                        top: 0;
                        background-color: $theme-color;
                        transform: translate(-50%,0);
                        transition: all .5s cubic-bezier(.215,.61,.355,1);
                    }
                    & i{
                        font-size: 16px;
                        margin-left: 5px;
                        color: $theme-color;
                    }
                }
                &.active, &:hover{
                    & > a{
                        &::before{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    & > ul {
        display: flex;
        & > li {
            position: relative;
            border-bottom: 4px solid transparent;
            & > a {
                font-size: 14px;
                line-height: 1.2;
                font-weight: 700;
                font-family: $heading-font;
                text-transform: uppercase;
                display: block;
                padding-top: 27px;
                padding-bottom: 23px;
                padding-left: 20px;
                padding-right: 20px;
                &::after{
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 8px 3px;
                    border-color: transparent;
                    border-bottom-color: $theme-color;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: all .5s cubic-bezier(.215,.61,.355,1);
                    transform: translate(-50%,0);
                }
            }
            &.has-dropdown {
                & > a {
                    &::after {
                        content: "\f107";
                        font-family: Fontawesome;
                        line-height: 30px;
                        margin-left: 3px;
                    }
                }
            }
            &:hover {
                & > .sub-menu {
                    top: 100%;
                    opacity: 1;
                    visibility: visible;
                    border-top-color: $theme-color;
                    z-index: 99;
                }
                & > .mega-menu {
                    margin-top: 0;
                    opacity: 1;
                    visibility: visible;
                    z-index: 99;
                }
            }
            
            &:last-child {
                & .sub-menu {
                    left: auto;
                    right: 0;
                    & .sub-menu {
                        & .sub-menu {
                            left: 100%;
                            right: auto;
                            & .sub-menu {
                                left: auto;
                                right: 100%;
                            }
                        }
                    }
                }
            }
            &:nth-last-child(-n+3) {
                & .sub-menu {
                    & .sub-menu {
                        left: auto;
                        right: 100%;
                        & .sub-menu {
                            left: 100%;
                            right: auto;
                        }
                    }
                }
            }
            &.active, &:hover{
                border-bottom-color: $theme-color;
                & > a {
                    color: $heading-color;
                    &::after {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

/*-- Sub Menu --*/
.sub-menu {
    position: absolute;
    left: 0;
    top: 120%;
    background-color: $white;
    border-top: 3px solid transparent;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,.2);
    transition: all 0.3s ease 0s;
    width: 300px;
    z-index: -99;
    opacity: 0;
    visibility: hidden;
    & > li {
        position: relative;
        background-color: $white;
        &.tag-new{
            &::before{
                content: 'NEW';
                font-size: 10px;
                line-height: 1;
                font-weight: 600;
                display: block;
                position: absolute;
                top: 50%;
                right: 15px;
                padding: 5px;
                color: #fff;
                background-color: #d81313;
                transform: translateY(-50%);
            }
        }
        & > a {
            display: block;
            font-size: 14px;
            line-height: 14px;
            padding-top: 15px;
            padding-bottom: 17px;
            padding-left: 20px;
            padding-right: 20px;
        }
        &.has-dropdown {
            & > a {
                &::after {
                    content: "\f105";
                    font-family: Fontawesome;
                    line-height: 14px;
                    float: right;
                }
            }
        }
        &.active {
            & > a {
                color: $theme-color;
            }
        }
        & .sub-menu {
            left: 100%;
            top: 0;
            border-top: 3px solid $theme-color;
        }
        &:hover {
            background-color: $theme-color;
            & > a {
                color: $heading-color;
            }
            & > .sub-menu {
                margin-top: -3px;
                opacity: 1;
                visibility: visible;
                z-index: 99;
            }
        }
        
        & .sub-menu {
            left: 100%;
            margin-left: 0;
            top: 0;
            & .sub-menu {
                left: auto;
                right: 100%;
                & .sub-menu {
                    left: 100%;
                    right: auto;
                }
            }
        }
    }
}
.main-menu-three{
   & .sub-menu{
       & .has-dropdown{
        & .sub-menu{
            // Responsive
            @media #{$desktop-device}{
                left: auto;
                right: 100%;
            }
        }
       }
   } 
}


/*-- Mobile Menu --*/
.mobile-header-area{
    background-color: #111;
}

.mobile-cart{
    & a{
        font-size: 20px;
        margin-right: 30px;
        color: $theme-color;
    }
}
.mobile-navigation-icon{
    width: 24px;
    height: 25px;
    position: relative;
    cursor: pointer;
    float: right;
    &:hover{
        i{
            background-color: $theme-color;
            &:before{
                width: 80%;
                background-color: $theme-color;
            }
            &:after{
                background-color: $theme-color;
                width: 60%;
            }
        }
    }

    i{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        background-color: $theme-color;
        transition: all 0.3s ease-in-out;

        &:before{
            position: absolute;
            bottom: 8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $theme-color;
            content: "";
            transition: all 0.3s ease-in-out;
        }

        &:after{
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $theme-color;
            content: "";
            transition: all 0.3s ease-in-out;
        }
    }


}

/* offcanvas mobile menu */

.header-mobile-navigation{
    padding: 20px 0;

    .mobile-navigation{
        .header-cart-icon{
            a{
                span{
                    left: 50%;
                }
            }
        }
    }
}

.offcanvas-widget-area{
    margin-bottom: 35px;
    margin-top: auto;

    @media #{$extra-small-mobile}{
        margin-bottom: 30px;
    }
}

.off-canvas-contact-widget{
    margin-bottom: 20px;

    .header-contact-info{
        flex-basis: 33.33%;
        &__list{
            li{
                display: inline-block;
                margin-right: 25px;
                i{
                    font-size: 14px;
                    margin-right: 5px;
                }
    
                a{
                    color: #666;
                    font-weight: 400;
                    line-height: 1.5;
                    &:hover{
                        color: $theme-color;
                    }
                }
    
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    
}

.offcanvas-mobile-menu{
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    max-width: 100%;
    
    height: 100vh;
    z-index: 9999;
    
    transform: translateX(100%);
    padding-left: 60px;
    transition: 0.6s;
    

    &.active{
        transform: translateX(0);
    }

    &.inactive{
        transform: translateX(calc(100% + 60px));
    }

}

.offcanvas-menu-close{
    position: absolute;
    left: 0;
    top: 0;
    background: #343538;
    z-index: 9;
    width: 60px;
    height: 60px;
    color: #fff;
    line-height: 60px;
    text-align: center;
    font-size: 30px;


    @media #{$extra-small-mobile}{
        width: 50px;
        height: 50px;
        line-height: 45px;
        left: 10px;
        font-size: 25px;
    }

    &:hover, &:focus{
        color: $white;
    }
    
    i{
        transition: 0.3s;
        transform: rotate(0);
    }

    &:hover{
        i{
            transform: rotate(-90deg);
        }
    }
}

.offcanvas-wrapper{
    overflow: auto;
    height: 100%;
    box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
    background-color: $white;
}

.offcanvas-mobile-search-area{
    background-color: #e6e6e6;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 60px);
    z-index: 9;
    margin-left: 60px;

    input{
        width: 100%;
        font-size: 16px;
        display: block;
        padding: 9px 25px;
        padding-right: 45px;
        color: #222;
        background: #e6e6e6;
        border: none;

        @media #{$extra-small-mobile}{
            font-size: 14px;
            padding: 5px 15px;
            padding-right: 45px;
        }
    }

    button{
        background: none;
        border: none;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #aaa;
        padding: 0;

        i{
            font-size: 18px;
            line-height: 40px; 
        }
    }
}

.offcanvas-inner-content{
    padding: 90px 35px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    @media #{$extra-small-mobile}{
        padding: 70px 25px 0;
    }
}

.offcanvas-navigation{
    margin-bottom: 50px;
    & > ul{
        & > li{
            margin: 0 -35px;
            padding: 0 35px;
            border-bottom: 1px solid #e6e6e6;
            background-color: #f5f5f5;
            // Responsive
            @media #{$large-mobile}{
                margin: 0 -25px;
                padding: 0 25px;
            }
            & > a{
                font-size: 15px;
                color: #111;
                font-weight: 600;
                line-height: 1.5;
                padding: 10px 0;
                &:hover{
                    color: $theme-color;
                }

                @media #{$extra-small-mobile}{
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    ul{

        &.submenu2{
            margin-left: 25px;
            & > li{
                & > a{
                    font-size: 13px;
                    color: #111;
                    font-weight: 400;
                    line-height: 1.5;
                    padding: 10px 0;
                    &:hover{
                        color: $theme-color;
                    }

                    @media #{$extra-small-mobile}{
                        font-size: 13px;
                        line-height: 18px;
                    }
                }
            }
        }
        li{
            

            &.menu-item-has-children{
                position: relative;
                display: block;
                a{
                    display: block;
                }

                &.active{
                    & > .menu-expand{
                        i{
                            &:before{
                                transform: rotate(0);
                            }
                        }
                    }
                }

                .menu-expand{
                    position: absolute;
                    right: auto;
                    left: 90%;
                    top: -5px;
                    width: 30px;
                    height: 50px;
                    line-height: 50px;
                    cursor: pointer;
                    text-align: center;

                    i{

                        display: block;
                        margin-top: 25px;
                        border-bottom: 1px solid;
                        position: relative;
                        width: 10px;
                        transition: all 250ms ease-out;
                        &:before{
                            width: 100%;
                            content: "";
                            border-bottom: 1px solid;
                            display: block;
                            position: absolute;
                            top: 0;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }
}

.off-canvas-widget-social{
    a{
        &:first-child{
            margin-left: 0;
        }
        margin: 0 10px;
        font-size: 14px;

        @media #{$extra-small-mobile}{
            margin: 0 10px;
        }

        &:hover{
            color: $theme-color;
        }

    }
}

/* offcanvas settings */

.offcanvas-settings{
    .offcanvas-navigation{
        & > ul{
            & > li{
                & > a{
                    font-size: 12px;
                    font-weight: 400;
                    padding: 10px 0;
                }

                &.menu-item-has-children{
                    .menu-expand{
                        height: 30px;
                        margin-top: 0;
                    }
                }
            }

            
        }

        ul{
            &.submenu2{
                & > li{
                   & >  a{
                         padding: 5px 0;
                     }
                 }
             }
        }


    }
}


 /*=====  End of Header  ======*/
