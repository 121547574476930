/*---------------------------------------
    26. Contact CSS
-----------------------------------------*/

/*-- Map --*/
.contact-map {
    height: 400px;
    width: 100%;
}
/* Contact Foram Area */
.contact-area, .testimonial-tringle{
    &.triangle-none{
        text-align: center;
        padding: 0;
        &::before, &::after{
            display: none;
        }
        & .section-title{
            & h1{
                font-size: 36px;
                // Responsive
                @media #{$large-mobile}{
                    font-size: 24px;
                }
            }
        }
    }
    padding: 70px 0;
    position: relative;
    padding-top: 65px;
    // Responsive
    @media #{$tablet-device}{
        padding: 60px 0;
        padding-top: 55px;
    }
    @media #{$tablet-device}{
        padding: 50px 0;
        padding-top: 45px;
    }
    @media #{$large-mobile}{
        padding: 50px 0;
        padding-top: 45px;
    }
    &::before{
        content: '';
        width: 95px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -80px;
        background-color: #111111;
        // Responsive
        @media #{$tablet-device}{
            display: none;
        }
        @media #{$large-mobile}{
            display: none;
        }
    }
    &::after{
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 300px 0 300px 150px;
        border-color: transparent;
        border-left-color: #111111;
        position: absolute;
        right: -165px;
        top: 50%;
        transform: translate(0,-50%);
        // Responsive
        @media #{$laptop-device}{
            display: none;
        }
        @media #{$desktop-device}{
            display: none;
        }
        @media #{$tablet-device}{
            display: none;
        }
        @media #{$large-mobile}{
            display: none;
        }
    }
}
.testimonial-tringle{
    padding-top: 65px;
    padding-bottom: 35px;
    // Responsive
    @media #{$tablet-device}{
        padding-top: 55px;
        padding-bottom: 25px;
    }
    @media #{$large-mobile}{
        padding-top: 45px;
        padding-bottom: 50px;
    }
}
/*-- Contact Information --*/
.contact-information {
    & h3 {
        margin-bottom: 30px;
    }
    & ul {
        & li {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            & .icon {
                width: 50px;
                & i {
                    font-size: 30px;
                }
            }
            & .text {
                max-width: calc(100% - 50px);
                & span, & a {
                    display: block;
                    line-height: 24px;
                    max-width: 230px;
                }
            }
        }
    }
}

/*-- Contact Form --*/
.contact-form {
    & input {
        width: 100%;
        height: 50px;
        border: 2px solid #ddd;
        padding: 5px 20px;
        color: $body-color;
        &:focus{
            border-color: $theme-color;
            color: $heading-color;
        }
    }
    & textarea {
        width: 100%;
        height: 120px;
        border: 2px solid #ddd;
        padding: 10px 20px;
        color: $body-color;
        &:focus{
            border-color: $theme-color;
            color: $heading-color;
        }
    }
}

.contact-form-wrap {
    &.margin-0{
         //Responsive
        @media #{$tablet-device}{
            margin-top: 0px;
        }
        @media #{$large-mobile}{
            margin-top: 0px;
        }  
    }
    & h2 {
        font-size: 36px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 35px;
        // Responsive
        @media #{$extra-small-mobile}{
            font-size: 24px;
            margin-bottom: 15px;
        }
    }
    & input {
        width: 100%;
        height: 50px;
        border: 2px solid #dddddd;
        padding: 5px 20px;
        color: $body-color;
        height: 45px;
        &:focus{
            border-color: $theme-color;
        }
    }
    & textarea {
        width: 100%;
        height: 200px;
        border: 2px solid #dddddd;
        padding: 10px 20px;
        color: $body-color;
        resize: none;
        &:focus{
            border-color: $theme-color;
        }
    }
    & button{
        margin-top: 20px;
    }
}
.form-messege.success {
	color: #1dbc51;
	font-weight: 700;
}
.form-messege.error{
    color: #ff1313;
}

.emergncy-contact {
	padding: 40px 30px;
    background-color: #111;
    margin-left: 85px;
    // Responsive
    @media #{$desktop-device}{
        margin-left: 0;
    }
    @media #{$tablet-device}{
        margin-left: 0;
        margin-top: 40px;
    }
    @media #{$large-mobile}{
        margin-left: 0;
        margin-top: 30px;
    }
    & h3{
        font-size: 24px;
        color: $white;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 40px;
        // Responsive
        @media #{$large-mobile}{
            font-size: 20px;
            margin-bottom: 25px;
        }
    }
}

.emergncy-contact-two{
    & p{
        line-height: 1.5;
        margin-bottom: 30px;
    }
}