/*----------------------------------------*/
/*  04. About CSS
/*----------------------------------------*/
.about-qute-area{
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 100px;
    border: 2px solid $theme-color;
    // Responsive
    @media #{$laptop-device}{
        margin-bottom: 80px;
    }
    @media #{$desktop-device}{
        margin-bottom: 70px;
    }
    @media #{$tablet-device}{
        margin-bottom: 55px;
    }
    @media #{$large-mobile}{
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 45px;
    }
}
.about-qute{
    text-align: center;
    & h2{
        font-size: 24px;
        line-height: 1.2;
        color: $heading-color;
        font-weight: 700;
        margin-bottom: 30px;
    }
}

.about-content{
    & h2{
        font-size: 36px;
        line-height: 1.2;
        color: $heading-color;
        font-weight: 700;
        margin-bottom: 30px;
        // Responsive
        @media #{$large-mobile}{
            font-size: 26px;
            margin-bottom: 10px;
        }
    }
    & p{
        line-height: 1.5;
    }
}
.about-page-image{
    & img{
        width: 100%;
    }
}
/* About Intro Area CSS */
.about-intro-image{
    margin: 5px auto;
    margin-bottom: 0;
    display: block;
    text-align: center;
}
.about-intro-area{
    h2{
        font-size: 32px;
        line-height: 1.2;
        font-weight: 700;
        // Responsive
        @media #{$large-mobile}{
            font-size: 22px;
        }
    }
    h3{
        font-size: 32px;
        line-height: 1.2;
        font-weight: 700;
        color: $theme-color;
        margin-bottom: 30px;
        // Responsive
        @media #{$large-mobile}{
            font-size: 22px;
            margin-bottom: 15px;
        }
    }
    p{
        line-height: 1.5;
        margin-bottom: 30px;
    }
}