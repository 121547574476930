/*----------------------------------------*/
/*  05. Feature CSS
/*----------------------------------------*/

/*-- Features --*/
.features-section-dark{
    position: relative;
    z-index: 9;
    overflow: hidden;
    &::before{
        content: '';
        display: block;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(17,17,17,.85);
        z-index: -1;
    }
    & .triangle{
        position: relative;
        z-index: 9;
        &:first-child{
            &::before, &::after{
                display: none !important;
            }
        }
        &::before{
            content: '';
            position: absolute;
            display: block !important;
            top: -60px;
            left: -40px;
            z-index: -1;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 158px 90px 158px 0;
            border-color: transparent rgba(17,17,17,.6) transparent transparent;
            // Responsive
            @media #{$desktop-device}{
                top: -60px;
            }
            @media #{$tablet-device}{
                display: none !important;
            }
            @media #{$large-mobile}{
                display: none !important;
            }
        }
        &::after{
            content: '';
            position: absolute;
            display: block;
            top: -60px;
            right: -650px;
            z-index: -2;
            height: 200%;
            width: 1000px;
            background-color: rgba(17,17,17,.6);
            // Responsive
            @media #{$desktop-device}{
                right: -730px;
            }
            @media #{$tablet-device}{
                display: none !important;
            }
            @media #{$large-mobile}{
                display: none !important;
            }
        }
    }
}
.features-title{
    & h1{
        font-size: 42px;
        line-height: 1.2;
        font-weight: 700;
        text-align: left;
        margin: 60px 0;
        // Responsive
        @media #{$tablet-device}{
            margin: 50px 0;
        }
        @media #{$large-mobile}{
            font-size: 24px;
            margin: 30px 0;
        }
    }
}

/* Single Features CSS */
.single-features {
	display: flex;
    align-items: center;
    &.service-style{
        align-items: flex-start;
        & .content{
            & h3{
                font-size: 18px;
                font-weight: 700;
                text-align: left;
                margin-bottom: 10px;
                padding-bottom: 5px !important;
                border-bottom: 1px solid rgba(18,33,50,.1);
            }
            & p{
                line-height: 1.5;
                // Responsive
                @media #{$large-mobile}{
                    line-height: 1.5;
                }
            }
        }
    }
    & .icon{
        background-color: #111111;
        &::before, &::after {
            background-color: #111111;
        }
        & i{
            font-size: 40px;
            color: $white;
            // Rseponsive
            @media #{$large-mobile}{
                font-size: 30px;
            }
        }
    }
    & .content{
        flex-basis: 75%;
        max-width: 75%;
        padding: 0 15px;
        // Responsive
        @media #{$large-mobile}{
            padding: 0 8px;
        }
        & h4{
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            margin-bottom: 10px;
            padding-bottom: 5px !important;
            border-bottom: 1px solid rgba(18,33,50,.1);
        }
        & h3{
            font-size: 18px;
            font-weight: 700;
            text-align: left;
            margin-bottom: 10px;
            padding-bottom: 5px !important;
            border-bottom: 1px solid rgba(18,33,50,.1);
        }
        & p{
            // Responsive
            @media #{$large-mobile}{
                line-height: 1.5;
            }
        }
    }
}
/* Feature Style Two CSS */
.feature-wrap{
    position: relative;
    &::before{
        content: '';
        width: 10px;
        height: 2px;
        background-color: $white;
        position: absolute;
        bottom: 0;
        left: 35px;
        z-index: 9;
    }
    & .feature-style-2{
        position: relative;
        overflow: hidden;
        border: 2px solid $theme-color;
        padding: 30px 120px 0px 20px;
        // Responsive
        @media #{$desktop-device}{
            padding: 30px 110px 0px 20px;
        }
        @media #{$large-mobile}{
            padding: 30px 90px 0px 20px;
        }
        &::before{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 430px 170px 430px 0;
            border-color: transparent;
            border-right-color: $theme-color;
            position: absolute;
            right: -60px;
            top: 50%;
            transform: translate(0,-50%);
            // Responsive
            @media #{$large-mobile}{
                border-width: 370px 140px 370px 0;
            }
        }
        & .content{
            & h4{
                font-size: 18px;
                font-weight: 700;
                text-align: left;
            }
            & p{
                margin-bottom: 35px;
                // Responsive
                @media #{$desktop-device}{
                    line-height: 20px;
                }
                @media #{$large-mobile}{
                    line-height: 20px;
                }
            }
        }
        & .features-icon{
            position: absolute;
            top: 50%;
            right: 20px;
            z-index: 1;
            transform: translate(0,-50%);
            & i{
                font-size: 45px;
                color: $heading-color;
            }
        }
    }
}
/* Feature Style Three CSS */
.feature-style-3{
    text-align: center;
    position: relative;
    z-index: 9;
    & .features-icon{
        & i{
            font-size: 80px;
            color: $theme-color;
        }
    }
    & .content{
        & h4{
            font-size: 20px;
            color: $white;
            text-align: center;
            font-weight: 700;
            margin: 5px 0;
        }
        & p{
            padding: 0 15px;
            color: $white;
            // Responsive
            @media #{$desktop-device}{
                padding: 0;
                margin-bottom: 0;
                line-height: 24px;
            }
            @media #{$tablet-device}{
                max-width: 300px;
                margin: auto;
            }
            @media #{$large-mobile}{
                max-width: 300px;
                margin: auto;
            }
        }
    }
}
/* feature Style Four */
.single-feature-four{
    display: flex;
    align-items: center;
    position: relative;
    background-color: #e8e8e8;
    padding: 30px 25px 30px 45px;
    overflow: hidden;
    transition: all .5s cubic-bezier(.215,.61,.355,1);
    // Responsive
    @media #{$desktop-device}{
        padding: 20px 15px 20px 35px;
    }
    @media #{$large-mobile}{
        padding: 20px 15px 20px 35px;
    }
    @media #{$extra-small-mobile}{
        padding: 20px;
    }
    &::before{
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: -75%;
        background-color: #ddd;
        z-index: 1;
        // Responsive
        @media #{$tablet-device}{
            display: none;
        }
        @media #{$extra-small-mobile}{
            display: none;
        }
    }
    &::after{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 430px 0 430px 170px;
        border-color: transparent transparent transparent #ddd;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        // Responsive
        @media #{$extra-small-mobile}{
            display: none;
        }
    }
    & .feature-icon{
        position: relative;
        z-index: 1;
        transition: all .5s cubic-bezier(.215,.61,.355,1);
        & i{
            font-size: 70px;
            color: $heading-color;
            // Responsive
            @media #{$extra-small-mobile}{
                font-size: 40px;
            }
        }
    }
    & .feature-content{
        margin-left: 80px;
        // Responsive
        @media #{$desktop-device}{
            margin-left: 70px;
        }
        @media #{$large-mobile}{
            margin-left: 70px;
        }
        @media #{$extra-small-mobile}{
            margin-left: 15px;
        }
        & h4{
            font-size: 18px;
            text-align: left;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 10px;
            padding-bottom: 5px;
            border-bottom: 1px solid #ddd;
            // Responsive
            @media #{$desktop-device}{
                font-size: 16px;
            }
            @media #{$extra-small-mobile}{
                font-size: 14px;
            }
        }
        & p{
            line-height: 22px;
        }
    }
    &:hover{
        background-color: #111111;
        &::before{
            background-color: $theme-color;
        }
        &::after{
            border-left-color: $theme-color;
        }
        //Responsive
        @media #{$extra-small-mobile}{
            & .feature-icon{
                & i{
                    color: $white;
                }
            }
        }
        & .feature-content{
            & h4{
                color: $white;
                & a{
                    &:hover{
                        color: $white;
                    }
                }
            }
            & p{
                color: $white;
            }
        }
    }
}
