/*---------------------------------------
    23. Comment CSS
-----------------------------------------*/

/*-- Comment Wrap --*/
.comments-wrapper {
    & h3 {
        font-size: 24px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 30px;
        // Responsive
        @media #{$large-mobile}{
            margin-bottom: 10px;
        }
    }
}
/*-- Comment Form --*/
.comments-form {
    & input {
        width: 100%;
        height: 50px;
        border: 2px solid #ddd;
        padding: 5px 20px;
        color: $body-color;
        &:focus{
            border: 2px solid $theme-color;
        }
    }
    & textarea {
        width: 100%;
        height: 120px;
        border: 2px solid #ddd;
        padding: 10px 20px;
        color: $body-color;
        resize: none;
        &:focus{
            border: 2px solid $theme-color;
        }
    }
}


  .commnent-list-wrap{
    .comment{
        list-style-type: none;
        margin-top: 48px;
        padding-top: 37px;
        padding-left: 6px;
        border-top: 1px solid #eee;
        // Responsive
        @media #{$tablet-device}{
          margin-top: 25px;
        }
        @media #{$large-mobile}{
            margin-top: 10px;
            padding-top: 20px;
        }
        .thumb{
            float: left;
            @media #{$large-mobile} {
                float: none;
            }
            img{
                width: 60px;
                height: 60px;
            }
        }
        .content{
            position: relative;
            overflow: hidden;
            margin-left: 80px;
            @media #{$large-mobile} {
                margin-left: 0;
                margin-top: 30px;
            }
            & .info{
              & h6{
                font-size: 15px;
                font-weight: 500;
              }
            }
            .reply-btn{
                a{
                    color: $theme-color;
                    transition: 0.4s;
                    display: inline-block;
                    text-transform: uppercase;
                    margin-left: 10px;
                    &:hover{}
                }
            }
            .comment-footer{
                span{
                    font-size: 14px;
                    font-weight: 400;
                    display: inline-block;
                    
                }
            }
        }
  
        &.comment-reply{
            padding-left: 61px;
            @media #{$large-mobile} {
                padding-left: 30px;
            }
            @media #{$extra-small-mobile} {
                padding-left: 0;
            }
        }
    }
  }