/*----------------------------------------*/
/*  06. Service CSS
/*----------------------------------------*/

/* Clasic Service CSS */
// Responsive
@media #{$laptop-device}{
    .clasic-service-section{
        .col-xl-2{
            display: none !important;
        }
        .col-xl-4{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
.clasic-service-image{
    img{
        width: 100%;
    }
}
.clasic-service-content{
    padding-left: 100px;
    // Responsive
    @media #{$laptop-device}{
        padding: 0 30px;
    }
    @media #{$desktop-device}{
        padding: 50px 100px;
    }
    @media #{$tablet-device}{
        padding: 50px 100px;
    }
    @media #{$large-mobile}{
        padding: 50px 30px;
    }
    & .title{
        font-size: 36px;
        color: $theme-color;
        line-height: 1.2;
        font-weight: 700;
        text-align: left;
        margin-bottom: 30px;
        // Responsive
        @media #{$laptop-device}{
            font-size: 32px;
            margin-bottom: 20px;
        }
        @media #{$tablet-device}{
            font-size: 32px;
            margin-bottom: 20px;
        }
        @media #{$large-mobile}{
            font-size: 26px;
            margin-bottom: 15px;
        }
        @media #{$extra-small-mobile}{
            font-size: 22px;
            margin-bottom: 15px;
        }
    }
    & p{
        color: $white;
        margin-bottom: 0;
        line-height: 1.5;
        margin-bottom: 35px;
        // Responsive
        @media #{$laptop-device}{
            margin-bottom: 25px;
        }
        @media #{$large-mobile}{
            margin-bottom: 20px;
        }
    }
    & .white-hover{
        &:hover{
            background-color: $white;
            color: $heading-color;
        }
    }
}
/* Clasic Service List CSS */
.clasic-service-list-content{
    & span{
        font-size: 18px;
        color: #545454;
        line-height: 1.2;
        font-weight: 700;
        font-family: $heading-font;
        text-align: left;
        display: block;
        margin-bottom: 10px;
        // Responsive
        @media #{$large-mobile}{
            font-size: 16px;
        }
    }
    & .title{
        font-size: 32px;
        font-weight: 700;
        line-height: 1.2;
        text-align: left;
        margin-bottom: 30px;
        // Responsive
        @media #{$laptop-device}{
            margin-bottom: 20x;
        }
        @media #{$desktop-device}{
            margin-bottom: 15px;
        }
        @media #{$tablet-device}{
            margin-bottom: 15px;
        }
        @media #{$large-mobile}{
            font-size: 26px;
            margin-bottom: 15px;
        }
        & a{
            display: block;
            color: $heading-color;
        }
    }
    & p{
        margin-bottom: 35px;
        // Responsive
        @media #{$laptop-device}{
            margin-bottom: 25px;
        }
        @media #{$desktop-device}{
            margin-bottom: 15px;
        }
        @media #{$tablet-device}{
            margin-bottom: 15px;
        }
        @media #{$large-mobile}{
            margin-bottom: 15px;
        }
    }
}
.clasic-service-list{
    margin-bottom: 35px;
    // Responsive
    @media #{$laptop-device}{
        margin-bottom: 25px;
    }
    @media #{$desktop-device}{
        margin-bottom: 15px;
    }
    @media #{$tablet-device}{
        margin-bottom: 15px;
    }
    @media #{$large-mobile}{
        margin-bottom: 15px;
    }
    & li{
        display: block;
        & i{
            margin-right: 10px;
            margin-bottom: 12px;
            display: inline-block;
        }
        & a{
            color: $body-color;
        }
    }
}
/* Clasic Service Section Three */
.clasic-service-section-three{
    position: relative;
    z-index: 999;
    &::before{
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: -80%;
        background: url('../images/bg/service-bg-before.jpg');
        z-index: -1;
        // Responsive
        @media #{$desktop-device}{
            left: -78%;
        }
    }
}
.clasic-service-content-three{
    position: relative;
    padding: 70px 0;
    // Responsive
    @media #{$desktop-device}{
        background-color: $white;
        padding: 70px 100px;
    }
    @media #{$tablet-device}{
        background-color: $white;
        padding: 60px 100px;
    }
    @media #{$large-mobile}{
        background-color: $white;
        padding: 50px 15px;
    }
    &::before{
        content: '';
        position: absolute;
        height: 50%;
        width: 160%;
        background-color: #fff;
        left: -130px;
        z-index: -1;
        outline: 1px solid transparent;
        top: 0;
        transform: perspective(1px) skew(-30deg,0deg);
        // Responsive
        @media #{$desktop-device}{
            display: none;
        }
        @media #{$tablet-device}{
            display: none;
        }
        @media #{$large-mobile}{
            display: none;
        }
    }
    &::after{
        content: '';
        position: absolute;
        height: 50%;
        width: 160%;
        background-color: #fff;
        left: -130px;
        z-index: -1;
        outline: 1px solid transparent;
        bottom: 0;
        transform: perspective(1px) skew(30deg,0deg);
        // Responsive
        @media #{$desktop-device}{
            display: none;
        }
        @media #{$tablet-device}{
            display: none;
        }
        @media #{$large-mobile}{
            display: none;
        }
    }
    & h2{
        font-size: 32px;
        line-height: 1.2;
        text-align: left;
        font-weight: 700;
        margin-bottom: 50px;
        // Responsive
        @media #{$laptop-device}{
            margin-bottom: 30px;
        }
        @media #{$desktop-device}{
            margin-bottom: 30px;
        }
        @media #{$tablet-device}{
            margin-bottom: 30px;
            font-size: 28px;
        }
        @media #{$large-mobile}{
            font-size: 18px;
            margin-bottom: 15px;
        }
    }
    & p{
        margin-bottom: 30px;
        // Responsive
        @media #{$large-mobile}{
            line-height: 22px
        }
    }
    & ul{
        margin-bottom: 15px;
        & li{
            font-size: 15px;
            width: 49%;
            display: inline-block;
            vertical-align: top;
            padding: 0 15px;
            position: relative;
            margin-bottom: 20px;
            // Responsive
            @media #{$large-mobile}{
                width: 100%;
            }
            &::before{
                content: '';
                height: 100%;
                width: 3px;
                position: absolute;
                left: 0;
                background-color: $theme-color;
            }
        }
    }
}
/* Clasic Service Section Four */
.clasic-service-section-four{
    position: relative;
    z-index: 1;
}
.clasic-service-for-image {
	position: relative;
    bottom: -31px;
    // Responsive
    @media #{$desktop-device}{
        bottom: -25px;
    }
    @media #{$tablet-device}{
        bottom: 0px;
    }
    @media #{$large-mobile}{
        bottom: 0px;
    }
}
.clasic-service-content-four{
    & h2{
        font-size: 36px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 30px;
        // Responsive
        @media #{$large-mobile}{
            font-size: 22px;
            margin-bottom: 15px;
        }
    }
    & p{
        margin-bottom: 35px;
        // Responsive
        @media #{$large-mobile}{
            margin-bottom: 15px;
        }
    }
    & a{
        // Responsive
        @media #{$extra-small-mobile}{
            margin-bottom: 10px;
        }
    }
}
/* Single Service CSS */
.single-service{
    position: relative;
    &.service-list{
        &::after{
            display: none;
        }
        &::before{
            background-color: #111111;
            opacity: 0.5;
        }
        &:hover{
            & .service-icon{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &::before, &::after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition-duration: .5s;
    }
    &::before{
        background-color: #111111;
        opacity: 0.8;
    }
    &::after{
        background-color: $theme-color;
        opacity: 0;
        visibility: hidden;
        transform: scale3d(.8,.8,1);
    }
    & .service-image{
        overflow: hidden;
        & img{
            width: 100%;
            transition: all .5s cubic-bezier(.215,.61,.355,1);
        }
    }
    & .service-icon{
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        text-align: center;
        z-index: 2;
        backface-visibility: hidden;
        margin: 0;
        transition: all .5s cubic-bezier(.215,.61,.355,1);
        & .icon{
            background-color: $theme-color;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 10px;
            &::before, &::after {
                background-color: $theme-color;
            }
            & i{
                font-size: 40px;
                color: $heading-color;
            }
        }
        & h3{
            font-size: 20px;
            color: $white;
            line-height: 1.2;
            text-align: center;
            font-weight: 700;
            margin-bottom: 0;
            // Responsive
            @media #{$desktop-device}{
                font-size: 16px;
            }
        }
    }
    & .service-content{
        text-align: center;
        position: absolute;
        width: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
        backface-visibility: hidden;
        margin: 0;
        opacity: 0;
        visibility: hidden;
        transition: all .5s cubic-bezier(.215,.61,.355,1);
        // Responsive
        @media #{$laptop-device}{
            width: 60%;
        }
        @media #{$desktop-device}{
            width: 80%;
        }
        @media #{$tablet-device}{
            width: 70%;
        }
        & h3{
            font-size: 20px;
            line-height: 1.2;
            text-align: center;
            font-weight: 700;
            // Responsive
            @media #{$laptop-device}{
                font-size: 18px;
            }
            @media #{$desktop-device}{
                font-size: 16px;
            }
            @media #{$tablet-device}{
                font-size: 18px;
            }
            & a{
                color: $heading-color;
            }
        }
        & p{
            color: $heading-color;
            line-height: 1.5;
            margin-bottom: 15px;
            // Responsive
            @media #{$laptop-device}{
                line-height: 1.2;
            }
            @media #{$desktop-device}{
                line-height: 1;
                margin-bottom: 10px;
            }
            @media #{$tablet-device}{
                line-height: 1.2;
            }
        }
        & .service-btn{
            background-color: $white;
            color: $heading-color;
            padding: 16px 20px;
            // Responsive
            @media #{$laptop-device}{
                padding: 12px 15px;
            }
            @media #{$desktop-device}{
                padding: 12px 15px;
            }
            @media #{$tablet-device}{
                padding: 12px 15px;
            }
        }
    }
    &:hover{
        &::after{
            opacity: .9;
            visibility: visible;
            transform: scale3d(1,1,1);
            // Responsive
            @media #{$large-mobile}{
                opacity: 0;
                visibility: hidden;
            }
        }
        & .service-image{
            & img{
                transform: scale(1.1);
            }
        }
        & .service-icon{
            opacity: 0;
            visibility: hidden;
            // Responsive
            @media #{$large-mobile}{
                opacity: 1;
                visibility: visible;
            }
        }
        & .service-content{
            opacity: 1;
            visibility: visible;
            // Responsive
            @media #{$large-mobile}{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}
/* Single Service Two CSS */
.single-service-two{
    position: relative;
    overflow: hidden;
    & .service-image{
        position: relative;
        overflow: hidden;
        & img{
            width: 100%;
            transition: all .5s cubic-bezier(.215,.61,.355,1);
        }
    }
    & .service-icon-wrap{
        position: relative;
        margin-top: -14px;
        z-index: 1;
        // Responsive
        @media #{$large-mobile}{
            margin-top: -5px;
        }
        & .service-icon{
            display: flex;
            align-items: center;
            justify-content: space-between;
            & .icon{
                background-color: #111111;
                left: 70px;
                margin-top: -25px;
                // Responsive
                @media #{$large-mobile}{
                    left: 50px;
                    margin-top: -24px;
                }
                &::before, &::after {
                    background-color: #111111;
                }
                & i{
                    color: $white;
                }
            }
        }
        & h3{
            display: block;
            background-color: #fbd232;
            width: 75%;
            text-align: right;
            margin-bottom: 0;
            & a{
                display: block;
                color: $heading-color;
                padding: 16px 20px;
                font-size: 16px;
                line-height: 19px;
                font-weight: 700;
                // Responsive
                @media #{$large-mobile}{
                    padding: 7px 20px;
                    font-size: 14px;
                }
            }
        }
    }
    &:hover{
        & .service-image{
            & img{
                transform: scale(1.1);
            }
        }
    }
}
/* Service List CSS */
.service-list-area{
    padding: 30px 15px;
    border: 2px solid #eee;
    border-bottom: 1px solid #eee;
    &:last-child{
        border-bottom: 2px solid #eee;
    }
    // Responsive
    @media #{$large-mobile}{
        padding: 30px 0px;
    }
}
.service-list-content{
    margin-right: 45px;
    // Responsive
    @media #{$desktop-device}{
        margin-right: 0;
    }
    @media #{$tablet-device}{
        margin-right: 0;
    }
    @media #{$large-mobile}{
        margin-right: 0;
    }
    & .title{
        font-size: 28px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid #eee;
        // Responsive
        @media #{$large-mobile}{
            margin-bottom: 15px;
            padding-bottom: 15px;
        }
    }
    & p{
        line-height: 1.5;
    }
}

/* Single Service Two CSS */
.single-service-three{
    position: relative;
    & .service-icon{
        position: relative;
        margin-bottom: -50px;
        margin-left: -20px;
        & .icon{
            & i{
                font-size: 32px;
            }
        }
    }
    & .service-image{
        position: relative;
        overflow: hidden;
        & img{
            width: 100%;
            transition: all .5s cubic-bezier(.215,.61,.355,1);
        }
    }
    & h3{
        position: absolute;
        bottom: 0;
        right: 0;
        width: auto;
        font-size: 16px;
        font-weight: 700;
        padding: 16px 20px;
        background: #fbd232;
        margin: 0;
        // Responsive
        @media #{$large-mobile}{
            font-size: 14px;
        }
        & a{
            color: $heading-color;
        }
    }
    &:hover{
        & .service-image{
            & img{
                transform: scale(1.1);
            }
        }
    }
}

/* Service Intro Css */
.gradient-bg{
    position: relative;
    &::before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        padding: 0 15px;
        background: #fff;
        background: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#f8f8f8));
        background: -webkit-linear-gradient(#fff,#f8f8f8);
        background: -moz-linear-gradient(center top,#fff 0%,#f8f8f8 100%);
        background: -moz-gradient(center top,#fff 0%,#f8f8f8 100%);
    }
}
.service-intro-image{
    text-align: center;
    margin-bottom: 20px;
}
.service-intro-content{
    & h2{
        font-size: 36px;
        text-align: left;
        font-weight: 700;
        margin-bottom: 0;
        // Responsive
        @media #{$tablet-device}{
            font-size: 28px;
        }
        @media #{$large-mobile}{
            font-size: 28px;
        }
        @media #{$small-mobile}{
            font-size: 24px;
        }
        @media #{$extra-small-mobile}{
            font-size: 20px;
        }
    }
    & p{
        // Responsive
        @media #{$tablet-device}{
            line-height: 22px;
        }
        @media #{$large-mobile}{
            line-height: 22px;
        }
    }
}

/* Service Table CSS */
.service-table{
    &.theme-color{
        & table{
            border: 1px solid #eee;
            & th{
                background-color: $theme-color;
                color: $white;
            }
        }
    }
    & table{
        margin-bottom: 0;
        & th{
            font-size: 17px;
            font-weight: 600;
            padding: 11px 40px;
            color: $heading-color;
            background-color: #eee;
            border: 0;
            border-bottom: 0 !important;
            width: 50%;
            // Responsive
            @media #{$large-mobile}{
                padding: 11px 20px;
                width: 200px;
            }
        }
        & td{
            font-size: 15px;
            padding: 11px 40px;
            border-bottom: 1px solid #eeeeee;
            // Responsive
            @media #{$large-mobile}{
                padding: 11px 20px;
            }
            &.price{
                font-weight: 600;
            }
        }
    }
}

/* Service Details CSS */
.service-details-image{
    & img{
        width: 100%;
        // Responsive
        @media #{$tablet-device}{
            margin-bottom: 30px;
        }
        @media #{$large-mobile}{
            margin-bottom: 30px;
        }
    }
}
.service-details-content{
    & h2{
        font-size: 36px;
        text-align: left;
        font-weight: 700;
        margin-bottom: 20px;
        // Responsive
        @media #{$large-mobile}{
            font-size: 28px;
            margin-bottom: 10px;
        }
    }
    & p{
        margin-bottom: 25px;
        line-height: 24px;
        // Responsive
        @media #{$large-mobile}{
            margin-bottom: 15px;
        }
    }
    & h3{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
        // Responsive
        @media #{$large-mobile}{
            font-size: 16px;
            margin-bottom: 10px;
        }
    }
}

/* Service Pricing Table CSS */
.pricing-table-area{
    border-top: 1px solid #ebebeb;
    padding-top: 50px;
}

.single-pricing{
    text-align: center;
    & .price-head{
        & h4{
            font-size: 18px;
            color: $white;
            background-color: $theme-color;
            font-weight: 700;
            margin-bottom: 0;
        }
        & h3{
            font-size: 48px;
            font-weight: 600;
            background-color: #f7f7f7;
            border: 2px solid #eee;
            padding: 30px 0;
            margin-bottom: 0;
            & span{
                font-size: 28px;
                line-height: 70px;
                margin-left: -8px;
                vertical-align: top;
            }
        }
    }
    & .price-body{
        & ul{
            border: 2px solid #eee;
            padding: 0 20px;
            border-top: 0;
            border-bottom: 0;
            // Responsive
            @media #{$desktop-device}{
                padding: 0 8px;
            }
            & li{
                border-bottom: 1px solid #eee;
                padding: 25px 0;
                margin: 0;
            }
        }
    }
    & .price-footer{
        a{
            font-size: 18px;
            font-weight: 600;
            display: block;
            border: 2px solid #eee;
            border-top: none;
            padding: 20px 0;
            color: $heading-color;
            background-color: $theme-color;
            font-family: $heading-font;
            & i{
                margin-left: 5px;
                transition: all .5s cubic-bezier(.215,.61,.355,1);
            }
            &:hover{
                background-color: #111;
                border-color: #111;
                color: $white;
                & i{
                    color: #fff;
                    transform: translate(-120px,0);
                }
            }
        }
    }
}