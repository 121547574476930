/*----------------------------------------*/
/*  14. Choose CSS
/*----------------------------------------*/

/* Contact Hot line CSS */
.contact-hot-line-area{
    position: relative;
    padding-right: 15px;
    &.black{
        & .hot-line-image{
            &::before{
                background-color: $heading-color;
            }
        }
        & .hot-line-content{
            & h4{
                color: $white;
            }
        }
    }
    & .hot-line-image{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $white;
            opacity: .9;
        }
        & img{
            width: 100%;
        }
    }
    & .hot-line-content{
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        padding-left: 50px;
        transform: translate(-50%,-50%);
        // Responsive
        @media #{$desktop-device}{
            padding-left: 30px;
        }
        @media #{$extra-small-mobile}{
            padding-left: 30px;
        }
        & h4{
            font-size: 20px;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 0;
            // Responsive
            @media #{$desktop-device}{
                font-size: 18px;
            }
            @media #{$extra-small-mobile}{
                font-size: 18px;
            }
        }
        & h2{
            font-size: 36px;
            font-weight: 700;
            line-height: 1.2;
            color: $theme-color;
            margin-bottom: 0;
            // Responsive
            @media #{$desktop-device}{
                font-size: 32px;
            }
            @media #{$extra-small-mobile}{
                font-size: 30px;
            }
        }
    }
}
/* Chosse Description CSS */
.choose-description{
    &.color-black{
        & span{
            font-size: 18px;
        }
        & h2{
            font-size: 32px;
            color: $heading-color;
        }
        & p{
            color: $body-color;
        }
    }
    padding-bottom: 30px;
    // Responsive
    @media #{$tablet-device}{
        padding-bottom: 0;
        margin-top: 30px;
    }
    @media #{$large-mobile}{
        padding-bottom: 25px;
        margin-top: 30px;
    }
    & span{
        font-size: 22px;
        color: $theme-color;
        line-height: 1.2;
        font-family: $heading-font;
        font-weight: 700;
        margin-bottom: 0;
        // Responsive
        @media #{$desktop-device}{
            font-size: 18px;
        }
        @media #{$large-mobile}{
            font-size: 18px;
        }
    }
    & h2{
        font-size: 36px;
        color: $white;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 0;
        // Responsive
        @media #{$desktop-device}{
            font-size: 32px !important;
        }
        @media #{$large-mobile}{
            font-size: 24px !important;
        }
    }
    & p{
        margin-top: 20px;
        line-height: 1.5;
        color: $white;
    }
}

/* Wrench List CSS */
.wrench-list{
    &.color-black{
        // Responsive
        @media #{$tablet-device}{
            margin-top: 30px;
        }
        & ul{
            & li{
                & a{
                    color: $heading-color;
                    &:hover{
                        color: $theme-color;
                    }
                }
            }
        }
    }
    & ul{
        & li{
            display: block;
            & i{
                font-size: 12px;
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 12px;
                color: $theme-color;
            }
            & a{
                color: $white;
                &:hover{
                    color: $theme-color;
                }
            }
        }
    }
}
/* Choose Us Section */
.choose-us-section-image{
    text-align: center;
}