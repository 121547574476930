/*---------------------------------------
    24. Sidebar CSS
-----------------------------------------*/

/*-- Sidebar --*/
.sidebar {
    margin-bottom: 50px;
    &:last-child {
        margin-bottom: 0;
    }
}
/*-- Sidebar Title --*/
.sidebar-title {
    font-size: 22px;
    line-height: 1;
    font-weight: 700;
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
    margin-bottom: 25px;
}
/*-- Sidebar Search --*/
.sidebar-search {
    & form {
        display: flex;
        border: 1px solid #e5e6e7;
        & input {
            flex: 1 0 calc(100% - 40px);
            max-width: calc(100% - 40px);
            height: 40px;
            border: none;
            background-color: transparent;
            padding: 0 15px;
            color: $body-color;
        }
        & button {
            max-width: 40px;
            flex: 1 0 40px;
            height: 40px;
            line-height: 40px;
            border: none;
            background-color: transparent;
            padding: 0;
            display: block;
            & i {
                font-size: 20px;
            }
            &:hover {
                color: $theme-color;
            }
        }
    }
}
/*-- Sidebar List --*/
.sidebar-list {
    & li {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e5e6e7;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0px solid transparent;
        }
        & a {
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            & img {
                width: 25px;
                margin-right: 15px;
            }
            & i {
                margin-right: 15px;
                font-size: 24px;
            }
            &:hover {
                color: $theme-color;
            }
        }
    }
}
/*-- Sidebar Blog --*/
.sidebar-blog {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e5e6e7;
    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }
    & .image {
        max-width: 60px;
        flex: 1 0 60px;
        & img {
            width: 100%;
        }
    }
    & .content {
        flex: 1 0 calc(100% - 60px);
        padding-left: 15px;
        & h5 {
            font-size: 14px;
            line-height: 1.1;
            font-weight: 600;
            & a {}
        }
        & span {
            font-size: 15px;
            display: block;
            line-height: 18px;
            color: #777;
        }
    }
}
/*-- Sidebar Tags --*/
.sidebar-tag {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    & li {
        margin-right: 2px;
        margin-bottom: 8px;
        & a {
            display: block;
            border: 2px solid #ddd;
            padding: 5px 12px;;
            font-size: 14px;
            &:hover {
                background-color: $heading-color;
                border-color: $heading-color;
                color: $theme-color;
            }
        }
    }
}
/*-- Sidebar Product --*/
.sidebar-product {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e5e6e7;
    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }
    & .image {
        max-width: 80px;
        flex: 1 0 80px;
        & img {
            width: 100%;
            border: 1px solid #f1f2f3;
        }
    }
    & .content {
        flex: 1 0 calc(100% - 80px);
        padding-left: 15px;
        & .title {
            font-size: 15px;
            font-weight: 600;
            line-height: 1.4;
            & a {}
        }
        & .price {
            font-size: 14px;
            line-height: 1;
            margin: 0;
            & span {
                &.new {
                    font-weight: 700;
                    color: #d80000;
                    margin-left: 10px;
                }
                &.old {
                    color: $body-color;
                    text-decoration: line-through;
                }
            }
        }
        & .ratting {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 5px;
            & i {
                font-size: 12px;
                line-height: 18px;
                margin-right: 5px;
                color: $theme-color;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}