/*----------------------------------------*/
/*  12. Work CSS
/*----------------------------------------*/
/*-- Work Slider --*/
.work-slider, .work-slider-two {
    & .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        border: none;
        background-color: $theme-color;
        color: $heading-color;
        width: 30px;
        height: 44px;
        border-radius: 6px;
        text-align: center;
        &::before, &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: $theme-color;
            transform: rotate(60deg);
            z-index: -1;
            border-radius: 6px;
        }
        &::after {
            transform: rotate(-60deg);
        }
        & i {
            font-size: 18px;
            margin-right: 0px;
            line-height: 25px;
            font-weight: 700;
            color: $heading-color;
        }
        &.slick-prev {
            left: 0;
        }
        &.slick-next {
            right: 0;
        }
    }
    & .slick-dots {
        width: 100%;
        padding: 0;
        margin: 20px 0 0;
        display: flex;
        justify-content: center;
        & li {
            margin: 0 5px;
            & button {
                display: block;
                padding: 0;
                width: 12px;
                height: 12px;
                border: none;
                background-color: $body-color-light;
                text-indent: -9999px;
                border-radius: 50px;
            }
            &.slick-active {
                & button {
                    background-color: $theme-color;
                }
            }
        }
    }
}
.work-slider-two{
    & .slick-arrow{
        &.slick-prev {
            left: 15px;
        }
        &.slick-next {
            right: 15px;
        }
    }
}
/* Work Menu CSS */
.work-menu{
    margin-bottom: 30px;
    ul{
        li{
            & a{
                font-size: 13px;
                font-weight: 700;
                display: block;
                text-transform: uppercase;
                color: $heading-color;
                line-height: 38px;
                font-family: $heading-font;
                padding: 5px 20px 5px 35px;
                position: relative;
                background-color: $theme-color;
                transition: all 0.3s ease-in-out;
                &::before, &::after{
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-color: transparent;
                    z-index: 1;
                    transition: all 0.3s ease-in-out;
                }
                &::before{
                    top: 0;
                    border-width: 24px 0 24px 16.5px;
                    right: -17px;
                    border-left-color: rgba(255,255,255,.15);
                }
                &::after{
                    top: 0;
                    right: -15px;
                    border-width: 24px 0 24px 15px;
                    border-left-color: $theme-color;
                }
                &:hover, &.active{
                    background-color: $white;
                    &::before{
                        border-left-color: $theme-color;
                    } 
                    &::after{
                        border-left-color: $white;
                    } 
                }
            }
        }
    }
}

/*-- Work --*/
.project {
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    & .image {
        display: block;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: $black;
            opacity: 0;
            transition: all 0.3s ease 0s;
        }
        & img {
            width: 100%;
        }
    }
    & .img{
        display: block;
        & img{
            width: 100%;
        }
    }
    & .daHoverElem{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: 0;
        transition: all 0.3s ease 0s;
    }
    & .content {
        &.content-center{
            top: 50%;
            bottom: auto;
            text-align: center;
            transform: translateY(-50%);
            padding: 0;
        }
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px 15px 50px 50px;
        & .title {
            font-size: 18px;
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 2px;
            opacity: 0;
            transform: translateY(-35px);
            animation-duration: 1.5s;
            transition: all 0.5s ease 0s;
            & > a {
                font-size: 18px;
                line-height: 25px;
                font-weight: 700;
                color: $theme-color;
            }
        }
        & .category {
            display: block;
            opacity: 0;
            transform: translateY(-20px);
            animation-duration: 1s;
            transition: .3s ease-out 0s;
            & > a {
                color: #dddddd;
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        & .work-search-icon{
            opacity: 0;
            visibility: hidden;
            transform: translateY(-45px);
            animation-duration: 2s;
            transition: all 0.3s ease 0s;
            & .icon{
                width: 30px;
                height: 45px;
                margin: auto;
                margin-top: 10px;
                & a{
                    display: block;
                    & i{
                        font-size: 18px;
                        margin-right: 0px;
                        color: $heading-color;
                    }
                }
            }
        }
    }
    &:hover {
        & .image {
            &::before {
                opacity: 0.7;
            }
        }
        & .daHoverElem{
            opacity: 0.7;
        }
        & .content {
            opacity: 1;
            & .title {
                opacity: 1;
                transition: .5s ease-out .3s;
                transform: translateY(0);
                & a {}
            }
            & .category {
               opacity: 1;
               transform: translateY(0); 
               transition: .5s ease-out .3s;
                & a {
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
            & .work-search-icon{
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }
}


/*-- Project Information --*/
.project-information {
    background-color: $heading-color;
    padding: 30px 35px;
    & h3 {
        color: $white;
        margin-bottom: 20px;
    }
    & ul {
        & li {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba($white, 0.1);
            color: $white;
            font-size: 14px;
            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border: none;
            }
            & strong {
                width: 80px;
                display: inline-flex;
            }
            & a {}
        }
    }
}