/*----------------------------------------*/
/*  19. Product CSS
/*----------------------------------------*/
/* Product Slider */
.product-slider-4 {
    & .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        border: none;
        background-color: $theme-color;
        color: $heading-color;
        width: 30px;
        height: 44px;
        border-radius: 6px;
        text-align: center;
        &::before, &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: $theme-color;
            transform: rotate(60deg);
            z-index: -1;
            border-radius: 6px;
        }
        &::after {
            transform: rotate(-60deg);
        }
        & i {
            font-size: 18px;
            margin-right: 0px;
            line-height: 25px;
            font-weight: 700;
            color: $heading-color;
        }
        &.slick-prev {
            left: 25px;
        }
        &.slick-next {
            right: 25px;
        }
    }
    & .slick-dots {
        width: 100%;
        padding: 0;
        margin: 20px 0 0;
        display: flex;
        justify-content: center;
        & li {
            margin: 0 5px;
            & button {
                display: block;
                padding: 0;
                width: 12px;
                height: 12px;
                border: none;
                background-color: $body-color-light;
                text-indent: -9999px;
                border-radius: 50px;
            }
            &.slick-active {
                & button {
                    background-color: $theme-color;
                }
            }
        }
    }
}

/*-- Product --*/
.product {
    & .product-inner {
        & .media {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            position: relative;
            margin-bottom: 15px;
            border: 1px solid #f1f2f3;
            & .image {
                width: 100%;
                position: relative;
                z-index: 1;
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $white;
                    z-index: 2;
                    opacity: 0;
                    transition: all 0.3s ease 0s;
                }
                & img {
                    width: 100%;
                }
            }
            & .btn {
                z-index: 9;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                margin-top: 20px;
                white-space: nowrap;
                opacity: 0;
                & i {
                    font-size: 20px;
                    margin-right: 10px;
                }
                &:hover {
                    background-color: $heading-color;
                    color: $theme-color;
                }
            }
            & .view-btn{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                margin-top: 20px;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s ease 0s;
                z-index: 99;
            }
            & .work-search-icon{
                & .icon{
                    width: 30px;
                    height: 45px;
                    margin: auto;
                    margin-top: 10px;
                    & a{
                        display: block;
                        & i{
                            font-size: 18px;
                            margin-right: 0px;
                            color: $heading-color;
                        }
                    }
                }
            }
            & .onsale{
                font-size: 14px;
                font-weight: 600;
                color: $white;
                text-transform: uppercase;
                position: absolute;
                height: 60px;
                width: 60px;
                line-height: 60px;
                border-radius: 100%;
                text-align: center;
                top: 20px;
                left: 25px;
                background-color: #d90000;
                padding: 0;
                z-index: 9;
            }
        }
        & .content {
            text-align: center;
            & .title {
                font-size: 15px;
                font-weight: 600;
                line-height: 1.4;
                & a {}
            }
            & .price {
                font-size: 14px;
                line-height: 1;
                margin: 0;
                & span {
                    &.new {
                        font-weight: 700;
                        color: #d80000;
                        margin-left: 10px;
                    }
                    &.old {
                        color: $body-color;
                        text-decoration: line-through;
                    }
                }
            }
            & .ratting {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 5px;
                & i {
                    font-size: 12px;
                    line-height: 18px;
                    margin-right: 5px;
                    color: $theme-color;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        &:hover {
            & .media {
                & .image {
                    &::before {
                        opacity: 0.8;
                    }
                }
                & .btn {
                   opacity: 1;
                    margin-top: 0;
                }
                & .view-btn{
                    opacity: 1;
                    visibility: visible;
                    margin-top: 0;
                }
            }
        }
    }
}

/*-- Product Details --*/
.product-details {
    & .product-inner {
        & .product-image-slider {
            margin-bottom: 10px;
            border: 1px solid #f1f2f3;
            padding-right: 1px;
            & .item {
                & a {
                    display: block;
                    &.gallery-popup {
                        position: relative;
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background-color: $heading-color;
                            opacity: 0;
                            transition: all 0.3s ease 0s;
                        }
                        & i {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%) scale(0);
                            opacity: 0;
                            font-size: 40px;
                            color: $white;
                            transition: all 0.3s ease 0s;
                        }
                        &:hover {
                            &::before {
                                opacity: 0.75;
                            }
                            & i {
                                transform: translateX(-50%) translateY(-50%) scale(1);
                                opacity: 1;
                            }
                        }
                    }
                }
                & img {
                    width: 100%;
                }
            }
        }
        & .product-thumb-slider {
            margin: -5px;
            & .item {
                padding: 5px;
                & img {
                    width: 100%;
                    border: 1px solid #f1f2f3;
                }
            }
        }
        & .content {
            & .title {
                font-size: 36px;
                font-weight: 600;
                line-height: 1.2;
                margin-bottom: 15px;
                // Responsive
                @media #{$tablet-device}{
                    font-size: 24px;
                }
                @media #{$large-mobile}{
                    font-size: 24px;
                }
            }
            & .price {
                font-size: 16px;
                line-height: 1;
                margin-bottom: 15px;
                & span {
                    &.new {
                        font-weight: 700;
                        color: #d80000;
                        margin-left: 10px;
                        font-size: 18px;
                    }
                    &.old {
                        color: $body-color;
                        text-decoration: line-through;
                    }
                }
            }
            & .actions {
                display: flex;
                flex-wrap: wrap;
                & .pro-qty {
                    text-align: center;
                    border: 1px solid #eeeeee;
                    margin-right: 10px;
                    & .qtybtn {
                        height: 42px;
                        padding: 0 10px;
                        border: none;
                        background-color: transparent;
                    }
                    & input {
                        height: 42px;
                        width: 50px;
                        text-align: center;
                        border-width: 0 1px;
                        border-style: solid;
                        border-color: #eeeeee;
                        color: $body-color;
                    }
                }
                & .btn {
                    line-height: 1.5;
                }
            }
            & .product-meta{
                border-top: 1px solid #eee;
                margin-top: 30px;
                & span{
                    font-weight: 600;
                    display: block;
                    text-transform: uppercase;
                    border-bottom: 1px solid #eee;
                    color: #777;
                    padding: 10px 0;
                    margin-bottom: 0;
                    & a{
                        font-weight: 400;
                        color: $heading-color;
                        font-size: 14px;
                        &:hover{
                            color: $theme-color;
                        }
                    }
                }
            }
            & .product-share-buttons{
                border-bottom: 1px solid #eee;
                & span{
                    display: inline-block;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: #777;
                    padding: 10px 0;
                    margin-right: 5px;
                }
                & a{
                    display: inline-block;
                    margin-right: 10px;
                    color: $heading-color;
                    &:hover{
                        color: $theme-color
                    }
                }
            }
        }
    }
}
/* Shop Filter CSS */
.shop-filter-right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // Responsive
    @media #{$large-mobile}{
        justify-content: flex-start;
    }
    h4{
        font-size: 14px;
        font-weight: 600;
        line-height: 35px;
        text-transform: uppercase;
        margin-right: 10px;
        margin-bottom: 0;
    }
}
.result-count{
    p{
        // Responsive
        @media #{$large-mobile}{
            margin-bottom: 15px;
        }  
    }
}

/*-- Product Details Tab List --*/
.product-details-tab-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0px 30px;
    border-bottom: 1px solid #d3ced2;
    & li {
        padding: 0 15px;
        & a {
            line-height: 1;
            display: block;
            font-size: 16px;
            font-family: $heading-font;
            color: $heading-color;
            font-weight: 700;
            line-height: 1.2;
            padding-bottom: 5px;
            border-bottom: 2px solid transparent;
            &.active {
                border-color: $theme-color;
                color: $theme-color;
            }
        }
    }
}

/*-- Product Details Tab Content --*/
.product-description {
    & ul{
        list-style: disc;
        padding: 15px;
        padding-bottom: 0;
        & li{
            font-size: 14px;
            line-height: 1.5;
        }
    }
}
.product-specification {
    padding-top: 5px;
}
.product-review {}

.review-form {
    & h4 {
        margin-bottom: 30px;
    }
    & input {
        width: 100%;
        height: 50px;
        border: 2px solid #ddd;
        padding: 5px 20px;
        color: $body-color;
        &:focus{
            border-color: $theme-color;
        }
    }
    & textarea {
        width: 100%;
        height: 120px;
        border: 2px solid #ddd;
        padding: 10px 20px;
        color: $body-color;
        resize: none;
        &:focus{
            border-color: $theme-color;
        }
    }
}

