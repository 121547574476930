/*---------------------------------------
    10. Blog CSS
-----------------------------------------*/

/*-- Blog --*/
.blog {
    &.post-full-item{
        border-bottom: 1px solid #eee;
        padding-bottom: 20px;
        & .content{
            & p{
                line-height: 1.8;
            }
            & blockquote{
                border: 2px solid $theme-color;
                border-left-width: 10px;
                padding: 20px;
                font-size: 18px;
                margin: 24px 40px;
                // Responsive
                @media #{$large-mobile}{
                    margin: 10px 0px;
                }
            }
        }
    }
    & .blog-inner {
        &.heading-color{
            & .content{
                & .title{
                    color: $heading-color;
                }
            }
        }
        & .blog-image {
            margin-bottom: 40px;
            position: relative;
            & .image {
                display: block;
                & img {
                    width: 100%;
                }
            }
            & .meta {
                &.theme-color{
                    background-color: $theme-color;
                    // Responsive
                    @media #{$large-mobile}{
                        width: 100%;
                    }
                    &::after{
                        border-left-color: $theme-color;
                    }
                    & li{
                        & i{
                            color: $heading-color;
                        }
                    }
                }
                display: flex;
                flex-wrap: wrap;
                position: absolute;
                left: 0;
                bottom: -20px;
                padding: 7px 0 8px 15px;
                background-color: $white;
                // Responsive
                @media #{$large-mobile}{
                    width: 100%;
                }
                &::after{
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 17px 0 17px 10px;
                    border-color: transparent;
                    border-left-color: $white;
                    position: absolute;
                    right: -10px;
                    top: 0;
                    // Responsive
                    @media #{$large-mobile}{
                        display: none;
                    }
                }
                & li {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 18px;
                    text-transform: capitalize;
                    color: $heading-color;
                    margin-right: 40px;
                    // Responsive
                    @media #{$desktop-device}{
                        margin-right: 20px;
                    }
                    @media #{$large-mobile}{
                        margin-right: 20px;
                    }
                    &:last-child{
                        margin-right: 20px;
                    }
                    & i{
                        color: $theme-color;
                        margin-right: 5px;
                    }
                    & a {
                        color: $heading-color;
                    }
                }
            }
        }
        & .content {
            &.blog-grid-content{
                & .title {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 1.5;
                    margin-bottom: 20px;
                    color: $heading-color;
                    // Responsive
                    @media #{$desktop-device}{
                        font-size: 14px;
                    }
                    @media #{$large-mobile}{
                        font-size: 16px;
                        margin-bottom: 15px;
                    }
                } 
                & p{
                    margin-bottom: 15px;
                    line-height: 1.8;
                }
                & > a {
                    font-size: 14px;
                    font-weight: 600;
                    color: $theme-color;
                    & i{
                        margin-left: 5px;
                    }
                }

            }
            & .title {
                font-size: 15px;
                font-weight: 700;
                line-height: 1.5;
                margin-bottom: 20px;
                color: $white;
                // Responsive
                @media #{$desktop-device}{
                    font-size: 14px;
                }
                & a {}
            }
            & p{
                margin-bottom: 15px;
                line-height: 1.8;
            }
            & > a {
                font-size: 14px;
                font-weight: 600;
                color: $theme-color;
                & i{
                    margin-left: 5px;
                }
            }
        }
    }
    & .blog-list{
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 30px;
        border-bottom: 1px solid #eee;
        // Responsive
        @media #{$large-mobile}{
            padding-bottom: 0px;
        }
        & .blog-image {
            flex-basis: 33.333%;
            max-width: 33.333%;
            padding-right: 20px;
            margin-bottom: 0px;
            // Responsive
            @media #{$large-mobile}{
                flex-basis: 100%;
                max-width: 100%;
                padding-right: 0px;
            }
            & .image {
                display: block;
                & img {
                    width: 100%;
                }
            }
        }
        & .content{
            flex-basis: calc(100% - 33.333%);
            max-width: calc(100% - 33.333%);
            // Responsive
            @media #{$large-mobile}{
                flex-basis: 100%;
                max-width: 100%;
            }
            & .meta {
                &.theme-color{
                    background-color: $theme-color;
                    &::after{
                        border-left-color: $theme-color;
                    }
                    & li{
                        & i{
                            color: $heading-color;
                        }
                    }
                }
                display: inline-block;
                line-height: 1;
                position: relative;
                padding: 7px 0 8px 15px;
                background-color: $white;
                margin-bottom: 15px;
                // Responsive
                @media #{$large-mobile}{
                    width: 100%;
                }
                &::after{
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 17px 0 17px 10px;
                    border-color: transparent;
                    border-left-color: $white;
                    position: absolute;
                    right: -10px;
                    top: 0;
                    // Responsive
                    @media #{$large-mobile}{
                        display: none;
                    }
                }
                & li {
                    display: inline-block;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 18px;
                    text-transform: capitalize;
                    color: $heading-color;
                    margin-right: 40px;
                    // Responsive
                    @media #{$desktop-device}{
                        margin-right: 20px;
                    }
                    @media #{$large-mobile}{
                        margin-right: 20px;
                    }
                    &:last-child{
                        margin-right: 20px;
                    }
                    & i{
                        color: $theme-color;
                        margin-right: 5px;
                    }
                    & a {
                        color: $heading-color;
                    }
                }
            }
            & .title {
                font-size: 18px;
                line-height: 1.2;
                margin-bottom: 20px;
                // Responsive
                @media #{$desktop-device}{
                    font-size: 14px;
                }
                @media #{$large-mobile}{
                    font-size: 16px;
                }
                & a {}
            }
        }
    }
}
