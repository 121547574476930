/*----------------------------------------*/
/*  09. Testimonial CSS
/*----------------------------------------*/
/* Testimonial Dots */
.testimonial-slider-content-two{
    & .slick-dots{
        position: absolute;
        bottom: 90px;
        // Responsive
        @media #{$large-mobile}{
            bottom: 0;
            justify-content: center;
        }
    }
}
/*-- Testimonial --*/
.testimonial{
    &.testimonial-style-2{
        & .testimonial-inner{
            & .testimonial-description{
                & blockquote{
                    padding: 30px 40px 36px 100px;
                    border: 2px solid $theme-color;
                    margin: 0 0 30px;
                    // Responsive
                    @media #{$large-mobile}{
                        padding: 30px 20px;
                    }
                    &::after{
                        content: '';
                        height: 2px;
                        width: 20px;
                        position: absolute;
                        left: 30px;
                        bottom: -2px;
                        background-color: $white;
                    }
                }
            }
            & .testimonial-author{
                padding-left: 0;
            }
        }
    }
    &.color-transparent{
        & .testimonial-inner{
            background-color: transparent;
            & .testimonial-description{
                & blockquote{
                    color: #ccc;
                }
            }
        }
        & .testimonial-author{
            & .author-info{
                & h4{
                    color: $white;
                    & span{
                        color: #ccc;
                        & a{
                            color: #ccc;
                            &:hover{
                                color: $theme-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .testimonial-inner {
        border-radius: 10px;
        background-color: #fff;
        & .testimonial-description{
            & blockquote{
                padding: 30px 40px 20px 100px;
                position: relative;
                font-size: 18px;
                line-height: 28px;
                font-style: italic;
                color: rgba(0,0,0,.7);
                // Responsive
                @media #{$large-mobile}{
                    padding: 30px 20px;
                    margin-bottom: 0;
                }
                &::before{
                    content: url('../images/icons/qute.png');
                    position: absolute;
                    top: 30px;
                    left: 30px;
                    float: left;
                    padding-top: 10px;
                    padding-right: 20px;
                    // Responsive
                    @media #{$large-mobile}{
                        position: static;
                    }
                }
            }
        }
        & .testimonial-author {
            display: flex;
            align-items: center;
            padding-left: 100px;
            padding-bottom: 30px;
            // Responsive
            @media #{$large-mobile}{
                padding-left: 20px;
                padding-bottom: 30px;
            }
            & .author-thumb{
                width: 90px;
                padding-right: 20px;
                & img{
                    width: 100%;
                }
            }
            & .author-info{
                & h4{
                    font-size: 18px;
                    font-family: $body-font;
                    line-height: 1.5;
                    font-weight: 700;
                    margin-bottom: 0;
                }
                & span{
                    display: block;
                    color: #999999;
                    & a{
                        color: #111;
                        font-style: italic;
                        &:hover{
                            color: $theme-color;
                        }
                    }
                }
            }
        }
    }
}

.gutter-30 {
    margin-left: -15px;
    margin-right: -15px;
    @media #{$large-mobile}{
        margin-left: 0;
        margin-right: 0;
    }
    @media #{$tablet-device}{
        margin-left: 0;
        margin-right: 0;
    }
    .gutter-item{
        padding-left: 15px;
        padding-right: 15px;
        @media #{$large-mobile}{
            padding-left: 0;
            padding-right: 0;
        }
        @media #{$tablet-device}{
            padding-left: 0;
            padding-right: 0;
        }
    }
}









