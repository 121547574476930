/*----------------------------------------*/
/*  07. Cost Calculator CSS
/*----------------------------------------*/
.cost-cal-sec-image{
    margin-right: -50px;
    margin-left: -70px;
    & img{
        max-width: none;
    }
}
.cost-cal-sec-image-2{
    margin-right: -220px;
    margin-left: -30px;
    & img{
        max-width: none;
    }
}
.cost-calculator-inner{
    padding-top: 65px;
    padding-right: 35px;
    padding-left: 35px;
    background-color: $white;
    //Responsive
    @media #{$laptop-device}{
        padding-top: 60px;
    }
    @media #{$desktop-device}{
        padding-top: 50px;
    }
    @media #{$tablet-device}{
        padding-top: 40px;
    }
    @media #{$large-mobile}{
        padding-top: 45px;
        padding-right: 20px;
        padding-left: 20px;
    }
}
.cost-cal-menu{
    & ul{
        text-align: center;
        & li{
            display: inline-block;
            border-bottom: 1px solid #eee;
            // Responsive
            @media #{$large-mobile}{
                border-bottom: 0;
            }
            & a{
                display: block;
                position: relative;
                font-size: 15px;
                line-height: 1.2;
                font-weight: 700;
                font-family: $heading-font;
                color: $heading-color;
                margin: 0 15px;
                padding: 0 0 5px;
                &.active, &:hover{
                    color: $theme-color;
                }
                //Responsive
                @media #{$large-mobile}{
                   font-size: 16px;
                }
                &::before{
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    height: 2px;
                    width: 100%;
                    background-color: $theme-color;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.3s ease 0s;
                    //Responsive
                    @media #{$large-mobile}{
                       display: none;
                    }
                }
                &.active{
                    &::before{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
.cost-calculator-area {
	padding: 45px 0;
    padding-bottom: 40px;
    //Responsive
    @media #{$large-mobile}{
        padding-top: 20px;
    }
}
.ext-options{
    display: none;
}
.single-cost-cal{
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    //Responsive
    @media #{$large-mobile}{
       flex-wrap: wrap;
    }
    & label{
        flex-basis: 220px;
        width: 220px;
        padding-right: 20px;
        //Responsive
        @media #{$large-mobile}{
           flex-basis: 100%;
           width: 100%;
           padding-right: 0;
        }
    }
    & input{
        width: calc(100% - 220px);
        flex-basis: calc(100% - 220px);
        display: block;
        line-height: 2;
        min-height: 38px;
        padding: 0 12px;
        background-color: $white;
        border: 1px solid #ccc;
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        transition: all 380ms ease;
        color: $body-color;
        border-radius: 2px;
        outline: none;
        &:focus{
            border-color: #999;
            box-shadow: 5px 0 0 #52b7bd inset, 0 2px 10px rgba(0,0,0,0.2);
            outline: none;
        }
        //Responsive
        @media #{$large-mobile}{
           flex-basis: 100%;
           width: 100%;
        }
    }
    & .switch{
        & .cmn-toggle {
            position: absolute;
            visibility: hidden;

            + label {
                display: block;
                position: relative;
                cursor: pointer;
                outline: none;
                user-select: none;
            }
        }

        /* Round Style CSS Toggle Switch */

        & > input {
            &.cmn-toggle-round-flat {
                + label {
                    padding: 2px;
                    width: 76px;
                    height: 38px;
                    background-color: #dddddd;
                    border-radius: 60px;
                    transition: all 0.4s ease-in-out;

                    &:before,
                    &:after {
                        display: block;
                        position: absolute;
                        content: "";
                    }

                    &:before {
                        top: 1px;
                        left: 1px;
                        bottom: 1px;
                        right: 1px;
                        background-color: #f3f4fa;
                        border-radius: 60px;
                        transition: 0.4s;
                    }

                    &:after {
                        top: 6px;
                        left: 6px;
                        bottom: 6px;
                        width: 26px;
                        height: 26px;
                        background-color: #dddddd;
                        border-radius: 50%;
                        transition: margin 0.4s, background 0.4s;
                    }
                }

                &:checked + label {
                    background-color: #dddddd;

                    &:after {
                        margin-left: 38px;
                        background-color: #52b7bd;
                    }
                }
            }
        }
        }

    &  .ui-widget{
        width: calc(100% - 220px);
        flex-basis: calc(100% - 220px);
        height: 6px;
        background-color: #d8d8d8;
        border: none;
        border-radius: 3px;
        position: relative;
        //Responsive
        @media #{$large-mobile}{
           flex-basis: 100%;
           width: 100%;
        }
        & .ui-slider-range {
            background-color: $theme-color;
            border-radius: 50px;
        }
        & .ui-slider-handle{
            background-color: #fff;
            min-width: 24px;
            height: 24px;
            box-shadow: none !important;
            border: 1px solid #ddd;
            outline: none;
            top: -10px;
            margin-left: -18px;
            border-radius: 50%;
            cursor: grab;
            transition: none;
            &::after{
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                height: 12px;
                width: 12px;
                background: $theme-color;
                border-radius: 100%;
                opacity: 1;
                transform: translate(-50%, -50%);
            }
            & span{
                position: absolute;
                top: -32px;
                left: 50%;
                min-width: 27px;
                height: 25px;
                border-radius: 5px;
                font-size: 13px;
                line-height: 25px;
                padding: 0 5px;
                transform: translateX(-50%);
                background: $heading-color;
                font-weight: 700;
                color: $white;
                text-align: center;
                display: block;
                &::after{
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 50%;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 5px 5px 0 5px;
                    border-color: transparent transparent transparent transparent;
                    border-top-color: $heading-color;
                    transform: translateX(-50%);
                }
            }
        }
    }
    & .nice-select{
        width: calc(100% - 220px);
        flex-basis: calc(100% - 220px);
        //Responsive
        @media #{$large-mobile}{
           flex-basis: 100%;
           width: 100%;
        }
    }
}
.total-cost-cal {
    overflow: hidden;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	border-top: 1px solid #ececec;
	border-bottom: 1px solid #ececec;
	padding: 15px 0;
    //Responsive
    @media #{$large-mobile}{
       flex-wrap: wrap;
    }
    & .total-text{
        & i{
            display: inline-block;
            vertical-align: middle;
            margin-right: 15px;
            font-size: 32px;
            color: $theme-color;
        }
        & span{
            font-size: 16px;
            line-height: 1;
            font-weight: 700;
            color: $heading-color;
            font-family: $heading-font;
        }
    }
    & .point-submit{
        display: flex;
        align-items: center;
        & .total-point{
            & span{
                font-size: 32px;
                line-height: 1.2;
                font-weight: 700;
                color: $heading-color;
                font-family: $heading-font;
            }
        }
    }
}














