/*----------------------------------------*/
/*  25. Brand CSS
/*----------------------------------------*/

/*-- Brand Slider --*/
.brand-slider, .brand-slider-two, .brand-slider-three{
    & .slick-dots {
        display: flex;
        justify-content: center;
    }
}

/*-- Brand --*/
.single-brand {
    &.brand-border{
        border: 2px solid #dddddd;
        transition: all 0.3s ease-in-out;
        &:hover{
            border-color: $theme-color;
        }
    }
    background: #fff;
    padding: 10px;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255,255,255,.85);
        z-index: 9;
        transition: all 0.5s ease-in-out;
        opacity: 0;
        visibility: hidden;
    }
    & .brand-image{
        position: relative;
        overflow: hidden;
        & img{
            width: 100%;
            height: auto;
        }
    }
    & .brand-number{
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        transform: scale(0.2, 0.2) translateY(-50%);
        opacity: 0;
        visibility: hidden;
        z-index: 99;
        transition: all 0.3s ease-in-out;
        & span{
            font-size: 14px;
            line-height: 22px;
            font-weight: 600;
            color: $heading-color;
        }
    }
    &:hover{
        &::before{
            opacity: 1;
            visibility: visible;
            height: 100%;
        }
        & .brand-number{
            opacity: 1;
            visibility: visible;
            transform: scale(1.0, 1.0) translateY(-50%);
        }
    }
}

