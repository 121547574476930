/*-----------------------------------------------------------------------------------

    Template Name: Koncrete - Construction Bootstrap 4 Template
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
    02. Header CSS
    03. Hero CSS
    04. About CSS
    05. Feature CSS
    06. Service CSS
    07. Cost Calculator CSS
    09. Testimonial CSS
    10. Blog CSS
    11. Cta CSS
    12. Work CSS
    13. Offer CSS
    14. Choose CSS
    15. Team CSS
    16. Faq CSS
    17. 404 CSS
    18. Maintenance CSS
    19. Product CSS
    20. Cart CSS
    21. Checkout CSS
    22. My Account CSS
    23. Comment CSS
    24. Sidebar CSS
    25. Brand CSS
    26. Contact CSS
    27. Footer CSS

-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Lato:400,700|Montserrat:400,700");
/*-- Common Style --*/
*, *::after, *::before {
  box-sizing: border-box;
}

html, body, .site-wrapper {
  height: 100%;
}

body {
  background-color: #ffffff;
  font-size: 15px;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
  visibility: visible;
  font-family: "Lato", sans-serif;
  color: #232323;
  position: relative;
}

body[data-rtl="rtl"] {
  direction: rtl;
  text-align: right;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  color: #1d2634;
  font-weight: 400;
  margin-top: 0;
  line-height: 1.2;
}

h1 {
  font-size: 36px;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 575px) {
  h1 {
    font-size: 30px;
  }
}

h2 {
  font-size: 30px;
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input, span {
  transition: all 0.3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #fbd232;
}

button, input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

/*-- 
    - Common Classes
-----------------------------------------*/
#scrollUp {
  background: #222;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100%;
  bottom: 25px;
  right: 25px;
  color: #fff;
  text-align: center;
  font-size: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#scrollUp:hover {
  background: #fbd232;
}

.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section, .main-wrapper {
  float: left;
  width: 100%;
}

.border-bottom {
  border-bottom: 4px solid #fbd232 !important;
}

.border-bottom-1 {
  border-bottom: 1px solid #eee !important;
}

.demo-text p {
  line-height: 1.5;
}

.demo-text blockquote {
  border: 2px solid #fbd232;
  border-left-width: 10px;
  padding: 20px;
  font-size: 18px;
  margin: 24px 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .demo-text blockquote {
    margin: 20px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .demo-text blockquote {
    margin: 10px 15px;
  }
}

.overlay {
  position: relative;
  z-index: 9;
  background-position: 50% 50%;
}

.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  z-index: -1;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
  .row-five-column > [class*="col-xl-"] {
    max-width: 20%;
    flex: 0 0 20%;
  }
}

@media only screen and (max-width: 575px) {
  .container {
    max-width: 450px;
  }
}

@media only screen and (max-width: 479px) {
  .container {
    max-width: 300px;
  }
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
  margin: 0 !important;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder, textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*--
    - Background Color
------------------------------------------*/
.bg-white {
  background-color: #ffffff !important;
}

.bg-gray {
  background-color: #f8f8f8 !important;
}

.bg-gray-two {
  background-color: #eeeeee !important;
}

.bg-black {
  background-color: #111111;
  opacity: .95;
}

.bg-dark {
  background-color: #1d2634 !important;
}

.bg-dark-two {
  background-color: #1c2535 !important;
}

.bg-dark-three {
  background-color: #1d2736 !important;
}

.bg-dark-four {
  background-color: #1d2634 !important;
}

.bg-skyblue {
  background-color: #dfecf7 !important;
}

.bg-theme {
  background-color: #fbd232 !important;
}

/*-- 
    - Tab Content & Pane Fix
------------------------------------------*/
.tab-content {
  width: 100%;
}

.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/*-- 
    - Main Wrapper
------------------------------------------*/
/*-- 
    - Section Title
------------------------------------------*/
.section-title h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  margin: 0;
}

@media only screen and (max-width: 479px) {
  .section-title h1 {
    font-size: 24px;
  }
}

.section-title h3 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
}

.section-title p {
  margin-top: 15px;
  line-height: 22px;
}

.section-title.full-width p {
  margin-bottom: 35px;
  max-width: 100%;
}

.section-title.text-center {
  text-align: center;
  background-position: top center;
}

.section-title.text-center p {
  margin-left: auto;
  margin-right: auto;
}

.section-title.text-left {
  text-align: left;
  background-position: top left;
}

.section-title.text-left p {
  margin-left: 0;
  margin-right: auto;
}

.section-title.text-right {
  text-align: right;
  background-position: top right;
}

.section-title.text-right p {
  margin-left: auto;
  margin-right: 0;
}

.section-title.color-white h1 {
  color: #ffffff;
}

.section-title.color-white p {
  color: #d2d2d2;
}

.section-title-two h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-two h1 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-two h1 {
    font-size: 21px;
  }
}

@media only screen and (max-width: 479px) {
  .section-title-two h1 {
    font-size: 21px;
  }
}

.section-title-two h1 span {
  color: #fbd232;
}

.section-title-three h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 1.2;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .section-title-three h1 {
    font-size: 22px;
  }
}

.section-title-three h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 1.2;
  margin: 0;
  color: #fbd232;
}

@media only screen and (max-width: 767px) {
  .section-title-three h2 {
    font-size: 22px;
  }
}

.section-title-three p {
  margin-top: 15px;
  line-height: 22px;
}

/*-- 
    - Button
------------------------------------------*/
.btn {
  background-color: #fbd232;
  font-family: "Montserrat", sans-serif;
  color: #1d2634;
  font-size: 13px;
  line-height: 1.2;
  font-weight: 700;
  padding: 10px 15px;
  text-transform: uppercase;
  border-radius: 0px;
  position: relative;
  transition: all 0.3s ease 0s;
}

.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn i {
  margin-left: 5px;
  font-size: 14px;
}

.btn:hover {
  background-color: #1d2634;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .btn {
    font-size: 13px;
    line-height: 1.2;
    padding: 10px 15px;
  }
  .btn:hover::before {
    left: 6px;
    top: 6px;
  }
  .btn:hover::after {
    left: -6px;
    top: -6px;
  }
}

/* Pentagon Icon CSS */
.pentagon-icon .icon {
  position: relative;
  width: 50px;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: #fbd232;
  border-radius: 6px;
  margin: 0 20px;
}

@media only screen and (max-width: 767px) {
  .pentagon-icon .icon {
    width: 38px;
    height: 57px;
    margin: 0 15px;
  }
}

.pentagon-icon .icon::before, .pentagon-icon .icon::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fbd232;
  transform: rotate(60deg);
  z-index: -1;
  border-radius: 6px;
}

.pentagon-icon .icon::after {
  transform: rotate(-60deg);
}

.pentagon-icon .icon i {
  font-size: 26px;
  color: #1d2634;
}

/*-- 
    - Page Banner Section
------------------------------------------*/
.page-banner-section {
  margin-top: 130px;
  padding: 80px 0 90px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1;
}

.page-banner-section::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #000000;
  opacity: 0.75;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner-section {
    margin-top: 122px;
    padding: 60px 0 70px;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner-section {
    margin-top: 122px;
    padding: 40px 0 50px;
  }
}

@media only screen and (max-width: 575px) {
  .page-banner-section {
    margin-top: 163px;
    padding: 25px 0 35px;
  }
}

@media only screen and (max-width: 479px) {
  .page-banner-section {
    margin-top: 151px;
  }
}

/*-- Page Banner --*/
.page-banner h1 {
  font-size: 48px;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0.1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner h1 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .page-banner h1 {
    font-size: 24px;
  }
}

/*-- Page Breadcrumb --*/
.breadcrumb-title h2 {
  font-size: 36px;
  font-weight: 700;
  color: #ffffff;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-title h2 {
    font-size: 24px;
  }
}

.breadcrumb-title-two {
  text-align: center;
}

.breadcrumb-title-two h2 {
  font-size: 60px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-title-two h2 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-title-two h2 {
    font-size: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-title-two h2 {
    font-size: 36px;
  }
}

.breadcrumb-title-two p {
  font-size: 18px;
  color: #ffffff;
  text-align: center;
}

/*-- 
    - Page Pagination
------------------------------------------*/
.page-pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -5px 0px;
  padding-top: 40px;
  border-top: 1px solid #eee;
}

.page-pagination li {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  margin: 5px 15px;
}

.page-pagination li a {
  position: relative;
  background-color: #fbd232;
  color: #1d2634;
  width: 30px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.page-pagination li a::before, .page-pagination li a::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fbd232;
  transform: rotate(60deg);
  z-index: -1;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.page-pagination li a::after {
  transform: rotate(-60deg);
}

.page-pagination li a i {
  font-size: 18px;
  margin-right: 0px;
  line-height: 25px;
  font-weight: 700;
  color: #1d2634;
}

.page-pagination li:hover a {
  color: #ffffff;
  background-color: #1d2634;
}

.page-pagination li:hover a i {
  color: #ffffff;
}

.page-pagination li:hover a::before, .page-pagination li:hover a::after {
  background-color: #1d2634;
}

.grid-filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.grid-filter button {
  background-color: transparent;
  color: #1d2634;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  margin: 0 10px 10px;
  text-transform: capitalize;
  line-height: 1;
  padding-bottom: 5px;
  position: relative;
}

.grid-filter button::before {
  content: "";
  height: 6px;
  width: 0;
  position: absolute;
  left: 0;
  bottom: 4px;
  background-color: #fbd232;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.grid-filter button:hover::before, .grid-filter button.active::before {
  width: 100%;
}

.grid-filter.center {
  justify-content: center;
}

.grid-filter.center button {
  margin: 0 10px 10px;
}

.grid-filter.left {
  justify-content: flex-start;
}

.grid-filter.left button {
  margin-left: 0;
  margin-right: 20px;
  margin-bottom: 10px;
}

.grid-filter.left button:last-child {
  margin-right: 0;
}

.grid-filter.right {
  justify-content: flex-end;
}

.grid-filter.right button {
  margin-left: 20px;
  margin-right: 0;
  margin-bottom: 10px;
}

.grid-filter.right button:last-child {
  margin-left: 0;
}

.slick-slider .slick-dots {
  width: 100%;
  padding: 0;
  margin: 20px 0 0;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

.slick-slider .slick-dots li {
  margin: 0 5px;
}

.slick-slider .slick-dots li button {
  display: block;
  padding: 0;
  width: 30px;
  height: 5px;
  border: none;
  background-color: #d8d8d8;
  text-indent: -9999px;
}

.slick-slider .slick-dots li.slick-active button {
  background-color: #fbd232;
}

.gallery-item {
  position: relative;
}

.gallery-item::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #1d2634;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.gallery-item img {
  width: 100%;
}

.gallery-item .plus {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 3;
  opacity: 0;
}

.gallery-item .plus::before, .gallery-item .plus::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #ffffff;
  transition: all 0.3s ease 0s;
}

.gallery-item .plus::before {
  width: 150px;
  height: 1px;
}

.gallery-item .plus::after {
  width: 1px;
  height: 150px;
}

.gallery-item:hover::before {
  opacity: 0.75;
}

.gallery-item:hover .plus {
  opacity: 1;
}

.gallery-item:hover .plus::before {
  width: 40px;
}

.gallery-item:hover .plus::after {
  height: 40px;
}

blockquote.blockquote {
  background-color: #f1f2f3;
  padding: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

blockquote.blockquote::before {
  position: absolute;
  content: '';
  left: -5px;
  top: -10px;
  z-index: -1;
  opacity: 0.07;
}

blockquote.blockquote p {
  font-size: 18px;
  font-style: italic;
}

blockquote.blockquote .author {
  font-size: 14px;
  display: block;
  line-height: 18px;
}

/*----------------------------------------*/
/*  02. Header CSS
/*----------------------------------------*/
header.header {
  background-color: #ffffff;
  position: static;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

header.header.is-sticky {
  position: fixed;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: slideInDown;
  animation-duration: 0.5s;
}

header.header.is-sticky .header-top {
  display: none;
}

header.header.is-sticky .header-middle {
  display: none;
}

header.header.is-sticky .main-menu.main-menu-two {
  position: static;
  margin-bottom: 0;
}

/*-- Header Top --*/
.header-top {
  background-color: #222;
}

.header-top > .container > .row > .col {
  flex-grow: 0;
  margin: 6px 0;
  display: flex;
}

.header-top > .container > .row > .col:first-child {
  flex-grow: 1;
}

@media only screen and (max-width: 575px) {
  .header-top > .container > .row > .col {
    flex: 1 0 100%;
    justify-content: center;
    margin: 0;
  }
  .header-top > .container > .row > .col:first-child {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .header-top > .container > .row > .col:last-child {
    margin-bottom: 10px;
  }
}

/*-- Header Top Links --*/
.header-top-links ul {
  display: flex;
}

.header-top-links ul li {
  font-size: 14px;
  line-height: 26px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.header-top-links ul li:last-child {
  margin-right: 0;
}

.header-top-links ul li a {
  display: block;
}

.header-top-links ul li a:hover {
  color: #ffffff;
}

.header-top-links-three ul {
  display: flex;
  position: relative;
}

.header-top-links-three ul::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: -43%;
  top: 0;
  background-color: #111111;
}

.header-top-links-three ul li {
  line-height: 26px;
  padding-left: 20px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  position: relative;
}

.header-top-links-three ul li::before, .header-top-links-three ul li::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 20px;
  border-color: transparent;
  position: absolute;
  top: 0;
}

.header-top-links-three ul li::before {
  border-left-color: #111111;
  right: 0;
  z-index: 9;
}

.header-top-links-three ul li::after {
  border-left-color: rgba(255, 255, 255, 0.1);
  right: -2px;
  z-index: 1;
}

.header-top-links-three ul li i {
  margin-right: 10px;
  line-height: 26px;
  padding-top: 2px;
  color: #fbd232;
}

.header-top-links-three ul li a {
  display: block;
}

@media only screen and (max-width: 479px) {
  .header-top-links-three ul li {
    font-size: 12px;
    margin-right: 10px;
  }
  .header-top-links-three ul li:last-child {
    margin-right: 0;
  }
}

/*-- Header Top Social --*/
.header-top-menu > ul {
  text-align: right;
}

.header-top-menu > ul > li {
  display: inline-block;
  padding: 9px 30px;
  border-left: 1px solid rgba(238, 238, 238, 0.1);
  position: relative;
  text-align: left;
}

.header-top-menu > ul > li:hover .ht-dropdown {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.header-top-menu > ul > li > a {
  font-size: 14px;
  display: block;
  color: #ffffff;
}

.header-top-menu > ul > li > a:hover {
  color: #ffffff;
}

/* Header top Dropdown */
.ht-dropdown {
  position: absolute;
  top: 120%;
  left: 0;
  width: 200px;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ht-dropdown > li {
  padding: 5px 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #222;
}

.ht-dropdown > li a {
  font-size: 14px;
  display: block;
  color: #ffffff;
}

.ht-dropdown > li a:hover {
  color: #ffffff;
}

/* Header Middle */
.header-middle {
  background-color: #111;
  position: relative;
}

/* Header Bottom */
.header-bottom.menu-right {
  position: relative;
}

.logo {
  padding-top: 47px;
  padding-bottom: 47px;
  padding-left: 15px;
  padding-right: 20px;
  position: relative;
  z-index: 9;
  background-color: #fbd232;
}

.logo.tringle-none {
  background-color: #111;
}

.logo.tringle-none::before {
  display: none;
}

.logo.tringle-none::after {
  display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .logo {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
  }
}

.logo::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1000px;
  width: 1000px;
  z-index: -1;
  background-color: #fbd232;
}

.logo::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 65px 0 65px 40px;
  border-color: transparent;
  border-left-color: #fbd232;
  position: absolute;
  top: 0;
  right: -40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo::after {
    border-width: 43px 0 43px 25px;
    right: -25px;
  }
}

@media only screen and (max-width: 767px) {
  .logo::after {
    border-width: 38px 0 38px 20px;
    right: -20px;
  }
}

@media only screen and (max-width: 479px) {
  .logo::after {
    border-width: 35px 0 35px 20px;
    right: -20px;
  }
}

.logo a {
  display: block;
}

.logo a img {
  margin-left: -20px;
  max-width: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo a img {
    margin-left: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .logo a img {
    margin-left: 0;
    width: 100%;
  }
}

.single-emergncy-contact {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .single-emergncy-contact.theme-color {
    margin-bottom: 30px;
  }
}

.single-emergncy-contact.theme-color .contact-icon .icon {
  width: auto;
  height: auto;
  margin-right: 20px;
}

.single-emergncy-contact.theme-color .contact-icon .icon i {
  color: #fbd232;
  font-size: 32px;
}

@media only screen and (max-width: 767px) {
  .single-emergncy-contact.theme-color .content h3 {
    font-size: 16px;
  }
}

.single-emergncy-contact.icon-black .contact-icon .icon i {
  color: #1d2634;
}

.single-emergncy-contact.content-black .content h3 {
  color: #1d2634;
}

.single-emergncy-contact.content-black .content span {
  color: #1d2634;
}

.single-emergncy-contact .contact-icon .icon {
  width: 30px;
  height: 45px;
}

.single-emergncy-contact .contact-icon .icon i {
  font-size: 18px;
  margin-right: 0px;
  color: #ffffff;
}

.single-emergncy-contact .icon i {
  font-size: 32px;
  margin-right: 20px;
  color: #fbd232;
}

.single-emergncy-contact .content h3 {
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0;
}

.single-emergncy-contact .content span {
  color: rgba(255, 255, 255, 0.8);
}

/*-- Header Search --*/
.header-search {
  margin-left: 50px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-search {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-search {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-search {
    margin-left: 0;
  }
}

@media only screen and (max-width: 479px) {
  .header-search {
    margin-left: 0;
  }
}

.header-search-toggle {
  background-color: transparent;
  border: none;
  color: #fbd232;
  padding: 0;
  line-height: 1;
  width: 30px;
  height: 30px;
}

.header-search-toggle i {
  font-size: 16px;
}

.header-search-toggle.open i {
  font-size: 20px;
}

/*-- Search Form --*/
.header-search-form {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 9;
}

@media only screen and (max-width: 479px) {
  .header-search-form {
    right: -85px;
  }
}

.header-search-form form {
  display: flex;
}

.header-search-form form input {
  width: 250px;
  border: none;
  background-color: transparent;
  color: #232323;
  line-height: 24px;
  padding: 13px 20px;
}

@media only screen and (max-width: 479px) {
  .header-search-form form input {
    width: 216px;
  }
}

.header-search-form form button {
  line-height: 24px;
  padding: 13px 15px;
  border: none;
  background-color: #fbd232;
  display: flex;
  color: #ffffff;
}

.header-search-form form button i {
  font-size: 20px;
  line-height: 20px;
}

/*-- Header Cart --*/
.header-cart {
  margin-left: 10px;
  position: relative;
}

.header-cart > a {
  background-color: transparent;
  border: none;
  color: #fbd232;
  padding: 0;
  line-height: 1;
  width: 30px;
  height: 30px;
  position: relative;
}

.header-cart > a i {
  font-size: 20px;
  margin-top: 2px;
}

.header-cart > a span {
  position: absolute;
  right: -12px;
  top: -16px;
  background-color: #2e2e2e;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 50%;
  color: #ffffff;
}

.header-cart:hover .minicart-box {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* minicart */
.minicart-box {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 99999;
  overflow: auto;
  padding: 30px 20px 27px;
  max-height: 700px;
  width: 360px;
  border-bottom: 3px solid #fbd232;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
  transform: translateY(30px);
  transition: all 0.5s ease-in-out;
}

.minicart-product-wrapper {
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
}

.minicart-product-wrapper .single-minicart-product {
  display: flex;
  margin-bottom: 20px;
}

.minicart-product-wrapper .single-minicart-product__image {
  flex-basis: 80px;
  border: 1px solid #ddd;
}

.minicart-product-wrapper .single-minicart-product__content {
  flex-basis: calc(100% - 80px);
  margin-left: 15px;
  position: relative;
}

.minicart-product-wrapper .single-minicart-product__content .close-icon {
  position: absolute;
  right: 0;
  top: 0;
  font-weight: 700;
  color: #888;
}

.minicart-product-wrapper .single-minicart-product__content .close-icon i {
  font-size: 12px;
}

.minicart-product-wrapper .single-minicart-product__content .close-icon:hover {
  color: #fbd232;
}

.minicart-product-wrapper .single-minicart-product__content .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
}

.minicart-product-wrapper .single-minicart-product__content .title:hover {
  color: #fbd232;
}

.minicart-product-wrapper .single-minicart-product__content .quantity {
  font-size: 15px;
  color: #888;
}

.minicart-product-wrapper .single-minicart-product__content .quantity .price {
  font-weight: 600;
  color: #fbd232;
}

.minicart-calculations {
  font-size: 16px;
  font-weight: 600;
  color: #1d2634;
  margin-bottom: 30px;
  text-align: center;
}

.minicart-calculations .value {
  color: #fbd232;
  font-size: 18px;
}

.minicart-buttons {
  text-align: center;
}

.minicart-link {
  display: inline-block;
  margin: 0 5px;
}

.minicart-link--cart:hover {
  background-color: #fbd232;
  color: #ffffff;
}

.minicart-link--checkout {
  background-color: #fbd232;
  color: #ffffff;
}

.minicart-link--checkout:hover {
  background-color: #fbd232;
  color: #ffffff;
  border-color: #fbd232;
}

/*-- Header Social --*/
.social-menu {
  background-color: #fbd232;
  position: absolute;
  right: 0;
  padding-right: 15%;
  bottom: -29px;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .social-menu {
    padding-right: 6%;
  }
}

.social-menu-top {
  position: relative;
  display: flex;
}

.social-menu-top::before, .social-menu-top::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 29px 15px 29px 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.social-menu-top::before {
  border-color: transparent rgba(255, 255, 255, 0.2) transparent transparent;
  right: 2px;
}

.social-menu-top::after {
  border-color: transparent;
  border-right-color: #fbd232;
  right: 0;
}

.social-menu-top li {
  position: relative;
  width: auto;
  background-color: #fbd232;
  padding: 15px 30px 15px 15px;
  margin: 0;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.social-menu-top li:first-child::before {
  display: none;
}

.social-menu-top li::before, .social-menu-top li::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 29px 15px 29px 0;
  position: absolute;
  top: 0;
  z-index: 1;
  -webkit-transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.social-menu-top li::before {
  border-color: transparent rgba(255, 255, 255, 0.2) transparent transparent;
  left: -17px;
}

.social-menu-top li::after {
  border-color: transparent;
  left: -15px;
  border-right-color: #fbd232;
}

.social-menu-top li a {
  display: block;
  color: #111;
  font-size: 20px;
}

.social-menu-top li:hover {
  background-color: #111111;
}

.social-menu-top li:hover::after {
  border-right-color: #111111;
}

.social-menu-top li:hover a {
  color: #fbd232;
}

.header-top-social {
  justify-content: flex-end;
}

.header-top-social a {
  margin-right: 15px;
  color: rgba(255, 255, 255, 0.8);
}

.header-top-social a:last-child {
  margin-right: 0;
}

.header-top-social a i {
  font-size: 16px;
  display: block;
  line-height: 1;
}

.header-top-social a:hover {
  color: #fbd232;
}

/*-- Header Bottom --*/
.header-bottom.menu-right > .container > .row {
  align-items: center;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-bottom.menu-right > .container > .row {
    align-items: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .header-bottom.menu-right > .container > .row {
    align-items: inherit;
  }
}

.header-bottom.menu-right > .container > .row > .col {
  flex-grow: 0;
  position: static;
}

.header-bottom.menu-right > .container > .row > .col:first-child {
  flex-grow: 1;
}

/*-- Main Menu --*/
.main-menu.main-menu-two {
  position: relative;
  margin-bottom: -32px;
  background-color: #fff;
  z-index: 9;
}

.main-menu.main-menu-two::before {
  content: '';
  position: absolute;
  top: 0;
  left: -62%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -1;
}

.main-menu.main-menu-two > ul > li {
  border-bottom: 0;
}

.main-menu.main-menu-two > ul > li::before {
  content: '';
  position: absolute;
  top: 0;
  right: -20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 35px 0 35px 20px;
  border-color: transparent;
  border-left-color: white;
  z-index: 2;
}

.main-menu.main-menu-two > ul > li a::after {
  display: none;
}

.main-menu.main-menu-three > ul > li {
  border-bottom: 0;
  display: flex;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-three > ul > li > a {
    padding-left: 15px;
    padding-right: 12px;
    font-size: 13px;
  }
}

.main-menu.main-menu-three > ul > li > a::after {
  display: none;
}

.main-menu.main-menu-three > ul > li > a::before {
  content: '';
  width: 0;
  height: 4px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 0;
  background-color: #fbd232;
  transform: translate(-50%, 0);
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.main-menu.main-menu-three > ul > li > a i {
  font-size: 16px;
  margin-left: 5px;
  color: #fbd232;
}

.main-menu.main-menu-three > ul > li.active > a::before, .main-menu.main-menu-three > ul > li:hover > a::before {
  width: 100%;
}

.main-menu > ul {
  display: flex;
}

.main-menu > ul > li {
  position: relative;
  border-bottom: 4px solid transparent;
}

.main-menu > ul > li > a {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  display: block;
  padding-top: 27px;
  padding-bottom: 23px;
  padding-left: 20px;
  padding-right: 20px;
}

.main-menu > ul > li > a::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 3px;
  border-color: transparent;
  border-bottom-color: #fbd232;
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate(-50%, 0);
}

.main-menu > ul > li.has-dropdown > a::after {
  content: "\f107";
  font-family: Fontawesome;
  line-height: 30px;
  margin-left: 3px;
}

.main-menu > ul > li:hover > .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
  border-top-color: #fbd232;
  z-index: 99;
}

.main-menu > ul > li:hover > .mega-menu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

.main-menu > ul > li:last-child .sub-menu {
  left: auto;
  right: 0;
}

.main-menu > ul > li:last-child .sub-menu .sub-menu .sub-menu {
  left: 100%;
  right: auto;
}

.main-menu > ul > li:last-child .sub-menu .sub-menu .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}

.main-menu > ul > li:nth-last-child(-n+3) .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}

.main-menu > ul > li:nth-last-child(-n+3) .sub-menu .sub-menu .sub-menu {
  left: 100%;
  right: auto;
}

.main-menu > ul > li.active, .main-menu > ul > li:hover {
  border-bottom-color: #fbd232;
}

.main-menu > ul > li.active > a, .main-menu > ul > li:hover > a {
  color: #1d2634;
}

.main-menu > ul > li.active > a::after, .main-menu > ul > li:hover > a::after {
  opacity: 1;
  visibility: visible;
}

/*-- Sub Menu --*/
.sub-menu {
  position: absolute;
  left: 0;
  top: 120%;
  background-color: #ffffff;
  border-top: 3px solid transparent;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease 0s;
  width: 300px;
  z-index: -99;
  opacity: 0;
  visibility: hidden;
}

.sub-menu > li {
  position: relative;
  background-color: #ffffff;
}

.sub-menu > li.tag-new::before {
  content: 'NEW';
  font-size: 10px;
  line-height: 1;
  font-weight: 600;
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  padding: 5px;
  color: #fff;
  background-color: #d81313;
  transform: translateY(-50%);
}

.sub-menu > li > a {
  display: block;
  font-size: 14px;
  line-height: 14px;
  padding-top: 15px;
  padding-bottom: 17px;
  padding-left: 20px;
  padding-right: 20px;
}

.sub-menu > li.has-dropdown > a::after {
  content: "\f105";
  font-family: Fontawesome;
  line-height: 14px;
  float: right;
}

.sub-menu > li.active > a {
  color: #fbd232;
}

.sub-menu > li .sub-menu {
  left: 100%;
  top: 0;
  border-top: 3px solid #fbd232;
}

.sub-menu > li:hover {
  background-color: #fbd232;
}

.sub-menu > li:hover > a {
  color: #1d2634;
}

.sub-menu > li:hover > .sub-menu {
  margin-top: -3px;
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

.sub-menu > li .sub-menu {
  left: 100%;
  margin-left: 0;
  top: 0;
}

.sub-menu > li .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}

.sub-menu > li .sub-menu .sub-menu .sub-menu {
  left: 100%;
  right: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-three .sub-menu .has-dropdown .sub-menu {
    left: auto;
    right: 100%;
  }
}

/*-- Mobile Menu --*/
.mobile-header-area {
  background-color: #111;
}

.mobile-cart a {
  font-size: 20px;
  margin-right: 30px;
  color: #fbd232;
}

.mobile-navigation-icon {
  width: 24px;
  height: 25px;
  position: relative;
  cursor: pointer;
  float: right;
}

.mobile-navigation-icon:hover i {
  background-color: #fbd232;
}

.mobile-navigation-icon:hover i:before {
  width: 80%;
  background-color: #fbd232;
}

.mobile-navigation-icon:hover i:after {
  background-color: #fbd232;
  width: 60%;
}

.mobile-navigation-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: #fbd232;
  transition: all 0.3s ease-in-out;
}

.mobile-navigation-icon i:before {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fbd232;
  content: "";
  transition: all 0.3s ease-in-out;
}

.mobile-navigation-icon i:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fbd232;
  content: "";
  transition: all 0.3s ease-in-out;
}

/* offcanvas mobile menu */
.header-mobile-navigation {
  padding: 20px 0;
}

.header-mobile-navigation .mobile-navigation .header-cart-icon a span {
  left: 50%;
}

.offcanvas-widget-area {
  margin-bottom: 35px;
  margin-top: auto;
}

@media only screen and (max-width: 479px) {
  .offcanvas-widget-area {
    margin-bottom: 30px;
  }
}

.off-canvas-contact-widget {
  margin-bottom: 20px;
}

.off-canvas-contact-widget .header-contact-info {
  flex-basis: 33.33%;
}

.off-canvas-contact-widget .header-contact-info__list li {
  display: inline-block;
  margin-right: 25px;
}

.off-canvas-contact-widget .header-contact-info__list li i {
  font-size: 14px;
  margin-right: 5px;
}

.off-canvas-contact-widget .header-contact-info__list li a {
  color: #666;
  font-weight: 400;
  line-height: 1.5;
}

.off-canvas-contact-widget .header-contact-info__list li a:hover {
  color: #fbd232;
}

.off-canvas-contact-widget .header-contact-info__list li:last-child {
  margin-right: 0;
}

.offcanvas-mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  max-width: 100%;
  height: 100vh;
  z-index: 9999;
  transform: translateX(100%);
  padding-left: 60px;
  transition: 0.6s;
}

.offcanvas-mobile-menu.active {
  transform: translateX(0);
}

.offcanvas-mobile-menu.inactive {
  transform: translateX(calc(100% + 60px));
}

.offcanvas-menu-close {
  position: absolute;
  left: 0;
  top: 0;
  background: #343538;
  z-index: 9;
  width: 60px;
  height: 60px;
  color: #fff;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
}

@media only screen and (max-width: 479px) {
  .offcanvas-menu-close {
    width: 50px;
    height: 50px;
    line-height: 45px;
    left: 10px;
    font-size: 25px;
  }
}

.offcanvas-menu-close:hover, .offcanvas-menu-close:focus {
  color: #ffffff;
}

.offcanvas-menu-close i {
  transition: 0.3s;
  transform: rotate(0);
}

.offcanvas-menu-close:hover i {
  transform: rotate(-90deg);
}

.offcanvas-wrapper {
  overflow: auto;
  height: 100%;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
}

.offcanvas-mobile-search-area {
  background-color: #e6e6e6;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  z-index: 9;
  margin-left: 60px;
}

.offcanvas-mobile-search-area input {
  width: 100%;
  font-size: 16px;
  display: block;
  padding: 9px 25px;
  padding-right: 45px;
  color: #222;
  background: #e6e6e6;
  border: none;
}

@media only screen and (max-width: 479px) {
  .offcanvas-mobile-search-area input {
    font-size: 14px;
    padding: 5px 15px;
    padding-right: 45px;
  }
}

.offcanvas-mobile-search-area button {
  background: none;
  border: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  padding: 0;
}

.offcanvas-mobile-search-area button i {
  font-size: 18px;
  line-height: 40px;
}

.offcanvas-inner-content {
  padding: 90px 35px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

@media only screen and (max-width: 479px) {
  .offcanvas-inner-content {
    padding: 70px 25px 0;
  }
}

.offcanvas-navigation {
  margin-bottom: 50px;
}

.offcanvas-navigation > ul > li {
  margin: 0 -35px;
  padding: 0 35px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #f5f5f5;
}

@media only screen and (max-width: 767px) {
  .offcanvas-navigation > ul > li {
    margin: 0 -25px;
    padding: 0 25px;
  }
}

.offcanvas-navigation > ul > li > a {
  font-size: 15px;
  color: #111;
  font-weight: 600;
  line-height: 1.5;
  padding: 10px 0;
}

.offcanvas-navigation > ul > li > a:hover {
  color: #fbd232;
}

@media only screen and (max-width: 479px) {
  .offcanvas-navigation > ul > li > a {
    font-size: 14px;
    line-height: 20px;
  }
}

.offcanvas-navigation ul.submenu2 {
  margin-left: 25px;
}

.offcanvas-navigation ul.submenu2 > li > a {
  font-size: 13px;
  color: #111;
  font-weight: 400;
  line-height: 1.5;
  padding: 10px 0;
}

.offcanvas-navigation ul.submenu2 > li > a:hover {
  color: #fbd232;
}

@media only screen and (max-width: 479px) {
  .offcanvas-navigation ul.submenu2 > li > a {
    font-size: 13px;
    line-height: 18px;
  }
}

.offcanvas-navigation ul li.menu-item-has-children {
  position: relative;
  display: block;
}

.offcanvas-navigation ul li.menu-item-has-children a {
  display: block;
}

.offcanvas-navigation ul li.menu-item-has-children.active > .menu-expand i:before {
  transform: rotate(0);
}

.offcanvas-navigation ul li.menu-item-has-children .menu-expand {
  position: absolute;
  right: auto;
  left: 90%;
  top: -5px;
  width: 30px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
}

.offcanvas-navigation ul li.menu-item-has-children .menu-expand i {
  display: block;
  margin-top: 25px;
  border-bottom: 1px solid;
  position: relative;
  width: 10px;
  transition: all 250ms ease-out;
}

.offcanvas-navigation ul li.menu-item-has-children .menu-expand i:before {
  width: 100%;
  content: "";
  border-bottom: 1px solid;
  display: block;
  position: absolute;
  top: 0;
  transform: rotate(90deg);
}

.off-canvas-widget-social a {
  margin: 0 10px;
  font-size: 14px;
}

.off-canvas-widget-social a:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 479px) {
  .off-canvas-widget-social a {
    margin: 0 10px;
  }
}

.off-canvas-widget-social a:hover {
  color: #fbd232;
}

/* offcanvas settings */
.offcanvas-settings .offcanvas-navigation > ul > li > a {
  font-size: 12px;
  font-weight: 400;
  padding: 10px 0;
}

.offcanvas-settings .offcanvas-navigation > ul > li.menu-item-has-children .menu-expand {
  height: 30px;
  margin-top: 0;
}

.offcanvas-settings .offcanvas-navigation ul.submenu2 > li > a {
  padding: 5px 0;
}

/*=====  End of Header  ======*/
/*----------------------------------------*/
/*  03. Hero CSS
/*----------------------------------------*/
/*-- Hero Slider --*/
.hero-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  border: none;
  background-color: white;
  color: #1d2634;
  padding: 10px;
  width: 60px;
  height: 60px;
  border-radius: 0;
  text-align: center;
  opacity: 0;
}

.hero-slider .slick-arrow i {
  font-size: 24px;
  line-height: 30px;
  display: block;
}

.hero-slider .slick-arrow.slick-prev {
  left: 40px;
  margin-left: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

.hero-slider .slick-arrow.slick-next {
  right: 40px;
  margin-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

.hero-slider .slick-arrow:hover {
  background-color: rgba(255, 255, 255, 0.75);
  color: #1d2634;
}

.hero-slider:hover .slick-arrow {
  opacity: 1;
}

.hero-slider:hover .slick-arrow.slick-prev {
  margin-left: 0;
}

.hero-slider:hover .slick-arrow.slick-next {
  margin-right: 0;
}

.hero-slider .slick-dots {
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider .slick-dots {
    bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider .slick-dots {
    bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider .slick-dots {
    bottom: 15px;
  }
}

.hero-slider .slick-dots li {
  margin: 0 5px;
}

.hero-slider .slick-dots li button {
  display: block;
  padding: 0;
  width: 30px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  text-indent: -9999px;
}

.hero-slider .slick-dots li.slick-active button {
  width: 30px;
  background-color: #ffffff;
}

/*-- Hero Item --*/
.hero-item {
  width: 100%;
  height: 650px;
  align-items: center;
  display: flex !important;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
}

.hero-item.hero-item-two {
  height: 510px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-item.hero-item-two {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-item.hero-item-two {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-item.hero-item-two {
    height: 350px;
  }
}

.hero-item.hero-item-three {
  height: 700px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-item.hero-item-three {
    height: 550px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-item.hero-item-three {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-item.hero-item-three {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-item.hero-item-three {
    height: 350px;
  }
}

.hero-item.hero-item-four {
  height: 500px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-item.hero-item-four {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-item.hero-item-four {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-item.hero-item-four {
    height: 350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-item {
    height: 550px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-item {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-item {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-item {
    height: 350px;
  }
}

/*-- Hero Content For Background Video --*/
.hero-content {
  text-align: center;
}

.hero-content h2 {
  font-size: 32px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .hero-content h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-content h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-content h2 {
    font-size: 18px;
  }
}

.hero-content h1 {
  font-size: 90px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .hero-content h1 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-content h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-content h1 {
    font-size: 44px;
  }
}

.hero-content h3 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .hero-content h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-content h3 {
    font-size: 16px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-content h3 {
    font-size: 14px;
  }
}

/*-- Hero Content Two For Hero Slider --*/
.slider-font-image {
  position: relative;
  bottom: -30px;
}

.hero-content-2.left {
  text-align: left;
}

.hero-content-2.center {
  text-align: center;
}

.hero-content-2.right {
  text-align: right;
}

.hero-content-2 > * {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeOutUp;
}

.hero-content-2 .slide-obj img {
  margin: 0 50px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .hero-content-2 .slide-obj img {
    margin: 0 20px;
    margin-bottom: 15px;
  }
}

.hero-content-2 img {
  margin: auto;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .hero-content-2 img {
    margin-bottom: 15px;
  }
}

.hero-content-2 .left-img {
  margin: 0;
  float: left;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .hero-content-2 .left-img {
    float: none;
    margin-right: 0px;
    margin-bottom: 10px;
    max-width: 120px;
  }
}

.hero-content-2 .mid-size {
  font-size: 58px;
  line-height: 70px;
  font-weight: 700;
  color: #1d2634;
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 .mid-size {
    font-size: 44px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2 .mid-size {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
}

.hero-content-2 .mid-size-white {
  font-size: 48px;
  line-height: 54px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 .mid-size-white {
    font-size: 40px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2 .mid-size-white {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
}

.hero-content-2 .sm-size {
  font-size: 48px;
  line-height: 54px;
  font-weight: 700;
  margin-bottom: 25px;
  color: #fbd232;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 .sm-size {
    font-size: 32px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2 .sm-size {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 5px;
  }
}

.hero-content-2 .sm-size i {
  color: #fbd232;
  margin: 0 15px;
}

@media only screen and (max-width: 767px) {
  .hero-content-2 .sm-size i {
    margin: 0 5px;
  }
}

.hero-content-2 .big-font {
  font-size: 80px;
  line-height: 55px;
  font-weight: 700;
  color: #fbd232;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-2 .big-font {
    font-size: 66px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 .big-font {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2 .big-font {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.hero-content-2 .white {
  color: #ffffff;
}

.hero-content-2 .size-white {
  font-size: 58px;
  line-height: 70px;
  font-weight: 700;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 .size-white {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2 .size-white {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
  }
}

.hero-content-2 .normal-size {
  font-size: 48px;
  line-height: 55px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #1d2634;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-2 .normal-size {
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2 .normal-size {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
}

.hero-content-2 .normal-size span {
  color: #fbd232;
}

.hero-content-2 .color-balck {
  color: #1d2634;
}

.hero-content-2 .color-white {
  color: #ffffff;
}

.hero-content-2 h2 {
  font-size: 30px;
  line-height: 1;
  font-weight: 300;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .hero-content-2 h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-content-2 h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-content-2 h2 {
    font-size: 20px;
  }
}

.hero-content-2 h1 {
  font-size: 60px;
  line-height: 1;
  font-weight: 700;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .hero-content-2 h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-content-2 h1 {
    font-size: 44px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-content-2 h1 {
    font-size: 36px;
  }
}

.hero-content-2 p {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .hero-content-2 p {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

/*-- Slide Content In Animation --*/
.slick-active {
  /*-- Hero Content Two For Hero Slider --*/
}

.slick-active .hero-content-2 > * {
  animation-name: fadeInUp;
}

.slick-active .hero-content-2 > *:nth-child(1) {
  animation-delay: 0.5s;
}

.slick-active .hero-content-2 > *:nth-child(2) {
  animation-delay: 1s;
}

.slick-active .hero-content-2 > *:nth-child(3) {
  animation-delay: 1.5s;
}

.slick-active .hero-content-2 > *:nth-child(4) {
  animation-delay: 2s;
}

.slick-active .hero-content-2 > *:nth-child(5) {
  animation-delay: 2.5s;
}

.slick-active .hero-content-2 > *:nth-child(6) {
  animation-delay: 3s;
}

/*----------------------------------------*/
/*  04. About CSS
/*----------------------------------------*/
.about-qute-area {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 100px;
  border: 2px solid #fbd232;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .about-qute-area {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-qute-area {
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-qute-area {
    margin-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .about-qute-area {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 45px;
  }
}

.about-qute {
  text-align: center;
}

.about-qute h2 {
  font-size: 24px;
  line-height: 1.2;
  color: #1d2634;
  font-weight: 700;
  margin-bottom: 30px;
}

.about-content h2 {
  font-size: 36px;
  line-height: 1.2;
  color: #1d2634;
  font-weight: 700;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .about-content h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }
}

.about-content p {
  line-height: 1.5;
}

.about-page-image img {
  width: 100%;
}

/* About Intro Area CSS */
.about-intro-image {
  margin: 5px auto;
  margin-bottom: 0;
  display: block;
  text-align: center;
}

.about-intro-area h2 {
  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .about-intro-area h2 {
    font-size: 22px;
  }
}

.about-intro-area h3 {
  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;
  color: #fbd232;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .about-intro-area h3 {
    font-size: 22px;
    margin-bottom: 15px;
  }
}

.about-intro-area p {
  line-height: 1.5;
  margin-bottom: 30px;
}

/*----------------------------------------*/
/*  05. Feature CSS
/*----------------------------------------*/
/*-- Features --*/
.features-section-dark {
  position: relative;
  z-index: 9;
  overflow: hidden;
}

.features-section-dark::before {
  content: '';
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(17, 17, 17, 0.85);
  z-index: -1;
}

.features-section-dark .triangle {
  position: relative;
  z-index: 9;
}

.features-section-dark .triangle:first-child::before, .features-section-dark .triangle:first-child::after {
  display: none !important;
}

.features-section-dark .triangle::before {
  content: '';
  position: absolute;
  display: block !important;
  top: -60px;
  left: -40px;
  z-index: -1;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 158px 90px 158px 0;
  border-color: transparent rgba(17, 17, 17, 0.6) transparent transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .features-section-dark .triangle::before {
    top: -60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .features-section-dark .triangle::before {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .features-section-dark .triangle::before {
    display: none !important;
  }
}

.features-section-dark .triangle::after {
  content: '';
  position: absolute;
  display: block;
  top: -60px;
  right: -650px;
  z-index: -2;
  height: 200%;
  width: 1000px;
  background-color: rgba(17, 17, 17, 0.6);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .features-section-dark .triangle::after {
    right: -730px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .features-section-dark .triangle::after {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .features-section-dark .triangle::after {
    display: none !important;
  }
}

.features-title h1 {
  font-size: 42px;
  line-height: 1.2;
  font-weight: 700;
  text-align: left;
  margin: 60px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .features-title h1 {
    margin: 50px 0;
  }
}

@media only screen and (max-width: 767px) {
  .features-title h1 {
    font-size: 24px;
    margin: 30px 0;
  }
}

/* Single Features CSS */
.single-features {
  display: flex;
  align-items: center;
}

.single-features.service-style {
  align-items: flex-start;
}

.single-features.service-style .content h3 {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 5px !important;
  border-bottom: 1px solid rgba(18, 33, 50, 0.1);
}

.single-features.service-style .content p {
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .single-features.service-style .content p {
    line-height: 1.5;
  }
}

.single-features .icon {
  background-color: #111111;
}

.single-features .icon::before, .single-features .icon::after {
  background-color: #111111;
}

.single-features .icon i {
  font-size: 40px;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .single-features .icon i {
    font-size: 30px;
  }
}

.single-features .content {
  flex-basis: 75%;
  max-width: 75%;
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  .single-features .content {
    padding: 0 8px;
  }
}

.single-features .content h4 {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 5px !important;
  border-bottom: 1px solid rgba(18, 33, 50, 0.1);
}

.single-features .content h3 {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 5px !important;
  border-bottom: 1px solid rgba(18, 33, 50, 0.1);
}

@media only screen and (max-width: 767px) {
  .single-features .content p {
    line-height: 1.5;
  }
}

/* Feature Style Two CSS */
.feature-wrap {
  position: relative;
}

.feature-wrap::before {
  content: '';
  width: 10px;
  height: 2px;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 35px;
  z-index: 9;
}

.feature-wrap .feature-style-2 {
  position: relative;
  overflow: hidden;
  border: 2px solid #fbd232;
  padding: 30px 120px 0px 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap .feature-style-2 {
    padding: 30px 110px 0px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap .feature-style-2 {
    padding: 30px 90px 0px 20px;
  }
}

.feature-wrap .feature-style-2::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 430px 170px 430px 0;
  border-color: transparent;
  border-right-color: #fbd232;
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translate(0, -50%);
}

@media only screen and (max-width: 767px) {
  .feature-wrap .feature-style-2::before {
    border-width: 370px 140px 370px 0;
  }
}

.feature-wrap .feature-style-2 .content h4 {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}

.feature-wrap .feature-style-2 .content p {
  margin-bottom: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap .feature-style-2 .content p {
    line-height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap .feature-style-2 .content p {
    line-height: 20px;
  }
}

.feature-wrap .feature-style-2 .features-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 1;
  transform: translate(0, -50%);
}

.feature-wrap .feature-style-2 .features-icon i {
  font-size: 45px;
  color: #1d2634;
}

/* Feature Style Three CSS */
.feature-style-3 {
  text-align: center;
  position: relative;
  z-index: 9;
}

.feature-style-3 .features-icon i {
  font-size: 80px;
  color: #fbd232;
}

.feature-style-3 .content h4 {
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  font-weight: 700;
  margin: 5px 0;
}

.feature-style-3 .content p {
  padding: 0 15px;
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-style-3 .content p {
    padding: 0;
    margin-bottom: 0;
    line-height: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-style-3 .content p {
    max-width: 300px;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .feature-style-3 .content p {
    max-width: 300px;
    margin: auto;
  }
}

/* feature Style Four */
.single-feature-four {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #e8e8e8;
  padding: 30px 25px 30px 45px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-feature-four {
    padding: 20px 15px 20px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .single-feature-four {
    padding: 20px 15px 20px 35px;
  }
}

@media only screen and (max-width: 479px) {
  .single-feature-four {
    padding: 20px;
  }
}

.single-feature-four::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: -75%;
  background-color: #ddd;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-feature-four::before {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  .single-feature-four::before {
    display: none;
  }
}

.single-feature-four::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 430px 0 430px 170px;
  border-color: transparent transparent transparent #ddd;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 479px) {
  .single-feature-four::after {
    display: none;
  }
}

.single-feature-four .feature-icon {
  position: relative;
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.single-feature-four .feature-icon i {
  font-size: 70px;
  color: #1d2634;
}

@media only screen and (max-width: 479px) {
  .single-feature-four .feature-icon i {
    font-size: 40px;
  }
}

.single-feature-four .feature-content {
  margin-left: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-feature-four .feature-content {
    margin-left: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .single-feature-four .feature-content {
    margin-left: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .single-feature-four .feature-content {
    margin-left: 15px;
  }
}

.single-feature-four .feature-content h4 {
  font-size: 18px;
  text-align: left;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-feature-four .feature-content h4 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 479px) {
  .single-feature-four .feature-content h4 {
    font-size: 14px;
  }
}

.single-feature-four .feature-content p {
  line-height: 22px;
}

.single-feature-four:hover {
  background-color: #111111;
}

.single-feature-four:hover::before {
  background-color: #fbd232;
}

.single-feature-four:hover::after {
  border-left-color: #fbd232;
}

@media only screen and (max-width: 479px) {
  .single-feature-four:hover .feature-icon i {
    color: #ffffff;
  }
}

.single-feature-four:hover .feature-content h4 {
  color: #ffffff;
}

.single-feature-four:hover .feature-content h4 a:hover {
  color: #ffffff;
}

.single-feature-four:hover .feature-content p {
  color: #ffffff;
}

/*----------------------------------------*/
/*  12. Work CSS
/*----------------------------------------*/
/*-- Work Slider --*/
.work-slider .slick-arrow, .work-slider-two .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  border: none;
  background-color: #fbd232;
  color: #1d2634;
  width: 30px;
  height: 44px;
  border-radius: 6px;
  text-align: center;
}

.work-slider .slick-arrow::before, .work-slider .slick-arrow::after, .work-slider-two .slick-arrow::before, .work-slider-two .slick-arrow::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fbd232;
  transform: rotate(60deg);
  z-index: -1;
  border-radius: 6px;
}

.work-slider .slick-arrow::after, .work-slider-two .slick-arrow::after {
  transform: rotate(-60deg);
}

.work-slider .slick-arrow i, .work-slider-two .slick-arrow i {
  font-size: 18px;
  margin-right: 0px;
  line-height: 25px;
  font-weight: 700;
  color: #1d2634;
}

.work-slider .slick-arrow.slick-prev, .work-slider-two .slick-arrow.slick-prev {
  left: 0;
}

.work-slider .slick-arrow.slick-next, .work-slider-two .slick-arrow.slick-next {
  right: 0;
}

.work-slider .slick-dots, .work-slider-two .slick-dots {
  width: 100%;
  padding: 0;
  margin: 20px 0 0;
  display: flex;
  justify-content: center;
}

.work-slider .slick-dots li, .work-slider-two .slick-dots li {
  margin: 0 5px;
}

.work-slider .slick-dots li button, .work-slider-two .slick-dots li button {
  display: block;
  padding: 0;
  width: 12px;
  height: 12px;
  border: none;
  background-color: #d8d8d8;
  text-indent: -9999px;
  border-radius: 50px;
}

.work-slider .slick-dots li.slick-active button, .work-slider-two .slick-dots li.slick-active button {
  background-color: #fbd232;
}

.work-slider-two .slick-arrow.slick-prev {
  left: 15px;
}

.work-slider-two .slick-arrow.slick-next {
  right: 15px;
}

/* Work Menu CSS */
.work-menu {
  margin-bottom: 30px;
}

.work-menu ul li a {
  font-size: 13px;
  font-weight: 700;
  display: block;
  text-transform: uppercase;
  color: #1d2634;
  line-height: 38px;
  font-family: "Montserrat", sans-serif;
  padding: 5px 20px 5px 35px;
  position: relative;
  background-color: #fbd232;
  transition: all 0.3s ease-in-out;
}

.work-menu ul li a::before, .work-menu ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.work-menu ul li a::before {
  top: 0;
  border-width: 24px 0 24px 16.5px;
  right: -17px;
  border-left-color: rgba(255, 255, 255, 0.15);
}

.work-menu ul li a::after {
  top: 0;
  right: -15px;
  border-width: 24px 0 24px 15px;
  border-left-color: #fbd232;
}

.work-menu ul li a:hover, .work-menu ul li a.active {
  background-color: #ffffff;
}

.work-menu ul li a:hover::before, .work-menu ul li a.active::before {
  border-left-color: #fbd232;
}

.work-menu ul li a:hover::after, .work-menu ul li a.active::after {
  border-left-color: #ffffff;
}

/*-- Work --*/
.project {
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.project .image {
  display: block;
  position: relative;
}

.project .image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.project .image img {
  width: 100%;
}

.project .img {
  display: block;
}

.project .img img {
  width: 100%;
}

.project .daHoverElem {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.project .content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 15px 50px 50px;
}

.project .content.content-center {
  top: 50%;
  bottom: auto;
  text-align: center;
  transform: translateY(-50%);
  padding: 0;
}

.project .content .title {
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  margin-bottom: 2px;
  opacity: 0;
  transform: translateY(-35px);
  animation-duration: 1.5s;
  transition: all 0.5s ease 0s;
}

.project .content .title > a {
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  color: #fbd232;
}

.project .content .category {
  display: block;
  opacity: 0;
  transform: translateY(-20px);
  animation-duration: 1s;
  transition: .3s ease-out 0s;
}

.project .content .category > a {
  color: #dddddd;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.project .content .category > a:hover {
  color: #fbd232;
}

.project .content .work-search-icon {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-45px);
  animation-duration: 2s;
  transition: all 0.3s ease 0s;
}

.project .content .work-search-icon .icon {
  width: 30px;
  height: 45px;
  margin: auto;
  margin-top: 10px;
}

.project .content .work-search-icon .icon a {
  display: block;
}

.project .content .work-search-icon .icon a i {
  font-size: 18px;
  margin-right: 0px;
  color: #1d2634;
}

.project:hover .image::before {
  opacity: 0.7;
}

.project:hover .daHoverElem {
  opacity: 0.7;
}

.project:hover .content {
  opacity: 1;
}

.project:hover .content .title {
  opacity: 1;
  transition: .5s ease-out .3s;
  transform: translateY(0);
}

.project:hover .content .category {
  opacity: 1;
  transform: translateY(0);
  transition: .5s ease-out .3s;
}

.project:hover .content .category a:hover {
  color: #fbd232;
}

.project:hover .content .work-search-icon {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/*-- Project Information --*/
.project-information {
  background-color: #1d2634;
  padding: 30px 35px;
}

.project-information h3 {
  color: #ffffff;
  margin-bottom: 20px;
}

.project-information ul li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 14px;
}

.project-information ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.project-information ul li strong {
  width: 80px;
  display: inline-flex;
}

/*----------------------------------------*/
/*  14. Choose CSS
/*----------------------------------------*/
/* Contact Hot line CSS */
.contact-hot-line-area {
  position: relative;
  padding-right: 15px;
}

.contact-hot-line-area.black .hot-line-image::before {
  background-color: #1d2634;
}

.contact-hot-line-area.black .hot-line-content h4 {
  color: #ffffff;
}

.contact-hot-line-area .hot-line-image {
  position: relative;
}

.contact-hot-line-area .hot-line-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: .9;
}

.contact-hot-line-area .hot-line-image img {
  width: 100%;
}

.contact-hot-line-area .hot-line-content {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  padding-left: 50px;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-hot-line-area .hot-line-content {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .contact-hot-line-area .hot-line-content {
    padding-left: 30px;
  }
}

.contact-hot-line-area .hot-line-content h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-hot-line-area .hot-line-content h4 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 479px) {
  .contact-hot-line-area .hot-line-content h4 {
    font-size: 18px;
  }
}

.contact-hot-line-area .hot-line-content h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  color: #fbd232;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-hot-line-area .hot-line-content h2 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 479px) {
  .contact-hot-line-area .hot-line-content h2 {
    font-size: 30px;
  }
}

/* Chosse Description CSS */
.choose-description {
  padding-bottom: 30px;
}

.choose-description.color-black span {
  font-size: 18px;
}

.choose-description.color-black h2 {
  font-size: 32px;
  color: #1d2634;
}

.choose-description.color-black p {
  color: #232323;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .choose-description {
    padding-bottom: 0;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .choose-description {
    padding-bottom: 25px;
    margin-top: 30px;
  }
}

.choose-description span {
  font-size: 22px;
  color: #fbd232;
  line-height: 1.2;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .choose-description span {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .choose-description span {
    font-size: 18px;
  }
}

.choose-description h2 {
  font-size: 36px;
  color: #ffffff;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .choose-description h2 {
    font-size: 32px !important;
  }
}

@media only screen and (max-width: 767px) {
  .choose-description h2 {
    font-size: 24px !important;
  }
}

.choose-description p {
  margin-top: 20px;
  line-height: 1.5;
  color: #ffffff;
}

/* Wrench List CSS */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wrench-list.color-black {
    margin-top: 30px;
  }
}

.wrench-list.color-black ul li a {
  color: #1d2634;
}

.wrench-list.color-black ul li a:hover {
  color: #fbd232;
}

.wrench-list ul li {
  display: block;
}

.wrench-list ul li i {
  font-size: 12px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 12px;
  color: #fbd232;
}

.wrench-list ul li a {
  color: #ffffff;
}

.wrench-list ul li a:hover {
  color: #fbd232;
}

/* Choose Us Section */
.choose-us-section-image {
  text-align: center;
}

/*----------------------------------------*/
/*  07. Cost Calculator CSS
/*----------------------------------------*/
.cost-cal-sec-image {
  margin-right: -50px;
  margin-left: -70px;
}

.cost-cal-sec-image img {
  max-width: none;
}

.cost-cal-sec-image-2 {
  margin-right: -220px;
  margin-left: -30px;
}

.cost-cal-sec-image-2 img {
  max-width: none;
}

.cost-calculator-inner {
  padding-top: 65px;
  padding-right: 35px;
  padding-left: 35px;
  background-color: #ffffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .cost-calculator-inner {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cost-calculator-inner {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cost-calculator-inner {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .cost-calculator-inner {
    padding-top: 45px;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.cost-cal-menu ul {
  text-align: center;
}

.cost-cal-menu ul li {
  display: inline-block;
  border-bottom: 1px solid #eee;
}

@media only screen and (max-width: 767px) {
  .cost-cal-menu ul li {
    border-bottom: 0;
  }
}

.cost-cal-menu ul li a {
  display: block;
  position: relative;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #1d2634;
  margin: 0 15px;
  padding: 0 0 5px;
}

.cost-cal-menu ul li a.active, .cost-cal-menu ul li a:hover {
  color: #fbd232;
}

@media only screen and (max-width: 767px) {
  .cost-cal-menu ul li a {
    font-size: 16px;
  }
}

.cost-cal-menu ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 2px;
  width: 100%;
  background-color: #fbd232;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .cost-cal-menu ul li a::before {
    display: none;
  }
}

.cost-cal-menu ul li a.active::before {
  opacity: 1;
  visibility: visible;
}

.cost-calculator-area {
  padding: 45px 0;
  padding-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .cost-calculator-area {
    padding-top: 20px;
  }
}

.ext-options {
  display: none;
}

.single-cost-cal {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .single-cost-cal {
    flex-wrap: wrap;
  }
}

.single-cost-cal label {
  flex-basis: 220px;
  width: 220px;
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  .single-cost-cal label {
    flex-basis: 100%;
    width: 100%;
    padding-right: 0;
  }
}

.single-cost-cal input {
  width: calc(100% - 220px);
  flex-basis: calc(100% - 220px);
  display: block;
  line-height: 2;
  min-height: 38px;
  padding: 0 12px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  transition: all 380ms ease;
  color: #232323;
  border-radius: 2px;
  outline: none;
}

.single-cost-cal input:focus {
  border-color: #999;
  box-shadow: 5px 0 0 #52b7bd inset, 0 2px 10px rgba(0, 0, 0, 0.2);
  outline: none;
}

@media only screen and (max-width: 767px) {
  .single-cost-cal input {
    flex-basis: 100%;
    width: 100%;
  }
}

.single-cost-cal .switch {
  /* Round Style CSS Toggle Switch */
}

.single-cost-cal .switch .cmn-toggle {
  position: absolute;
  visibility: hidden;
}

.single-cost-cal .switch .cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.single-cost-cal .switch > input.cmn-toggle-round-flat + label {
  padding: 2px;
  width: 76px;
  height: 38px;
  background-color: #dddddd;
  border-radius: 60px;
  transition: all 0.4s ease-in-out;
}

.single-cost-cal .switch > input.cmn-toggle-round-flat + label:before, .single-cost-cal .switch > input.cmn-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

.single-cost-cal .switch > input.cmn-toggle-round-flat + label:before {
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
  background-color: #f3f4fa;
  border-radius: 60px;
  transition: 0.4s;
}

.single-cost-cal .switch > input.cmn-toggle-round-flat + label:after {
  top: 6px;
  left: 6px;
  bottom: 6px;
  width: 26px;
  height: 26px;
  background-color: #dddddd;
  border-radius: 50%;
  transition: margin 0.4s, background 0.4s;
}

.single-cost-cal .switch > input.cmn-toggle-round-flat:checked + label {
  background-color: #dddddd;
}

.single-cost-cal .switch > input.cmn-toggle-round-flat:checked + label:after {
  margin-left: 38px;
  background-color: #52b7bd;
}

.single-cost-cal .ui-widget {
  width: calc(100% - 220px);
  flex-basis: calc(100% - 220px);
  height: 6px;
  background-color: #d8d8d8;
  border: none;
  border-radius: 3px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .single-cost-cal .ui-widget {
    flex-basis: 100%;
    width: 100%;
  }
}

.single-cost-cal .ui-widget .ui-slider-range {
  background-color: #fbd232;
  border-radius: 50px;
}

.single-cost-cal .ui-widget .ui-slider-handle {
  background-color: #fff;
  min-width: 24px;
  height: 24px;
  box-shadow: none !important;
  border: 1px solid #ddd;
  outline: none;
  top: -10px;
  margin-left: -18px;
  border-radius: 50%;
  cursor: grab;
  transition: none;
}

.single-cost-cal .ui-widget .ui-slider-handle::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 12px;
  width: 12px;
  background: #fbd232;
  border-radius: 100%;
  opacity: 1;
  transform: translate(-50%, -50%);
}

.single-cost-cal .ui-widget .ui-slider-handle span {
  position: absolute;
  top: -32px;
  left: 50%;
  min-width: 27px;
  height: 25px;
  border-radius: 5px;
  font-size: 13px;
  line-height: 25px;
  padding: 0 5px;
  transform: translateX(-50%);
  background: #1d2634;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  display: block;
}

.single-cost-cal .ui-widget .ui-slider-handle span::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: transparent transparent transparent transparent;
  border-top-color: #1d2634;
  transform: translateX(-50%);
}

.single-cost-cal .nice-select {
  width: calc(100% - 220px);
  flex-basis: calc(100% - 220px);
}

@media only screen and (max-width: 767px) {
  .single-cost-cal .nice-select {
    flex-basis: 100%;
    width: 100%;
  }
}

.total-cost-cal {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  padding: 15px 0;
}

@media only screen and (max-width: 767px) {
  .total-cost-cal {
    flex-wrap: wrap;
  }
}

.total-cost-cal .total-text i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  font-size: 32px;
  color: #fbd232;
}

.total-cost-cal .total-text span {
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  color: #1d2634;
  font-family: "Montserrat", sans-serif;
}

.total-cost-cal .point-submit {
  display: flex;
  align-items: center;
}

.total-cost-cal .point-submit .total-point span {
  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;
  color: #1d2634;
  font-family: "Montserrat", sans-serif;
}

/*----------------------------------------*/
/*  06. Service CSS
/*----------------------------------------*/
/* Clasic Service CSS */
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .clasic-service-section .col-xl-2 {
    display: none !important;
  }
  .clasic-service-section .col-xl-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.clasic-service-image img {
  width: 100%;
}

.clasic-service-content {
  padding-left: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .clasic-service-content {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clasic-service-content {
    padding: 50px 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clasic-service-content {
    padding: 50px 100px;
  }
}

@media only screen and (max-width: 767px) {
  .clasic-service-content {
    padding: 50px 30px;
  }
}

.clasic-service-content .title {
  font-size: 36px;
  color: #fbd232;
  line-height: 1.2;
  font-weight: 700;
  text-align: left;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .clasic-service-content .title {
    font-size: 32px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clasic-service-content .title {
    font-size: 32px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .clasic-service-content .title {
    font-size: 26px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .clasic-service-content .title {
    font-size: 22px;
    margin-bottom: 15px;
  }
}

.clasic-service-content p {
  color: #ffffff;
  margin-bottom: 0;
  line-height: 1.5;
  margin-bottom: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .clasic-service-content p {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .clasic-service-content p {
    margin-bottom: 20px;
  }
}

.clasic-service-content .white-hover:hover {
  background-color: #ffffff;
  color: #1d2634;
}

/* Clasic Service List CSS */
.clasic-service-list-content span {
  font-size: 18px;
  color: #545454;
  line-height: 1.2;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .clasic-service-list-content span {
    font-size: 16px;
  }
}

.clasic-service-list-content .title {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .clasic-service-list-content .title {
    margin-bottom: 20x;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clasic-service-list-content .title {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clasic-service-list-content .title {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .clasic-service-list-content .title {
    font-size: 26px;
    margin-bottom: 15px;
  }
}

.clasic-service-list-content .title a {
  display: block;
  color: #1d2634;
}

.clasic-service-list-content p {
  margin-bottom: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .clasic-service-list-content p {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clasic-service-list-content p {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clasic-service-list-content p {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .clasic-service-list-content p {
    margin-bottom: 15px;
  }
}

.clasic-service-list {
  margin-bottom: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .clasic-service-list {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clasic-service-list {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clasic-service-list {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .clasic-service-list {
    margin-bottom: 15px;
  }
}

.clasic-service-list li {
  display: block;
}

.clasic-service-list li i {
  margin-right: 10px;
  margin-bottom: 12px;
  display: inline-block;
}

.clasic-service-list li a {
  color: #232323;
}

/* Clasic Service Section Three */
.clasic-service-section-three {
  position: relative;
  z-index: 999;
}

.clasic-service-section-three::before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: -80%;
  background: url("../images/bg/service-bg-before.jpg");
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clasic-service-section-three::before {
    left: -78%;
  }
}

.clasic-service-content-three {
  position: relative;
  padding: 70px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clasic-service-content-three {
    background-color: #ffffff;
    padding: 70px 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clasic-service-content-three {
    background-color: #ffffff;
    padding: 60px 100px;
  }
}

@media only screen and (max-width: 767px) {
  .clasic-service-content-three {
    background-color: #ffffff;
    padding: 50px 15px;
  }
}

.clasic-service-content-three::before {
  content: '';
  position: absolute;
  height: 50%;
  width: 160%;
  background-color: #fff;
  left: -130px;
  z-index: -1;
  outline: 1px solid transparent;
  top: 0;
  transform: perspective(1px) skew(-30deg, 0deg);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clasic-service-content-three::before {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clasic-service-content-three::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .clasic-service-content-three::before {
    display: none;
  }
}

.clasic-service-content-three::after {
  content: '';
  position: absolute;
  height: 50%;
  width: 160%;
  background-color: #fff;
  left: -130px;
  z-index: -1;
  outline: 1px solid transparent;
  bottom: 0;
  transform: perspective(1px) skew(30deg, 0deg);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clasic-service-content-three::after {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clasic-service-content-three::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .clasic-service-content-three::after {
    display: none;
  }
}

.clasic-service-content-three h2 {
  font-size: 32px;
  line-height: 1.2;
  text-align: left;
  font-weight: 700;
  margin-bottom: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .clasic-service-content-three h2 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clasic-service-content-three h2 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clasic-service-content-three h2 {
    margin-bottom: 30px;
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .clasic-service-content-three h2 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.clasic-service-content-three p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .clasic-service-content-three p {
    line-height: 22px;
  }
}

.clasic-service-content-three ul {
  margin-bottom: 15px;
}

.clasic-service-content-three ul li {
  font-size: 15px;
  width: 49%;
  display: inline-block;
  vertical-align: top;
  padding: 0 15px;
  position: relative;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .clasic-service-content-three ul li {
    width: 100%;
  }
}

.clasic-service-content-three ul li::before {
  content: '';
  height: 100%;
  width: 3px;
  position: absolute;
  left: 0;
  background-color: #fbd232;
}

/* Clasic Service Section Four */
.clasic-service-section-four {
  position: relative;
  z-index: 1;
}

.clasic-service-for-image {
  position: relative;
  bottom: -31px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clasic-service-for-image {
    bottom: -25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clasic-service-for-image {
    bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .clasic-service-for-image {
    bottom: 0px;
  }
}

.clasic-service-content-four h2 {
  font-size: 36px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .clasic-service-content-four h2 {
    font-size: 22px;
    margin-bottom: 15px;
  }
}

.clasic-service-content-four p {
  margin-bottom: 35px;
}

@media only screen and (max-width: 767px) {
  .clasic-service-content-four p {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .clasic-service-content-four a {
    margin-bottom: 10px;
  }
}

/* Single Service CSS */
.single-service {
  position: relative;
}

.single-service.service-list::after {
  display: none;
}

.single-service.service-list::before {
  background-color: #111111;
  opacity: 0.5;
}

.single-service.service-list:hover .service-icon {
  opacity: 1;
  visibility: visible;
}

.single-service::before, .single-service::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition-duration: .5s;
}

.single-service::before {
  background-color: #111111;
  opacity: 0.8;
}

.single-service::after {
  background-color: #fbd232;
  opacity: 0;
  visibility: hidden;
  transform: scale3d(0.8, 0.8, 1);
}

.single-service .service-image {
  overflow: hidden;
}

.single-service .service-image img {
  width: 100%;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.single-service .service-icon {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  z-index: 2;
  backface-visibility: hidden;
  margin: 0;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.single-service .service-icon .icon {
  background-color: #fbd232;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
}

.single-service .service-icon .icon::before, .single-service .service-icon .icon::after {
  background-color: #fbd232;
}

.single-service .service-icon .icon i {
  font-size: 40px;
  color: #1d2634;
}

.single-service .service-icon h3 {
  font-size: 20px;
  color: #ffffff;
  line-height: 1.2;
  text-align: center;
  font-weight: 700;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service .service-icon h3 {
    font-size: 16px;
  }
}

.single-service .service-content {
  text-align: center;
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  backface-visibility: hidden;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-service .service-content {
    width: 60%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service .service-content {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service .service-content {
    width: 70%;
  }
}

.single-service .service-content h3 {
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  font-weight: 700;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-service .service-content h3 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service .service-content h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service .service-content h3 {
    font-size: 18px;
  }
}

.single-service .service-content h3 a {
  color: #1d2634;
}

.single-service .service-content p {
  color: #1d2634;
  line-height: 1.5;
  margin-bottom: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-service .service-content p {
    line-height: 1.2;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service .service-content p {
    line-height: 1;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service .service-content p {
    line-height: 1.2;
  }
}

.single-service .service-content .service-btn {
  background-color: #ffffff;
  color: #1d2634;
  padding: 16px 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-service .service-content .service-btn {
    padding: 12px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-service .service-content .service-btn {
    padding: 12px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-service .service-content .service-btn {
    padding: 12px 15px;
  }
}

.single-service:hover::after {
  opacity: .9;
  visibility: visible;
  transform: scale3d(1, 1, 1);
}

@media only screen and (max-width: 767px) {
  .single-service:hover::after {
    opacity: 0;
    visibility: hidden;
  }
}

.single-service:hover .service-image img {
  transform: scale(1.1);
}

.single-service:hover .service-icon {
  opacity: 0;
  visibility: hidden;
}

@media only screen and (max-width: 767px) {
  .single-service:hover .service-icon {
    opacity: 1;
    visibility: visible;
  }
}

.single-service:hover .service-content {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .single-service:hover .service-content {
    opacity: 0;
    visibility: hidden;
  }
}

/* Single Service Two CSS */
.single-service-two {
  position: relative;
  overflow: hidden;
}

.single-service-two .service-image {
  position: relative;
  overflow: hidden;
}

.single-service-two .service-image img {
  width: 100%;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.single-service-two .service-icon-wrap {
  position: relative;
  margin-top: -14px;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .single-service-two .service-icon-wrap {
    margin-top: -5px;
  }
}

.single-service-two .service-icon-wrap .service-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-service-two .service-icon-wrap .service-icon .icon {
  background-color: #111111;
  left: 70px;
  margin-top: -25px;
}

@media only screen and (max-width: 767px) {
  .single-service-two .service-icon-wrap .service-icon .icon {
    left: 50px;
    margin-top: -24px;
  }
}

.single-service-two .service-icon-wrap .service-icon .icon::before, .single-service-two .service-icon-wrap .service-icon .icon::after {
  background-color: #111111;
}

.single-service-two .service-icon-wrap .service-icon .icon i {
  color: #ffffff;
}

.single-service-two .service-icon-wrap h3 {
  display: block;
  background-color: #fbd232;
  width: 75%;
  text-align: right;
  margin-bottom: 0;
}

.single-service-two .service-icon-wrap h3 a {
  display: block;
  color: #1d2634;
  padding: 16px 20px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .single-service-two .service-icon-wrap h3 a {
    padding: 7px 20px;
    font-size: 14px;
  }
}

.single-service-two:hover .service-image img {
  transform: scale(1.1);
}

/* Service List CSS */
.service-list-area {
  padding: 30px 15px;
  border: 2px solid #eee;
  border-bottom: 1px solid #eee;
}

.service-list-area:last-child {
  border-bottom: 2px solid #eee;
}

@media only screen and (max-width: 767px) {
  .service-list-area {
    padding: 30px 0px;
  }
}

.service-list-content {
  margin-right: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-list-content {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-list-content {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .service-list-content {
    margin-right: 0;
  }
}

.service-list-content .title {
  font-size: 28px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #eee;
}

@media only screen and (max-width: 767px) {
  .service-list-content .title {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}

.service-list-content p {
  line-height: 1.5;
}

/* Single Service Two CSS */
.single-service-three {
  position: relative;
}

.single-service-three .service-icon {
  position: relative;
  margin-bottom: -50px;
  margin-left: -20px;
}

.single-service-three .service-icon .icon i {
  font-size: 32px;
}

.single-service-three .service-image {
  position: relative;
  overflow: hidden;
}

.single-service-three .service-image img {
  width: 100%;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.single-service-three h3 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  font-size: 16px;
  font-weight: 700;
  padding: 16px 20px;
  background: #fbd232;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .single-service-three h3 {
    font-size: 14px;
  }
}

.single-service-three h3 a {
  color: #1d2634;
}

.single-service-three:hover .service-image img {
  transform: scale(1.1);
}

/* Service Intro Css */
.gradient-bg {
  position: relative;
}

.gradient-bg::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  padding: 0 15px;
  background: #fff;
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f8f8f8));
  background: -webkit-linear-gradient(#fff, #f8f8f8);
  background: -moz-linear-gradient(center top, #fff 0%, #f8f8f8 100%);
  background: -moz-gradient(center top, #fff 0%, #f8f8f8 100%);
}

.service-intro-image {
  text-align: center;
  margin-bottom: 20px;
}

.service-intro-content h2 {
  font-size: 36px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-intro-content h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .service-intro-content h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .service-intro-content h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 479px) {
  .service-intro-content h2 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-intro-content p {
    line-height: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .service-intro-content p {
    line-height: 22px;
  }
}

/* Service Table CSS */
.service-table.theme-color table {
  border: 1px solid #eee;
}

.service-table.theme-color table th {
  background-color: #fbd232;
  color: #ffffff;
}

.service-table table {
  margin-bottom: 0;
}

.service-table table th {
  font-size: 17px;
  font-weight: 600;
  padding: 11px 40px;
  color: #1d2634;
  background-color: #eee;
  border: 0;
  border-bottom: 0 !important;
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .service-table table th {
    padding: 11px 20px;
    width: 200px;
  }
}

.service-table table td {
  font-size: 15px;
  padding: 11px 40px;
  border-bottom: 1px solid #eeeeee;
}

@media only screen and (max-width: 767px) {
  .service-table table td {
    padding: 11px 20px;
  }
}

.service-table table td.price {
  font-weight: 600;
}

/* Service Details CSS */
.service-details-image img {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details-image img {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .service-details-image img {
    margin-bottom: 30px;
  }
}

.service-details-content h2 {
  font-size: 36px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .service-details-content h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
}

.service-details-content p {
  margin-bottom: 25px;
  line-height: 24px;
}

@media only screen and (max-width: 767px) {
  .service-details-content p {
    margin-bottom: 15px;
  }
}

.service-details-content h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .service-details-content h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

/* Service Pricing Table CSS */
.pricing-table-area {
  border-top: 1px solid #ebebeb;
  padding-top: 50px;
}

.single-pricing {
  text-align: center;
}

.single-pricing .price-head h4 {
  font-size: 18px;
  color: #ffffff;
  background-color: #fbd232;
  font-weight: 700;
  margin-bottom: 0;
}

.single-pricing .price-head h3 {
  font-size: 48px;
  font-weight: 600;
  background-color: #f7f7f7;
  border: 2px solid #eee;
  padding: 30px 0;
  margin-bottom: 0;
}

.single-pricing .price-head h3 span {
  font-size: 28px;
  line-height: 70px;
  margin-left: -8px;
  vertical-align: top;
}

.single-pricing .price-body ul {
  border: 2px solid #eee;
  padding: 0 20px;
  border-top: 0;
  border-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-pricing .price-body ul {
    padding: 0 8px;
  }
}

.single-pricing .price-body ul li {
  border-bottom: 1px solid #eee;
  padding: 25px 0;
  margin: 0;
}

.single-pricing .price-footer a {
  font-size: 18px;
  font-weight: 600;
  display: block;
  border: 2px solid #eee;
  border-top: none;
  padding: 20px 0;
  color: #1d2634;
  background-color: #fbd232;
  font-family: "Montserrat", sans-serif;
}

.single-pricing .price-footer a i {
  margin-left: 5px;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.single-pricing .price-footer a:hover {
  background-color: #111;
  border-color: #111;
  color: #ffffff;
}

.single-pricing .price-footer a:hover i {
  color: #fff;
  transform: translate(-120px, 0);
}

/*----------------------------------------*/
/*  13. Offer CSS
/*----------------------------------------*/
.about-offer {
  padding-top: 100px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  background-image: url("../images/bg/offer-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-offer {
    padding-top: 55px;
    padding-bottom: 60px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .about-offer {
    padding-top: 45px;
    padding-bottom: 50px;
    padding-left: 0;
    padding-right: 0;
  }
}

.about-offer h2 {
  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-offer h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .about-offer h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

.about-offer p {
  line-height: 1.5;
  margin-bottom: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-offer p {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .about-offer p {
    margin-bottom: 15px;
  }
}

.offer-wrap {
  background-color: #fbd232;
  padding-bottom: 30px;
  text-align: center;
}

.offer-wrap .offer-image {
  margin-bottom: 10px;
}

.offer-wrap .offer-image img {
  position: relative;
  z-index: 9;
  margin-top: -40px;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offer-wrap .offer-image img {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .offer-wrap .offer-image img {
    margin-top: 0;
  }
}

.offer-wrap .offer-content h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 40px;
  border-bottom: 1px solid #e6c109;
  padding-top: 10px;
  padding-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offer-wrap .offer-content h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .offer-wrap .offer-content h3 {
    font-size: 18px;
  }
}

.offer-wrap .offer-content a {
  background-color: #111111;
  color: #fbd232;
}

/* Single Offer CSS */
.single-offer {
  display: flex;
  align-items: center;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-offer {
    justify-content: center;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-offer {
    margin-bottom: 30px;
  }
}

.single-offer::before {
  content: '';
  position: absolute;
  height: 300px;
  width: 1000px;
  top: -100%;
  left: -500px;
  background-color: rgba(255, 255, 255, 0.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-offer::before {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-offer::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .single-offer::before {
    display: none;
  }
}

.single-offer::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 80px 0 80px 40px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.1);
  top: 50%;
  right: 30px;
  transform: translate(0, -50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-offer::after {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-offer::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .single-offer::after {
    display: none;
  }
}

.single-offer .offer-icon {
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  .single-offer .offer-icon {
    padding: 0;
    padding-right: 15px;
  }
}

.single-offer .offer-icon i {
  font-size: 47px;
  color: #fbd232;
}

.single-offer .offer-content {
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  .single-offer .offer-content {
    padding: 0;
  }
}

.single-offer .offer-content .title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .single-offer .offer-content .title {
    font-size: 16px;
  }
}

/*----------------------------------------*/
/*  09. Testimonial CSS
/*----------------------------------------*/
/* Testimonial Dots */
.testimonial-slider-content-two .slick-dots {
  position: absolute;
  bottom: 90px;
}

@media only screen and (max-width: 767px) {
  .testimonial-slider-content-two .slick-dots {
    bottom: 0;
    justify-content: center;
  }
}

/*-- Testimonial --*/
.testimonial.testimonial-style-2 .testimonial-inner .testimonial-description blockquote {
  padding: 30px 40px 36px 100px;
  border: 2px solid #fbd232;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .testimonial.testimonial-style-2 .testimonial-inner .testimonial-description blockquote {
    padding: 30px 20px;
  }
}

.testimonial.testimonial-style-2 .testimonial-inner .testimonial-description blockquote::after {
  content: '';
  height: 2px;
  width: 20px;
  position: absolute;
  left: 30px;
  bottom: -2px;
  background-color: #ffffff;
}

.testimonial.testimonial-style-2 .testimonial-inner .testimonial-author {
  padding-left: 0;
}

.testimonial.color-transparent .testimonial-inner {
  background-color: transparent;
}

.testimonial.color-transparent .testimonial-inner .testimonial-description blockquote {
  color: #ccc;
}

.testimonial.color-transparent .testimonial-author .author-info h4 {
  color: #ffffff;
}

.testimonial.color-transparent .testimonial-author .author-info h4 span {
  color: #ccc;
}

.testimonial.color-transparent .testimonial-author .author-info h4 span a {
  color: #ccc;
}

.testimonial.color-transparent .testimonial-author .author-info h4 span a:hover {
  color: #fbd232;
}

.testimonial .testimonial-inner {
  border-radius: 10px;
  background-color: #fff;
}

.testimonial .testimonial-inner .testimonial-description blockquote {
  padding: 30px 40px 20px 100px;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-style: italic;
  color: rgba(0, 0, 0, 0.7);
}

@media only screen and (max-width: 767px) {
  .testimonial .testimonial-inner .testimonial-description blockquote {
    padding: 30px 20px;
    margin-bottom: 0;
  }
}

.testimonial .testimonial-inner .testimonial-description blockquote::before {
  content: url("../images/icons/qute.png");
  position: absolute;
  top: 30px;
  left: 30px;
  float: left;
  padding-top: 10px;
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  .testimonial .testimonial-inner .testimonial-description blockquote::before {
    position: static;
  }
}

.testimonial .testimonial-inner .testimonial-author {
  display: flex;
  align-items: center;
  padding-left: 100px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .testimonial .testimonial-inner .testimonial-author {
    padding-left: 20px;
    padding-bottom: 30px;
  }
}

.testimonial .testimonial-inner .testimonial-author .author-thumb {
  width: 90px;
  padding-right: 20px;
}

.testimonial .testimonial-inner .testimonial-author .author-thumb img {
  width: 100%;
}

.testimonial .testimonial-inner .testimonial-author .author-info h4 {
  font-size: 18px;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 0;
}

.testimonial .testimonial-inner .testimonial-author .author-info span {
  display: block;
  color: #999999;
}

.testimonial .testimonial-inner .testimonial-author .author-info span a {
  color: #111;
  font-style: italic;
}

.testimonial .testimonial-inner .testimonial-author .author-info span a:hover {
  color: #fbd232;
}

.gutter-30 {
  margin-left: -15px;
  margin-right: -15px;
}

@media only screen and (max-width: 767px) {
  .gutter-30 {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gutter-30 {
    margin-left: 0;
    margin-right: 0;
  }
}

.gutter-30 .gutter-item {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: 767px) {
  .gutter-30 .gutter-item {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gutter-30 .gutter-item {
    padding-left: 0;
    padding-right: 0;
  }
}

/*----------------------------------------*/
/*  19. Product CSS
/*----------------------------------------*/
/* Product Slider */
.product-slider-4 .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  border: none;
  background-color: #fbd232;
  color: #1d2634;
  width: 30px;
  height: 44px;
  border-radius: 6px;
  text-align: center;
}

.product-slider-4 .slick-arrow::before, .product-slider-4 .slick-arrow::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fbd232;
  transform: rotate(60deg);
  z-index: -1;
  border-radius: 6px;
}

.product-slider-4 .slick-arrow::after {
  transform: rotate(-60deg);
}

.product-slider-4 .slick-arrow i {
  font-size: 18px;
  margin-right: 0px;
  line-height: 25px;
  font-weight: 700;
  color: #1d2634;
}

.product-slider-4 .slick-arrow.slick-prev {
  left: 25px;
}

.product-slider-4 .slick-arrow.slick-next {
  right: 25px;
}

.product-slider-4 .slick-dots {
  width: 100%;
  padding: 0;
  margin: 20px 0 0;
  display: flex;
  justify-content: center;
}

.product-slider-4 .slick-dots li {
  margin: 0 5px;
}

.product-slider-4 .slick-dots li button {
  display: block;
  padding: 0;
  width: 12px;
  height: 12px;
  border: none;
  background-color: #d8d8d8;
  text-indent: -9999px;
  border-radius: 50px;
}

.product-slider-4 .slick-dots li.slick-active button {
  background-color: #fbd232;
}

/*-- Product --*/
.product .product-inner .media {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  margin-bottom: 15px;
  border: 1px solid #f1f2f3;
}

.product .product-inner .media .image {
  width: 100%;
  position: relative;
  z-index: 1;
}

.product .product-inner .media .image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 2;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.product .product-inner .media .image img {
  width: 100%;
}

.product .product-inner .media .btn {
  z-index: 9;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-top: 20px;
  white-space: nowrap;
  opacity: 0;
}

.product .product-inner .media .btn i {
  font-size: 20px;
  margin-right: 10px;
}

.product .product-inner .media .btn:hover {
  background-color: #1d2634;
  color: #fbd232;
}

.product .product-inner .media .view-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-top: 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  z-index: 99;
}

.product .product-inner .media .work-search-icon .icon {
  width: 30px;
  height: 45px;
  margin: auto;
  margin-top: 10px;
}

.product .product-inner .media .work-search-icon .icon a {
  display: block;
}

.product .product-inner .media .work-search-icon .icon a i {
  font-size: 18px;
  margin-right: 0px;
  color: #1d2634;
}

.product .product-inner .media .onsale {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  position: absolute;
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 100%;
  text-align: center;
  top: 20px;
  left: 25px;
  background-color: #d90000;
  padding: 0;
  z-index: 9;
}

.product .product-inner .content {
  text-align: center;
}

.product .product-inner .content .title {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.4;
}

.product .product-inner .content .price {
  font-size: 14px;
  line-height: 1;
  margin: 0;
}

.product .product-inner .content .price span.new {
  font-weight: 700;
  color: #d80000;
  margin-left: 10px;
}

.product .product-inner .content .price span.old {
  color: #232323;
  text-decoration: line-through;
}

.product .product-inner .content .ratting {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5px;
}

.product .product-inner .content .ratting i {
  font-size: 12px;
  line-height: 18px;
  margin-right: 5px;
  color: #fbd232;
}

.product .product-inner .content .ratting i:last-child {
  margin-right: 0;
}

.product .product-inner:hover .media .image::before {
  opacity: 0.8;
}

.product .product-inner:hover .media .btn {
  opacity: 1;
  margin-top: 0;
}

.product .product-inner:hover .media .view-btn {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

/*-- Product Details --*/
.product-details .product-inner .product-image-slider {
  margin-bottom: 10px;
  border: 1px solid #f1f2f3;
  padding-right: 1px;
}

.product-details .product-inner .product-image-slider .item a {
  display: block;
}

.product-details .product-inner .product-image-slider .item a.gallery-popup {
  position: relative;
}

.product-details .product-inner .product-image-slider .item a.gallery-popup::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #1d2634;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.product-details .product-inner .product-image-slider .item a.gallery-popup i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0);
  opacity: 0;
  font-size: 40px;
  color: #ffffff;
  transition: all 0.3s ease 0s;
}

.product-details .product-inner .product-image-slider .item a.gallery-popup:hover::before {
  opacity: 0.75;
}

.product-details .product-inner .product-image-slider .item a.gallery-popup:hover i {
  transform: translateX(-50%) translateY(-50%) scale(1);
  opacity: 1;
}

.product-details .product-inner .product-image-slider .item img {
  width: 100%;
}

.product-details .product-inner .product-thumb-slider {
  margin: -5px;
}

.product-details .product-inner .product-thumb-slider .item {
  padding: 5px;
}

.product-details .product-inner .product-thumb-slider .item img {
  width: 100%;
  border: 1px solid #f1f2f3;
}

.product-details .product-inner .content .title {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details .product-inner .content .title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details .product-inner .content .title {
    font-size: 24px;
  }
}

.product-details .product-inner .content .price {
  font-size: 16px;
  line-height: 1;
  margin-bottom: 15px;
}

.product-details .product-inner .content .price span.new {
  font-weight: 700;
  color: #d80000;
  margin-left: 10px;
  font-size: 18px;
}

.product-details .product-inner .content .price span.old {
  color: #232323;
  text-decoration: line-through;
}

.product-details .product-inner .content .actions {
  display: flex;
  flex-wrap: wrap;
}

.product-details .product-inner .content .actions .pro-qty {
  text-align: center;
  border: 1px solid #eeeeee;
  margin-right: 10px;
}

.product-details .product-inner .content .actions .pro-qty .qtybtn {
  height: 42px;
  padding: 0 10px;
  border: none;
  background-color: transparent;
}

.product-details .product-inner .content .actions .pro-qty input {
  height: 42px;
  width: 50px;
  text-align: center;
  border-width: 0 1px;
  border-style: solid;
  border-color: #eeeeee;
  color: #232323;
}

.product-details .product-inner .content .actions .btn {
  line-height: 1.5;
}

.product-details .product-inner .content .product-meta {
  border-top: 1px solid #eee;
  margin-top: 30px;
}

.product-details .product-inner .content .product-meta span {
  font-weight: 600;
  display: block;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
  color: #777;
  padding: 10px 0;
  margin-bottom: 0;
}

.product-details .product-inner .content .product-meta span a {
  font-weight: 400;
  color: #1d2634;
  font-size: 14px;
}

.product-details .product-inner .content .product-meta span a:hover {
  color: #fbd232;
}

.product-details .product-inner .content .product-share-buttons {
  border-bottom: 1px solid #eee;
}

.product-details .product-inner .content .product-share-buttons span {
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  color: #777;
  padding: 10px 0;
  margin-right: 5px;
}

.product-details .product-inner .content .product-share-buttons a {
  display: inline-block;
  margin-right: 10px;
  color: #1d2634;
}

.product-details .product-inner .content .product-share-buttons a:hover {
  color: #fbd232;
}

/* Shop Filter CSS */
.shop-filter-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .shop-filter-right {
    justify-content: flex-start;
  }
}

.shop-filter-right h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
  text-transform: uppercase;
  margin-right: 10px;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .result-count p {
    margin-bottom: 15px;
  }
}

/*-- Product Details Tab List --*/
.product-details-tab-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0px 30px;
  border-bottom: 1px solid #d3ced2;
}

.product-details-tab-list li {
  padding: 0 15px;
}

.product-details-tab-list li a {
  line-height: 1;
  display: block;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: #1d2634;
  font-weight: 700;
  line-height: 1.2;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}

.product-details-tab-list li a.active {
  border-color: #fbd232;
  color: #fbd232;
}

/*-- Product Details Tab Content --*/
.product-description ul {
  list-style: disc;
  padding: 15px;
  padding-bottom: 0;
}

.product-description ul li {
  font-size: 14px;
  line-height: 1.5;
}

.product-specification {
  padding-top: 5px;
}

.review-form h4 {
  margin-bottom: 30px;
}

.review-form input {
  width: 100%;
  height: 50px;
  border: 2px solid #ddd;
  padding: 5px 20px;
  color: #232323;
}

.review-form input:focus {
  border-color: #fbd232;
}

.review-form textarea {
  width: 100%;
  height: 120px;
  border: 2px solid #ddd;
  padding: 10px 20px;
  color: #232323;
  resize: none;
}

.review-form textarea:focus {
  border-color: #fbd232;
}

/*---------------------------------------
    20. Cart CSS
-----------------------------------------*/
/*-- Cart Table --*/
.cart-table .table {
  border-bottom: 2px solid #1d2634;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .cart-table .table {
    border-top: 2px solid #1d2634;
  }
}

.cart-table .table thead {
  background-color: #fbd232;
}

@media only screen and (max-width: 767px) {
  .cart-table .table thead {
    display: none;
  }
}

.cart-table .table thead tr th {
  text-align: center;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding: 12px 20px;
}

.cart-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 20px;
  vertical-align: middle;
  border-bottom: 1px solid #dddddd;
}

@media only screen and (max-width: 767px) {
  .cart-table .table tbody tr td {
    display: block;
    width: 100%;
    max-width: none;
    padding: 15px;
    text-align: left;
  }
}

.cart-table th.pro-thumbnail, .cart-table td.pro-thumbnail {
  max-width: 120px;
  min-width: 100px;
  width: 120px;
}

@media only screen and (max-width: 767px) {
  .cart-table th.pro-thumbnail a, .cart-table td.pro-thumbnail a {
    width: 120px;
  }
}

.cart-table th.pro-title, .cart-table td.pro-title {
  min-width: 200px;
}

.cart-table td.pro-thumbnail a {
  display: block;
  border: 1px solid #eeeeee;
}

.cart-table td.pro-thumbnail a img {
  width: 100%;
  background-color: #f6f7f8;
}

.cart-table td.pro-title a {
  font-size: 16px;
  font-weight: 600;
  color: #232323;
}

.cart-table td.pro-title a:hover {
  color: #fbd232;
}

.cart-table td.pro-price span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #232323;
}

.cart-table td.pro-quantity .pro-qty {
  display: inline-flex;
  text-align: center;
  border: 1px solid #eeeeee;
}

.cart-table td.pro-quantity .pro-qty .qtybtn {
  height: 42px;
  padding: 0 10px;
  border: none;
  background-color: transparent;
}

.cart-table td.pro-quantity .pro-qty input {
  height: 42px;
  width: 50px;
  text-align: center;
  border-width: 0 1px;
  border-style: solid;
  border-color: #eeeeee;
  color: #232323;
}

.cart-table td.pro-subtotal span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #232323;
}

.cart-table td.pro-addtocart button {
  width: 140px;
  border-radius: 50px;
  height: 36px;
  border: 1px solid #fbd232;
  line-height: 24px;
  padding: 5px 20px;
  font-weight: 700;
  text-transform: capitalize;
  color: #1d2634;
  background-color: #fbd232;
}

.cart-table td.pro-addtocart button:hover {
  background-color: #1d2634;
  border-color: #1d2634;
  color: #fbd232;
}

.cart-table td.pro-remove a {
  display: block;
  font-weight: 600;
  color: #232323;
}

.cart-table td.pro-remove a i {
  font-size: 15px;
}

.cart-table td.pro-remove a:hover {
  color: #ff0000;
}

@media only screen and (max-width: 767px) {
  .cart-table td.pro-remove a {
    width: 60px;
    text-align: center;
  }
}

/*-- Calculate Shipping --*/
.calculate-shipping {
  margin-bottom: 23px;
}

.calculate-shipping h4 {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
}

.calculate-shipping form .nice-select {
  width: 100%;
  border-radius: 0;
  height: 40px;
  line-height: 1;
  border: 1px solid #999999;
  padding: 9px 20px;
  color: #232323;
  background-color: transparent;
}

@media only screen and (max-width: 767px) {
  .calculate-shipping form .nice-select {
    height: 45px;
    line-height: inherit;
  }
}

.calculate-shipping form input {
  width: 100%;
  height: 40px;
  border: 1px solid #999999;
  line-height: 24px;
  padding: 9px 20px;
  color: #232323;
  color: #232323;
  background-color: transparent;
}

@media only screen and (max-width: 767px) {
  .calculate-shipping form input {
    height: 45px;
  }
}

/*-- Discount Coupon --*/
.discount-coupon h4 {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
}

.discount-coupon form input {
  width: 100%;
  height: 40px;
  border: 1px solid #999999;
  line-height: 24px;
  padding: 9px 20px;
  color: #232323;
  background-color: transparent;
}

@media only screen and (max-width: 767px) {
  .discount-coupon form input {
    height: 45px;
  }
}

/*-- Cart Summary --*/
.cart-summary {
  float: right;
  max-width: 410px;
  width: 100%;
  margin-left: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-summary {
    margin-left: 0;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .cart-summary {
    margin-left: 0;
    max-width: 100%;
  }
}

.cart-summary .cart-summary-wrap {
  background-color: #1d2634;
  padding: 45px 50px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .cart-summary .cart-summary-wrap {
    padding: 25px 30px;
  }
}

.cart-summary .cart-summary-wrap h4 {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
  color: #fbd232;
}

.cart-summary .cart-summary-wrap p {
  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  color: #ffffff;
}

.cart-summary .cart-summary-wrap p span {
  float: right;
}

.cart-summary .cart-summary-wrap h2 {
  border-top: 1px solid #ffffff;
  padding-top: 9px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
  color: #fbd232;
  margin: 0;
}

.cart-summary .cart-summary-wrap h2 span {
  float: right;
}

.cart-summary .cart-summary-button {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-summary .cart-summary-button {
    display: flex;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .cart-summary .cart-summary-button {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.cart-summary .cart-summary-button button {
  margin: 0 5px;
}

.cart-summary .cart-summary-button button:first-child {
  margin-left: 0;
}

.cart-summary .cart-summary-button button:last-child {
  margin-right: 0;
}

/*---------------------------------------
    21. Checkout CSS
-----------------------------------------*/
/*-- Checkout Title --*/
.checkout-title {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
}

/*-- Checkout Form --*/
.checkout-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.checkout-form .nice-select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  height: 45px;
  color: #232323;
  margin-bottom: 15px;
}

.checkout-form input {
  width: 100%;
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: #232323;
  margin-bottom: 15px;
}

.checkout-form input[type="checkbox"] {
  width: auto;
}

.checkout-form .check-box {
  float: left;
  margin-right: 70px;
}

@media only screen and (max-width: 767px) {
  .checkout-form .check-box {
    margin-bottom: 10px;
  }
}

.checkout-form .check-box:last-child {
  margin-right: 0;
}

.checkout-form .check-box input[type="checkbox"] {
  display: none;
}

.checkout-form .check-box input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #1d2634;
  margin: 0;
}

.checkout-form .check-box input[type="checkbox"] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #999999;
  content: "";
  transition: all 0.3s ease 0s;
}

.checkout-form .check-box input[type="checkbox"] + label::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "\f00c";
  font-family: Fontawesome;
  font-size: 12px;
  line-height: 20px;
  opacity: 0;
  color: #1d2634;
  width: 20px;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.checkout-form .check-box input[type="checkbox"]:checked + label::before {
  border: 2px solid #1d2634;
}

.checkout-form .check-box input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

/*-- Shipping Form --*/
#shipping-form {
  display: none;
}

/*-- Checkout Cart Total --*/
.checkout-cart-total {
  background-color: #1d2634;
  padding: 45px;
}

@media only screen and (max-width: 575px) {
  .checkout-cart-total {
    padding: 30px;
  }
}

.checkout-cart-total h4 {
  flex-basis: 18px;
  line-height: 23px;
  font-weight: 700;
  color: #fbd232;
}

.checkout-cart-total h4:first-child {
  margin-top: 0;
  margin-bottom: 25px;
}

.checkout-cart-total h4:last-child {
  margin-top: 15px;
  margin-bottom: 0;
}

.checkout-cart-total h4 span {
  float: right;
  display: block;
}

.checkout-cart-total ul {
  border-bottom: 1px solid #ffffff;
}

.checkout-cart-total ul li {
  color: #ffffff;
  font-size: 14px;
  line-height: 23px;
  font-weight: 600;
  display: block;
  margin-bottom: 16px;
}

.checkout-cart-total ul li span {
  color: #ffffff;
  float: right;
}

.checkout-cart-total p {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #ffffff;
  padding: 10px 0;
  border-bottom: 1px solid #ffffff;
  margin: 0;
}

.checkout-cart-total p span {
  float: right;
}

/*-- Checkout Payment Method --*/
.checkout-payment-method {
  background-color: #1d2634;
  padding: 45px;
}

@media only screen and (max-width: 575px) {
  .checkout-payment-method {
    padding: 30px;
  }
}

/*-- Single Payment Method --*/
.single-method {
  margin-bottom: 20px;
}

.single-method:last-child {
  margin-bottom: 0;
}

.single-method input[type="radio"] {
  display: none;
}

.single-method input[type="radio"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
}

.single-method input[type="radio"] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #ffffff;
  content: "";
  transition: all 0.3s ease 0s;
}

.single-method input[type="radio"] + label::after {
  position: absolute;
  left: 5px;
  top: 5px;
  display: block;
  content: "";
  opacity: 0;
  background-color: #fbd232;
  width: 10px;
  text-align: center;
  height: 10px;
  transition: all 0.3s ease 0s;
}

.single-method input[type="radio"]:checked + label::before {
  border: 2px solid #fbd232;
}

.single-method input[type="radio"]:checked + label::after {
  opacity: 1;
}

.single-method input[type="checkbox"] {
  display: none;
}

.single-method input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
}

.single-method input[type="checkbox"] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  display: block;
  border: 2px solid #ffffff;
  content: "";
  transition: all 0.3s ease 0s;
}

.single-method input[type="checkbox"] + label::after {
  position: absolute;
  left: 4px;
  top: 4px;
  display: block;
  content: "";
  opacity: 0;
  background-color: #fbd232;
  width: 8px;
  text-align: center;
  height: 8px;
  transition: all 0.3s ease 0s;
}

.single-method input[type="checkbox"]:checked + label::before {
  border: 2px solid #fbd232;
}

.single-method input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

.single-method p {
  display: none;
  margin-top: 8px;
  font-size: 14px;
  color: #ffffff;
  line-height: 23px;
}

/*-- Place Order --*/
.place-order {
  margin-top: 40px;
  float: left;
}

@media only screen and (max-width: 767px) {
  .place-order {
    margin-top: 20px;
  }
}

/*---------------------------------------
    11. Cta CSS
-----------------------------------------*/
/* CTA CSS */
.cta-section {
  position: relative;
  padding-top: 65px;
  padding-bottom: 68px;
  z-index: 99;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-section {
    position: static;
    z-index: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-section {
    position: static;
    z-index: 0;
    padding-top: 55px;
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .cta-section {
    position: static;
    z-index: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.cta-section .row::after {
  content: '';
  display: block !important;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1000px;
  z-index: 0;
  background-color: #fff;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-section .row::after {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-section .row::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .cta-section .row::after {
    display: none;
  }
}

.cta-section .col-md-8 {
  position: relative;
  z-index: 1;
}

.cta-section .col-md-8::before {
  content: '';
  position: absolute;
  top: -200%;
  right: 50px;
  width: 100%;
  height: 1000%;
  background-color: #eee;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-section .col-md-8::before {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-section .col-md-8::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .cta-section .col-md-8::before {
    display: none;
  }
}

.cta-section .col-md-8::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100px 0 100px 60px;
  border-color: transparent transparent transparent #eee;
  position: absolute;
  top: -68px;
  right: -10px;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-section .col-md-8::after {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-section .col-md-8::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .cta-section .col-md-8::after {
    display: none;
  }
}

.cta-section-two {
  position: relative;
  padding-top: 65px;
  padding-bottom: 68px;
  z-index: 99;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-section-two {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .cta-section-two {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.cta-icon {
  position: relative;
  z-index: 9;
}

.cta-icon .icon {
  position: relative;
  width: 40px;
  height: 60px;
  margin: 0 15px;
}

@media only screen and (max-width: 767px) {
  .cta-icon .icon {
    margin: auto;
    margin-bottom: 15px;
  }
}

.cta-icon .icon i {
  font-size: 26px;
  color: #1d2634;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-content {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-content {
    padding-left: 30px;
  }
}

.cta-content.color-white h2 {
  color: #ffffff;
}

.cta-content.color-white p {
  color: #aaaaaa;
  margin-bottom: 0;
}

.cta-content h2 {
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-content h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .cta-content h2 {
    font-size: 24px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .cta-content p {
    text-align: center;
    line-height: 1.3;
    margin-bottom: 20px;
  }
}

/*----------------------------------------*/
/*  16. Faq CSS
/*----------------------------------------*/
.faq-question {
  margin-bottom: 30px;
}

.faq-question i {
  margin-right: 5px;
}

.faq-question a {
  color: #1d2634;
}

.faq-question a:hover {
  color: #fbd232;
}

.faq-accordion.faq-page .card-header a {
  margin-left: 0;
}

.faq-accordion .card {
  border-radius: 0;
  margin-bottom: 10px;
  background-color: transparent;
  border: 2px solid #eeeeee;
}

.faq-accordion .card.actives {
  border-color: #fbd232;
}

.faq-accordion .card.actives .card-header {
  background-color: #fbd232;
  border-color: #fbd232;
}

.faq-accordion .card.actives .card-header a {
  color: #1d2634;
}

.faq-accordion .card-header {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: block;
  background-color: #ffffff;
  border: 0;
  border-radius: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  line-height: 1.2;
  position: relative;
  cursor: pointer;
}

.faq-accordion .card-header i {
  font-size: 17px;
  color: #1d2634;
}

.faq-accordion .card-header a {
  display: block;
  padding: 15px 20px;
  cursor: pointer;
  color: #1d2634;
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .faq-accordion .card-header a {
    font-size: 13px;
  }
}

.faq-accordion .card-header a.collapsed::before {
  font-size: 14px;
  content: "\f078";
  font-family: fontawesome;
  position: absolute;
  right: 15px;
  top: 15px;
  font-weight: 400;
}

.faq-accordion .card-header a.collapsed::after {
  display: none;
}

.faq-accordion .card-header a:after {
  font-size: 14px;
  font-family: fontawesome;
  position: absolute;
  right: 15px;
  top: 12px;
  content: "\f077";
  font-weight: 400;
}

.faq-accordion .card-header a:hover {
  color: #1d2634;
}

.card-body {
  padding: 14px 20px;
}

/*----------------------------------------*/
/*  15. Team CSS
/*----------------------------------------*/
/* Team Intro CSS */
.team-intro-area {
  position: relative;
}

.team-intro-area h2 {
  position: absolute;
  top: -20px;
  right: 240px;
  padding: 30px;
  border-radius: 4px;
  font-size: 24px;
  font-weight: 700;
  background-color: #fbd232;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-intro-area h2 {
    right: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-intro-area h2 {
    right: 30px;
    padding: 20px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .team-intro-area h2 {
    right: 00px;
    padding: 15px;
    font-size: 18px;
  }
}

.team-intro-area h2::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 25px 0 0;
  border-color: transparent;
  border-top-color: #fbd232;
  position: absolute;
  left: 0;
  bottom: -20px;
}

/* Single Team CSS */
.single-team {
  display: flex;
}

.single-team.single-team-grid {
  display: block;
}

.single-team.single-team-grid .team-image {
  max-width: 100%;
  flex-basis: 100%;
  margin-bottom: 20px;
}

.single-team.single-team-grid .team-content {
  width: 100%;
  flex-basis: 100%;
  padding-left: 0;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .single-team {
    display: block;
  }
}

.single-team .team-image {
  max-width: 150px;
  flex-basis: 150px;
}

@media only screen and (max-width: 767px) {
  .single-team .team-image {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

.single-team .team-image img {
  width: 100%;
}

.single-team .team-content {
  width: calc(100% - 150px);
  flex-basis: calc(100% - 150px);
  padding-left: 20px;
}

@media only screen and (max-width: 767px) {
  .single-team .team-content {
    width: 100%;
    flex-basis: 100%;
    padding-left: 0;
    text-align: center;
  }
}

.single-team .team-content h4 {
  font-size: 18px;
  line-height: 22px;
  color: #fbd232;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 0;
}

.single-team .team-content p {
  line-height: 1.5;
  margin-bottom: 10px;
}

.single-team .team-content p strong {
  color: #1d2634;
}

.single-team .team-content .social li {
  display: inline-block;
  margin: 0 5px;
}

.single-team .team-content .social li:first-child {
  margin-left: 0;
}

.single-team .team-content .social li:last-child {
  margin-right: 0;
}

.single-team .team-content .social li a {
  color: #888;
  display: block;
  font-size: 15px;
}

.single-team .team-content .social li a:hover {
  color: #fbd232;
}

/*----------------------------------------*/
/*  18. Maintenance CSS
/*----------------------------------------*/
.maintenance-section {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
  z-index: 9;
  display: flex;
  align-items: center;
}

.maintenance-section::before {
  content: '';
  background-color: #111111;
  opacity: .9;
  z-index: 1;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.site-maintenance {
  text-align: center;
}

.site-maintenance h2 {
  font-size: 36px;
  color: #fbd232;
  line-height: 1.2;
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .site-maintenance h2 {
    font-size: 24px;
  }
}

.site-maintenance h1 {
  font-size: 58px;
  color: #ffffff;
  line-height: 1.2;
  text-align: center;
  font-weight: 700;
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .site-maintenance h1 {
    font-size: 46px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .site-maintenance h1 {
    font-size: 32px;
    margin-bottom: 30px;
  }
}

.site-maintenance p {
  max-width: 555px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.5);
  margin: auto;
  margin-top: 60px;
  margin-bottom: 35px;
}

@media only screen and (max-width: 767px) {
  .site-maintenance p {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}

.site-maintenance .logo-area {
  margin-top: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .site-maintenance .logo-area {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .site-maintenance .logo-area {
    margin-top: 50px;
  }
}

/*----------------------------------------*/
/*  22. My Account CSS
/*----------------------------------------*/
.form-login-title > h2, .form-register-title > h2 {
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 26px;
}

.login-form, .register-form {
  padding: 20px;
  border: 1px solid #d3ced2;
  margin: 30px 0;
  text-align: left;
  border-radius: 5px;
}

.form-fild, .login-submit, .lost-password, .register-submit {
  margin-bottom: 10px;
}

.form-fild label, .login-submit label, .lost-password label, .register-submit label {
  margin-left: 10px;
}

.form-fild p {
  margin: 0;
}

.form-fild p span.required {
  color: #f23838;
}

.form-fild input {
  width: 100%;
  padding: 0 15px;
  height: 45px;
  border: 1px solid #dddddd;
  color: #232323;
  border-radius: 5px;
}

.lost-password > a {
  color: #a43d21;
}

.lost-password > a:hover {
  color: #fbd232;
}

/*---------------------------------------
    10. Blog CSS
-----------------------------------------*/
/*-- Blog --*/
.blog.post-full-item {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.blog.post-full-item .content p {
  line-height: 1.8;
}

.blog.post-full-item .content blockquote {
  border: 2px solid #fbd232;
  border-left-width: 10px;
  padding: 20px;
  font-size: 18px;
  margin: 24px 40px;
}

@media only screen and (max-width: 767px) {
  .blog.post-full-item .content blockquote {
    margin: 10px 0px;
  }
}

.blog .blog-inner.heading-color .content .title {
  color: #1d2634;
}

.blog .blog-inner .blog-image {
  margin-bottom: 40px;
  position: relative;
}

.blog .blog-inner .blog-image .image {
  display: block;
}

.blog .blog-inner .blog-image .image img {
  width: 100%;
}

.blog .blog-inner .blog-image .meta {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  bottom: -20px;
  padding: 7px 0 8px 15px;
  background-color: #ffffff;
}

.blog .blog-inner .blog-image .meta.theme-color {
  background-color: #fbd232;
}

@media only screen and (max-width: 767px) {
  .blog .blog-inner .blog-image .meta.theme-color {
    width: 100%;
  }
}

.blog .blog-inner .blog-image .meta.theme-color::after {
  border-left-color: #fbd232;
}

.blog .blog-inner .blog-image .meta.theme-color li i {
  color: #1d2634;
}

@media only screen and (max-width: 767px) {
  .blog .blog-inner .blog-image .meta {
    width: 100%;
  }
}

.blog .blog-inner .blog-image .meta::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 17px 0 17px 10px;
  border-color: transparent;
  border-left-color: #ffffff;
  position: absolute;
  right: -10px;
  top: 0;
}

@media only screen and (max-width: 767px) {
  .blog .blog-inner .blog-image .meta::after {
    display: none;
  }
}

.blog .blog-inner .blog-image .meta li {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
  color: #1d2634;
  margin-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog .blog-inner .blog-image .meta li {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog .blog-inner .blog-image .meta li {
    margin-right: 20px;
  }
}

.blog .blog-inner .blog-image .meta li:last-child {
  margin-right: 20px;
}

.blog .blog-inner .blog-image .meta li i {
  color: #fbd232;
  margin-right: 5px;
}

.blog .blog-inner .blog-image .meta li a {
  color: #1d2634;
}

.blog .blog-inner .content.blog-grid-content .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #1d2634;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog .blog-inner .content.blog-grid-content .title {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .blog .blog-inner .content.blog-grid-content .title {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.blog .blog-inner .content.blog-grid-content p {
  margin-bottom: 15px;
  line-height: 1.8;
}

.blog .blog-inner .content.blog-grid-content > a {
  font-size: 14px;
  font-weight: 600;
  color: #fbd232;
}

.blog .blog-inner .content.blog-grid-content > a i {
  margin-left: 5px;
}

.blog .blog-inner .content .title {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog .blog-inner .content .title {
    font-size: 14px;
  }
}

.blog .blog-inner .content p {
  margin-bottom: 15px;
  line-height: 1.8;
}

.blog .blog-inner .content > a {
  font-size: 14px;
  font-weight: 600;
  color: #fbd232;
}

.blog .blog-inner .content > a i {
  margin-left: 5px;
}

.blog .blog-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}

@media only screen and (max-width: 767px) {
  .blog .blog-list {
    padding-bottom: 0px;
  }
}

.blog .blog-list .blog-image {
  flex-basis: 33.333%;
  max-width: 33.333%;
  padding-right: 20px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 767px) {
  .blog .blog-list .blog-image {
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 0px;
  }
}

.blog .blog-list .blog-image .image {
  display: block;
}

.blog .blog-list .blog-image .image img {
  width: 100%;
}

.blog .blog-list .content {
  flex-basis: calc(100% - 33.333%);
  max-width: calc(100% - 33.333%);
}

@media only screen and (max-width: 767px) {
  .blog .blog-list .content {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.blog .blog-list .content .meta {
  display: inline-block;
  line-height: 1;
  position: relative;
  padding: 7px 0 8px 15px;
  background-color: #ffffff;
  margin-bottom: 15px;
}

.blog .blog-list .content .meta.theme-color {
  background-color: #fbd232;
}

.blog .blog-list .content .meta.theme-color::after {
  border-left-color: #fbd232;
}

.blog .blog-list .content .meta.theme-color li i {
  color: #1d2634;
}

@media only screen and (max-width: 767px) {
  .blog .blog-list .content .meta {
    width: 100%;
  }
}

.blog .blog-list .content .meta::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 17px 0 17px 10px;
  border-color: transparent;
  border-left-color: #ffffff;
  position: absolute;
  right: -10px;
  top: 0;
}

@media only screen and (max-width: 767px) {
  .blog .blog-list .content .meta::after {
    display: none;
  }
}

.blog .blog-list .content .meta li {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
  color: #1d2634;
  margin-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog .blog-list .content .meta li {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog .blog-list .content .meta li {
    margin-right: 20px;
  }
}

.blog .blog-list .content .meta li:last-child {
  margin-right: 20px;
}

.blog .blog-list .content .meta li i {
  color: #fbd232;
  margin-right: 5px;
}

.blog .blog-list .content .meta li a {
  color: #1d2634;
}

.blog .blog-list .content .title {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog .blog-list .content .title {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .blog .blog-list .content .title {
    font-size: 16px;
  }
}

/*---------------------------------------
    23. Comment CSS
-----------------------------------------*/
/*-- Comment Wrap --*/
.comments-wrapper h3 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .comments-wrapper h3 {
    margin-bottom: 10px;
  }
}

/*-- Comment Form --*/
.comments-form input {
  width: 100%;
  height: 50px;
  border: 2px solid #ddd;
  padding: 5px 20px;
  color: #232323;
}

.comments-form input:focus {
  border: 2px solid #fbd232;
}

.comments-form textarea {
  width: 100%;
  height: 120px;
  border: 2px solid #ddd;
  padding: 10px 20px;
  color: #232323;
  resize: none;
}

.comments-form textarea:focus {
  border: 2px solid #fbd232;
}

.commnent-list-wrap .comment {
  list-style-type: none;
  margin-top: 48px;
  padding-top: 37px;
  padding-left: 6px;
  border-top: 1px solid #eee;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .commnent-list-wrap .comment {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .commnent-list-wrap .comment {
    margin-top: 10px;
    padding-top: 20px;
  }
}

.commnent-list-wrap .comment .thumb {
  float: left;
}

@media only screen and (max-width: 767px) {
  .commnent-list-wrap .comment .thumb {
    float: none;
  }
}

.commnent-list-wrap .comment .thumb img {
  width: 60px;
  height: 60px;
}

.commnent-list-wrap .comment .content {
  position: relative;
  overflow: hidden;
  margin-left: 80px;
}

@media only screen and (max-width: 767px) {
  .commnent-list-wrap .comment .content {
    margin-left: 0;
    margin-top: 30px;
  }
}

.commnent-list-wrap .comment .content .info h6 {
  font-size: 15px;
  font-weight: 500;
}

.commnent-list-wrap .comment .content .reply-btn a {
  color: #fbd232;
  transition: 0.4s;
  display: inline-block;
  text-transform: uppercase;
  margin-left: 10px;
}

.commnent-list-wrap .comment .content .comment-footer span {
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}

.commnent-list-wrap .comment.comment-reply {
  padding-left: 61px;
}

@media only screen and (max-width: 767px) {
  .commnent-list-wrap .comment.comment-reply {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .commnent-list-wrap .comment.comment-reply {
    padding-left: 0;
  }
}

/*---------------------------------------
    24. Sidebar CSS
-----------------------------------------*/
/*-- Sidebar --*/
.sidebar {
  margin-bottom: 50px;
}

.sidebar:last-child {
  margin-bottom: 0;
}

/*-- Sidebar Title --*/
.sidebar-title {
  font-size: 22px;
  line-height: 1;
  font-weight: 700;
  border-bottom: 1px solid #eee;
  padding-bottom: 30px;
  margin-bottom: 25px;
}

/*-- Sidebar Search --*/
.sidebar-search form {
  display: flex;
  border: 1px solid #e5e6e7;
}

.sidebar-search form input {
  flex: 1 0 calc(100% - 40px);
  max-width: calc(100% - 40px);
  height: 40px;
  border: none;
  background-color: transparent;
  padding: 0 15px;
  color: #232323;
}

.sidebar-search form button {
  max-width: 40px;
  flex: 1 0 40px;
  height: 40px;
  line-height: 40px;
  border: none;
  background-color: transparent;
  padding: 0;
  display: block;
}

.sidebar-search form button i {
  font-size: 20px;
}

.sidebar-search form button:hover {
  color: #fbd232;
}

/*-- Sidebar List --*/
.sidebar-list li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e6e7;
}

.sidebar-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0px solid transparent;
}

.sidebar-list li a {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.sidebar-list li a img {
  width: 25px;
  margin-right: 15px;
}

.sidebar-list li a i {
  margin-right: 15px;
  font-size: 24px;
}

.sidebar-list li a:hover {
  color: #fbd232;
}

/*-- Sidebar Blog --*/
.sidebar-blog {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e6e7;
}

.sidebar-blog:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.sidebar-blog .image {
  max-width: 60px;
  flex: 1 0 60px;
}

.sidebar-blog .image img {
  width: 100%;
}

.sidebar-blog .content {
  flex: 1 0 calc(100% - 60px);
  padding-left: 15px;
}

.sidebar-blog .content h5 {
  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
}

.sidebar-blog .content span {
  font-size: 15px;
  display: block;
  line-height: 18px;
  color: #777;
}

/*-- Sidebar Tags --*/
.sidebar-tag {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.sidebar-tag li {
  margin-right: 2px;
  margin-bottom: 8px;
}

.sidebar-tag li a {
  display: block;
  border: 2px solid #ddd;
  padding: 5px 12px;
  font-size: 14px;
}

.sidebar-tag li a:hover {
  background-color: #1d2634;
  border-color: #1d2634;
  color: #fbd232;
}

/*-- Sidebar Product --*/
.sidebar-product {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e6e7;
}

.sidebar-product:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.sidebar-product .image {
  max-width: 80px;
  flex: 1 0 80px;
}

.sidebar-product .image img {
  width: 100%;
  border: 1px solid #f1f2f3;
}

.sidebar-product .content {
  flex: 1 0 calc(100% - 80px);
  padding-left: 15px;
}

.sidebar-product .content .title {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.4;
}

.sidebar-product .content .price {
  font-size: 14px;
  line-height: 1;
  margin: 0;
}

.sidebar-product .content .price span.new {
  font-weight: 700;
  color: #d80000;
  margin-left: 10px;
}

.sidebar-product .content .price span.old {
  color: #232323;
  text-decoration: line-through;
}

.sidebar-product .content .ratting {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.sidebar-product .content .ratting i {
  font-size: 12px;
  line-height: 18px;
  margin-right: 5px;
  color: #fbd232;
}

.sidebar-product .content .ratting i:last-child {
  margin-right: 0;
}

/*----------------------------------------*/
/*  25. Brand CSS
/*----------------------------------------*/
/*-- Brand Slider --*/
.brand-slider .slick-dots, .brand-slider-two .slick-dots, .brand-slider-three .slick-dots {
  display: flex;
  justify-content: center;
}

/*-- Brand --*/
.single-brand {
  background: #fff;
  padding: 10px;
  position: relative;
}

.single-brand.brand-border {
  border: 2px solid #dddddd;
  transition: all 0.3s ease-in-out;
}

.single-brand.brand-border:hover {
  border-color: #fbd232;
}

.single-brand:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 9;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.single-brand .brand-image {
  position: relative;
  overflow: hidden;
}

.single-brand .brand-image img {
  width: 100%;
  height: auto;
}

.single-brand .brand-number {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: scale(0.2, 0.2) translateY(-50%);
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  transition: all 0.3s ease-in-out;
}

.single-brand .brand-number span {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: #1d2634;
}

.single-brand:hover::before {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.single-brand:hover .brand-number {
  opacity: 1;
  visibility: visible;
  transform: scale(1, 1) translateY(-50%);
}

/*---------------------------------------
    26. Contact CSS
-----------------------------------------*/
/*-- Map --*/
.contact-map {
  height: 400px;
  width: 100%;
}

/* Contact Foram Area */
.contact-area, .testimonial-tringle {
  padding: 70px 0;
  position: relative;
  padding-top: 65px;
}

.contact-area.triangle-none, .testimonial-tringle.triangle-none {
  text-align: center;
  padding: 0;
}

.contact-area.triangle-none::before, .contact-area.triangle-none::after, .testimonial-tringle.triangle-none::before, .testimonial-tringle.triangle-none::after {
  display: none;
}

.contact-area.triangle-none .section-title h1, .testimonial-tringle.triangle-none .section-title h1 {
  font-size: 36px;
}

@media only screen and (max-width: 767px) {
  .contact-area.triangle-none .section-title h1, .testimonial-tringle.triangle-none .section-title h1 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-area, .testimonial-tringle {
    padding: 60px 0;
    padding-top: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-area, .testimonial-tringle {
    padding: 50px 0;
    padding-top: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-area, .testimonial-tringle {
    padding: 50px 0;
    padding-top: 45px;
  }
}

.contact-area::before, .testimonial-tringle::before {
  content: '';
  width: 95px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -80px;
  background-color: #111111;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-area::before, .testimonial-tringle::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .contact-area::before, .testimonial-tringle::before {
    display: none;
  }
}

.contact-area::after, .testimonial-tringle::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 300px 0 300px 150px;
  border-color: transparent;
  border-left-color: #111111;
  position: absolute;
  right: -165px;
  top: 50%;
  transform: translate(0, -50%);
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .contact-area::after, .testimonial-tringle::after {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-area::after, .testimonial-tringle::after {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-area::after, .testimonial-tringle::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .contact-area::after, .testimonial-tringle::after {
    display: none;
  }
}

.testimonial-tringle {
  padding-top: 65px;
  padding-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-tringle {
    padding-top: 55px;
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-tringle {
    padding-top: 45px;
    padding-bottom: 50px;
  }
}

/*-- Contact Information --*/
.contact-information h3 {
  margin-bottom: 30px;
}

.contact-information ul li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.contact-information ul li:last-child {
  margin-bottom: 0;
}

.contact-information ul li .icon {
  width: 50px;
}

.contact-information ul li .icon i {
  font-size: 30px;
}

.contact-information ul li .text {
  max-width: calc(100% - 50px);
}

.contact-information ul li .text span, .contact-information ul li .text a {
  display: block;
  line-height: 24px;
  max-width: 230px;
}

/*-- Contact Form --*/
.contact-form input {
  width: 100%;
  height: 50px;
  border: 2px solid #ddd;
  padding: 5px 20px;
  color: #232323;
}

.contact-form input:focus {
  border-color: #fbd232;
  color: #1d2634;
}

.contact-form textarea {
  width: 100%;
  height: 120px;
  border: 2px solid #ddd;
  padding: 10px 20px;
  color: #232323;
}

.contact-form textarea:focus {
  border-color: #fbd232;
  color: #1d2634;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrap.margin-0 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap.margin-0 {
    margin-top: 0px;
  }
}

.contact-form-wrap h2 {
  font-size: 36px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 35px;
}

@media only screen and (max-width: 479px) {
  .contact-form-wrap h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

.contact-form-wrap input {
  width: 100%;
  height: 50px;
  border: 2px solid #dddddd;
  padding: 5px 20px;
  color: #232323;
  height: 45px;
}

.contact-form-wrap input:focus {
  border-color: #fbd232;
}

.contact-form-wrap textarea {
  width: 100%;
  height: 200px;
  border: 2px solid #dddddd;
  padding: 10px 20px;
  color: #232323;
  resize: none;
}

.contact-form-wrap textarea:focus {
  border-color: #fbd232;
}

.contact-form-wrap button {
  margin-top: 20px;
}

.form-messege.success {
  color: #1dbc51;
  font-weight: 700;
}

.form-messege.error {
  color: #ff1313;
}

.emergncy-contact {
  padding: 40px 30px;
  background-color: #111;
  margin-left: 85px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .emergncy-contact {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .emergncy-contact {
    margin-left: 0;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .emergncy-contact {
    margin-left: 0;
    margin-top: 30px;
  }
}

.emergncy-contact h3 {
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .emergncy-contact h3 {
    font-size: 20px;
    margin-bottom: 25px;
  }
}

.emergncy-contact-two p {
  line-height: 1.5;
  margin-bottom: 30px;
}

/*----------------------------------------*/
/*  17. 404 CSS
/*----------------------------------------*/
/* 404 Error CSS */
.error-area {
  text-align: center;
}

.error-area h2 {
  font-size: 60px;
  line-height: 1;
  color: #fbd232;
  font-weight: 700;
}

.error-area h3 {
  font-size: 36px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
}

@media only screen and (max-width: 575px) {
  .error-area h3 {
    font-size: 28px;
  }
}

.error-area p {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .error-area p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

/*----------------------------------------*/
/*  27. Footer CSS
/*----------------------------------------*/
.footer-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1;
}

.footer-section::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(17, 17, 17, 0.9);
  z-index: -1;
}

.footer-top {
  position: relative;
}

/*-- Footer Widget --*/
.footer-widget {
  overflow: hidden;
}

.footer-widget .title {
  position: relative;
  font-size: 20px;
  padding-bottom: 20px;
  margin-bottom: 25px;
  color: #ffffff;
}

.footer-widget .title::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 2px;
  background-color: #fbd232;
}

.footer-widget .title::after {
  content: '';
  height: 2px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 30px);
  left: 30px;
  background-color: #fbd232;
}

.footer-widget .title .text {
  margin-right: 10px;
}

.footer-widget .title .shape {
  height: 5px;
  display: block;
  background-color: rgba(255, 255, 255, 0.3);
  flex: 1 0 auto;
}

.footer-widget img {
  margin-bottom: 20px;
}

.footer-widget p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 2;
}

.footer-widget ul li {
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 12px;
}

.footer-widget ul li:last-child {
  margin-bottom: 0;
}

.footer-widget ul li i {
  margin-right: 20px;
  line-height: 1.5;
  font-size: 16px;
}

.footer-widget ul li span {
  overflow: hidden;
}

.footer-widget ul li a {
  display: block;
}

.footer-widget ul li a:hover {
  color: #fbd232;
}

/*-- Footer Social --*/
.social-menu-right {
  background-color: #fbd232;
  position: absolute;
  left: 0;
  padding-left: 4%;
  bottom: -29px;
  z-index: 1;
}

.social-menu-bottom {
  position: relative;
  display: flex;
}

.social-menu-bottom::before, .social-menu-bottom::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 15px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.social-menu-bottom::before {
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.2);
  left: 2px;
}

.social-menu-bottom::after {
  border-color: transparent;
  border-left-color: #fbd232;
  left: 0;
}

.social-menu-bottom li {
  position: relative;
  width: auto;
  background-color: #fbd232;
  padding: 14px 15px 14px 25px;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.social-menu-bottom li::before, .social-menu-bottom li::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 15px;
  position: absolute;
  top: 0;
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.social-menu-bottom li::before {
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.2);
  right: -17px;
}

.social-menu-bottom li::after {
  border-color: transparent;
  border-left-color: #fbd232;
  right: -15px;
}

.social-menu-bottom li a {
  display: block;
  color: #111;
  font-size: 16px;
  line-height: 21px;
}

.social-menu-bottom li a::before {
  content: '';
  margin-right: 10px;
}

.social-menu-bottom li:hover {
  background-color: #111111;
}

.social-menu-bottom li:hover::after {
  border-left-color: #111111;
}

.social-menu-bottom li:hover a {
  color: #fbd232;
}

/*-- Footer News Letter --*/
.footer-newsletter {
  display: flex;
  flex-wrap: wrap;
}

.footer-newsletter input {
  width: calc(100% - 40px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
  color: #ffffff;
  line-height: 24px;
  padding: 7px 15px;
  font-size: 13px;
}

.footer-newsletter button {
  width: 40px;
  height: 40px;
  border: none;
  background-color: #fbd232;
  color: #ffffff;
}

/*-- Footer Bottom --*/
.footer-bottom {
  padding: 50px 0;
  background-color: #111111;
  padding-bottom: 30px;
}

/*-- Copyright --*/
.copyright p {
  line-height: 30px;
  color: rgba(255, 255, 255, 0.6);
}

.copyright p a:hover {
  color: #fbd232;
}
