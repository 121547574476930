/*----------------------------------------*/
/*  16. Faq CSS
/*----------------------------------------*/
.faq-question{
  margin-bottom: 30px;
  & i{
    margin-right: 5px;
  }
  & a{
    color: $heading-color;
    &:hover{
      color: $theme-color;
    }
  }
}
.faq-accordion {
  &.faq-page{
    & .card-header{
      & a{
        margin-left: 0;
      }
    }
  }
    .card {
      &.actives {
          border-color: $theme-color;
          & .card-header{
            background-color: $theme-color;
            border-color: $theme-color;
              & a{
                  color: $heading-color;
              }
          }
      }
      border-radius: 0;
      margin-bottom: 10px;
      background-color: transparent;
      border: 2px solid #eeeeee;
    }
    .card-header {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      display: block;
      background-color: $white;
      border: 0;
      border-radius: 0;
      padding: 0;
      font-size: 16px;
      font-weight: 700;
      text-decoration: none;
      line-height: 1.2;
      position: relative;
      cursor: pointer;
      & i{
        font-size: 17px;
        color: $heading-color;
      }
      a {
          //Responsive
          @media #{$large-mobile}{
              font-size: 13px;
          }
          &.collapsed{
              &::before {
              font-size: 14px;
              content: "\f078";
              font-family: fontawesome;
              position: absolute;
              right: 15px;
              top: 15px;
              font-weight: 400;
            }
              &::after{
                  display: none;
              }
              
          }
        &:after {
          font-size: 14px;
          font-family: fontawesome;
          position: absolute;
          right: 15px;
          top: 12px;
          content: "\f077";
          font-weight: 400;
        }
        display: block;
        padding: 15px 20px;
        cursor: pointer;
        color: $heading-color;
        font-size: 16px;
        font-weight: 700;
        margin-left: 10px;
        &:hover {
          color: $heading-color;
        }
      }
    }
  }
  .card-body{
      padding: 14px 20px;
  }