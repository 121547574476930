/*----------------------------------------*/
/*  18. Maintenance CSS
/*----------------------------------------*/
.maintenance-section{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    height: 100vh;
    z-index: 9;
    display: flex;
    align-items: center;
    &::before{
        content: '';
        background-color: #111111;
        opacity: .9;
        z-index: 1;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}

.site-maintenance{
    text-align: center;
    & h2{
        font-size: 36px;
        color: $theme-color;
        line-height: 1.2;
        text-align: center;
        font-weight: 700;
        margin-bottom: 10px;
         // Responsive
        @media #{$large-mobile}{
            font-size: 24px;
        }
    }
    & h1{
        font-size: 58px;
        color: $white;
        line-height: 1.2;
        text-align: center;
        font-weight: 700;
        margin-bottom: 60px;
        // Responsive
        @media #{$tablet-device}{
            font-size: 46px;
            margin-bottom: 40px;
        }
        @media #{$large-mobile}{
            font-size: 32px;
            margin-bottom: 30px;
        }
    }
    & p{
        max-width: 555px;
        line-height: 1.5;
        color: rgba(255,255,255,.5);
        margin: auto;
        margin-top: 60px;
        margin-bottom: 35px;
        // Responsive
        @media #{$large-mobile}{
            margin-top: 30px;
            margin-bottom: 15px;
        }
    }
    & .logo-area{
        margin-top: 150px;
        // Responsive
        @media #{$tablet-device}{
            margin-top: 100px;
        }
        @media #{$large-mobile}{
            margin-top: 50px;
        }
    }
}