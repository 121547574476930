/*----------------------------------------*/
/*  15. Team CSS
/*----------------------------------------*/
/* Team Intro CSS */
.team-intro-area{
    position: relative;
    & h2{
        position: absolute;
        top: -20px;
        right: 240px;
        padding: 30px;
        border-radius: 4px;
        font-size: 24px;
        font-weight: 700;
        background-color: $theme-color;
        margin-bottom: 30px;
        // Responsive
        @media #{$desktop-device}{
            right: 150px;
        }
        @media #{$tablet-device}{
            right: 30px;
            padding: 20px;
            font-size: 22px;
        }
        @media #{$large-mobile}{
            right: 00px;
            padding: 15px;
            font-size: 18px;
        }
        &::before{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 25px 25px 0 0;
            border-color: transparent;
            border-top-color: $theme-color;
            position: absolute;
            left: 0;
            bottom: -20px;
        }
    }
}
/* Single Team CSS */
.single-team {
    display: flex;
    &.single-team-grid{
        display: block;
        & .team-image{
            max-width: 100%;
            flex-basis: 100%;
            margin-bottom: 20px;
        }
        & .team-content{
            width: 100%;
            flex-basis: 100%;
            padding-left: 0;
            text-align: center;
        }
    }
    // Responsive 
    @media #{$large-mobile}{
        display: block;
    }
    .team-image {
        max-width: 150px;
        flex-basis: 150px;
        // Responsive 
        @media #{$large-mobile}{
            max-width: 100%;
            flex-basis: 100%;
            margin-bottom: 20px;
        }
        & img{
            width: 100%;
        }
    }
    .team-content{
        width: calc(100% - 150px);
        flex-basis: calc(100% - 150px);
        padding-left: 20px;
        // Responsive 
        @media #{$large-mobile}{
            width: 100%;
            flex-basis: 100%;
            padding-left: 0;
            text-align: center;
        }
        & h4{
            font-size: 18px;
            line-height: 22px;
            color: $theme-color;
            font-weight: 700;
            text-transform: capitalize;
            margin-bottom: 0;
        }
        & p{
            line-height: 1.5;
            margin-bottom: 10px;
            & strong{
                color: $heading-color;
            }
        }
        & .social{
            & li{
                display: inline-block;
                margin: 0 5px;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
                & a{
                    color: #888;
                    display: block;
                    font-size: 15px;
                    &:hover{
                        color: $theme-color;
                    }
                }
            }
        }
    }
}