/*----------------------------------------*/
/*  17. 404 CSS
/*----------------------------------------*/

/* 404 Error CSS */
.error-area{
    text-align: center;
    & h2{
        font-size: 60px;
        line-height: 1; 
        color: $theme-color;
        font-weight: 700;
    }
    & h3{
        font-size: 36px;
        line-height: 1.2;
        font-weight: 700;
        text-transform: uppercase;
        // Responsive
        @media #{$small-mobile}{
            font-size: 28px;
        }
    }
    & p{
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 30px;
        // Responsive
        @media #{$small-mobile}{
            font-size: 15px;
            margin-bottom: 20px;
        }
    }
}
