/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/

/*-- Google Font --*/
@import url('https://fonts.googleapis.com/css?family=Lato:400,700|Montserrat:400,700');

/*-- Common Style --*/
*, *::after, *::before {
  box-sizing: border-box;
}
html, body, .site-wrapper {
    height: 100%;
}
body{
    background-color: $white;
    font-size: 15px;
    line-height: 28px;
    font-style: normal;
    font-weight: normal;
    visibility: visible;
    font-family: $body-font;
    color: $body-color;
    position: relative;
    &[data-rtl="rtl"] {
        direction: rtl;
        text-align: right;
    }
}
h1, h2, h3, h4, h5, h6 {
    font-family: $heading-font;
    color: $heading-color;
    font-weight: 400;
    margin-top: 0;
    line-height: 1.2;
}
h1 {
    font-size: 36px;
    
    // Responsive
    @media #{$large-mobile}{
        font-size: 32px;
    }
    @media #{$small-mobile}{
        font-size: 30px;
    }
}
h2 {
    font-size: 30px;
    
    // Responsive
    @media #{$large-mobile}{
        font-size: 26px;
    }
    @media #{$small-mobile}{
        font-size: 24px;
    }
}
h3 {
    font-size: 24px;
    
    // Responsive
    @media #{$large-mobile}{
        font-size: 22px;
    }
    @media #{$small-mobile}{
        font-size: 20px;
    }
}
h4 {
    font-size: 18px;
}
h5 {
    font-size: 14px;
}
h6 {
    font-size: 12px;
}
p:last-child {
    margin-bottom: 0;
}
a, button {
    color: inherit;
    display: inline-block;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
}
a, button, img, input, span {
    transition: all 0.3s ease 0s;
}
*:focus {
    outline: none !important;
}
a:focus {
    color: inherit;
    outline: none;
    text-decoration: none;
}
a:hover {
    text-decoration: none;
    color: $theme-color;
}
button, input[type="submit"] {
    cursor: pointer;
}
ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
img {
    max-width: 100%;
}

/*-- 
    - Common Classes
-----------------------------------------*/
#scrollUp {
	background: #222;
	width: 60px;
	height: 60px;
	line-height: 60px;
    border-radius: 100%;
	bottom: 25px;
	right: 25px;
	color: #fff;
	text-align: center;
	font-size: 25px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
    &:hover{
        background: $theme-color;
    }
}
.fix {
    overflow:hidden
}
.hidden {
    display: none;
}
.clear{
    clear: both;
}
.section, .main-wrapper {
    float: left;
    width: 100%;
}
.border-bottom{
    border-bottom: 4px solid $theme-color !important;
}
.border-bottom-1{
    border-bottom: 1px solid #eee !important;
}
.demo-text{
    p{
        line-height: 1.5;
    }
    blockquote{
        border: 2px solid $theme-color;
        border-left-width: 10px;
        padding: 20px;
        font-size: 18px;
        margin: 24px 40px;
        // Responsive
        @media #{$tablet-device}{
            margin: 20px 30px;
        }
        // Responsive
        @media #{$large-mobile}{
            margin: 10px 15px;
        }
    }
}

.overlay{
    position: relative;
    z-index: 9;
    background-position: 50% 50%;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.6;
        z-index: -1;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1200px
    }
    .row-five-column {
        & > [class*="col-xl-"] {
            max-width: 20%;
            flex: 0 0 20%;
        }
    }
}
@media #{$small-mobile}{
    .container {
        max-width: 450px;
    }
}
@media #{$extra-small-mobile}{
    .container {
        max-width: 300px;
    }
}
.no-gutters {
    margin-left: 0;
    margin-right: 0;
    & > .col, & > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
        margin: 0 !important;
    }
}


/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder, textarea:-moz-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
input::-moz-placeholder, textarea::-moz-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*--
    - Background Color
------------------------------------------*/
.bg-white {
    background-color: #ffffff !important;
}
.bg-gray {
    background-color: #f8f8f8 !important;
}
.bg-gray-two {
    background-color: #eeeeee !important;
}
.bg-black{
    background-color: #111111;
    opacity: .95;
}
.bg-dark {
    background-color: $heading-color !important;
}
.bg-dark-two {
    background-color: #1c2535 !important;
}
.bg-dark-three {
    background-color: #1d2736 !important;
}
.bg-dark-four {
    background-color: #1d2634 !important;
}
.bg-skyblue{
    background-color: #dfecf7 !important;
}


.bg-theme {
    background-color: $theme-color !important;
}

/*-- 
    - Tab Content & Pane Fix
------------------------------------------*/
.tab-content {
    width: 100%;
    & .tab-pane {
        display: block;
        height: 0;
        max-width: 100%;
        visibility: hidden;
        overflow: hidden;
        opacity: 0;
        &.active {
            height: auto;
            visibility: visible;
            opacity: 1;
            overflow: visible;
        }
    }
}

/*-- 
    - Main Wrapper
------------------------------------------*/
.main-wrapper {}

/*-- 
    - Section Title
------------------------------------------*/
.section-title {
    & h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 1.2;
        margin: 0;
        // Responsive
        @media #{$extra-small-mobile}{
            font-size: 24px;
        }
    }
    & h3{
        font-size: 24px;
        line-height: 1.2;
        font-weight: 700;
    }
    & p {
        margin-top: 15px;
        line-height: 22px;
    }
    &.full-width{
        & p {
            margin-bottom: 35px;
            max-width: 100%;
        }
    }
    &.text-center {
        text-align: center;
    background-position: top center;
        & p {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &.text-left {
        text-align: left;
        background-position: top left;
        & p {
            margin-left: 0;
            margin-right: auto;
        }
    }
    &.text-right {
        text-align: right;
        background-position: top right;
        & p {
            margin-left: auto;
            margin-right: 0;
        }
    }
    &.color-white{
        & h1{
            color: $white;
        }
        & p{
            color: #d2d2d2;
        }
    }
}
.section-title-two{
    & h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 1.2;
        margin: 0;
        // Responsive
        @media #{$tablet-device}{
            font-size: 28px;
        }
        @media #{$large-mobile}{
            font-size: 21px;
        }
        @media #{$extra-small-mobile}{
            font-size: 21px;
        }
        & span{
            color: $theme-color;
        }
    }
}
.section-title-three{
    & h1 {
        font-weight: 700;
        font-size: 36px;
        line-height: 1.2;
        margin: 0;
        // Responsive
        @media #{$large-mobile}{
            font-size: 22px;
        }
    }
    & h2 {
        font-weight: 700;
        font-size: 36px;
        line-height: 1.2;
        margin: 0;
        color: $theme-color;
        // Responsive
        @media #{$large-mobile}{
            font-size: 22px;
        }
    }
    & p {
        margin-top: 15px;
        line-height: 22px;
    }
}

/*-- 
    - Button
------------------------------------------*/
.btn {
    &:focus {
        box-shadow: none;
        outline: none;
    }
    background-color: $theme-color;
    font-family: $heading-font;
    color: $heading-color;
    font-size: 13px;
    line-height: 1.2;
    font-weight: 700;
    padding: 10px 15px;
    text-transform: uppercase;
    border-radius: 0px;
    position: relative;
    transition: all 0.3s ease 0s;
    & i{
        margin-left: 5px;
        font-size: 14px;
    }
    &:hover {
        background-color: $heading-color;
        color: $white;
    }
    // Responsive
    @media #{$large-mobile}{
        font-size: 13px;
        line-height: 1.2;
        padding: 10px 15px;
        &:hover {
            &::before {
                left: 6px;
                top: 6px;
            }
            &::after {
                left: -6px;
                top: -6px;
            }
        }
    }
    @media #{$small-mobile}{
    }
    @media #{$extra-small-mobile}{
    }
}
/* Pentagon Icon CSS */
.pentagon-icon{
    & .icon{
        position: relative;
        width: 50px;
        height: 77px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        background-color: $theme-color;
        border-radius: 6px;
        margin: 0 20px;
        // Responsive
        @media #{$large-mobile}{
            width: 38px;
            height: 57px;
            margin: 0 15px;
        }
        &::before, &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: $theme-color;
            transform: rotate(60deg);
            z-index: -1;
            border-radius: 6px;
        }
        &::after {
            transform: rotate(-60deg);
        }
        & i{
            font-size: 26px;
            color: $heading-color;
        }
    }
}


/*-- 
    - Page Banner Section
------------------------------------------*/
.page-banner-section {
	margin-top: 130px;
    padding: 80px 0 90px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1;
    
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: $black;
        opacity: 0.75;
        z-index: -1;
    }
    // Responsive
    @media #{$tablet-device}{
        margin-top: 122px;
        padding: 60px 0 70px;
    }
    @media #{$large-mobile}{
        margin-top: 122px;
        padding: 40px 0 50px;
    }
    @media #{$small-mobile}{
        margin-top: 163px;
        padding: 25px 0 35px;
    }
    @media #{$extra-small-mobile}{
        margin-top: 151px;
    }
}
/*-- Page Banner --*/
.page-banner {
    & h1 {
        font-size: 48px;
        color: $white;
        font-weight: 500;
        letter-spacing: 0.1px;
        // Responsive
        @media #{$tablet-device}{
            font-size: 36px;
        }
        @media #{$large-mobile}{
            font-size: 30px;
        }
        @media #{$small-mobile}{
            font-size: 24px;
        }
    }
}
/*-- Page Breadcrumb --*/
.breadcrumb-title {
    & h2{
        font-size: 36px;
        font-weight: 700;
        color: $white;
        text-align: left;
        // Responsive
        @media #{$large-mobile}{
            font-size: 24px;
        }
    }
}
.breadcrumb-title-two{
    text-align: center;
    & h2{
        font-size: 60px;
        font-weight: 700;
        color: $white;
        margin-bottom: 20px;
        // Responsive
        @media #{$desktop-device}{
            font-size: 52px;
        }
        @media #{$tablet-device}{
            font-size: 52px;
        }
        @media #{$large-mobile}{
            font-size: 36px;
        }
    }
    & p{
        font-size: 18px;
        color: $white;
        text-align: center;
    }
}

/*-- 
    - Page Pagination
------------------------------------------*/
.page-pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: -5px 0px;
    padding-top: 40px;
    border-top: 1px solid #eee;
    & li {
        font-family: $heading-font;
        text-align: center;
        margin: 5px 15px;
        & a {
            position: relative;
            background-color: $theme-color;
            color: $heading-color;
            width: 30px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            transition: all 0.3s ease-in-out;
            &::before, &::after {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                content: "";
                background-color: $theme-color;
                transform: rotate(60deg);
                z-index: -1;
                border-radius: 6px;
                transition: all 0.3s ease-in-out;
            }
            &::after {
                transform: rotate(-60deg);
            }
            & i {
                font-size: 18px;
                margin-right: 0px;
                line-height: 25px;
                font-weight: 700;
                color: $heading-color;
            }
        }
        &:hover {
            & a {
                color: $white;
                background-color: $heading-color;
                & i{
                    color: $white;
                }
                &::before, &::after{
                    background-color: $heading-color;
                }
            }
        }
    }
}

.grid-filter {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    & button {
        background-color: transparent;
        color: $heading-color;
        border: none;
        padding: 0;
        font-size: 14px;
        font-weight: 600;
        margin: 0 10px 10px;
        text-transform: capitalize;
        line-height: 1;
        padding-bottom: 5px;
        position: relative;
        &::before {
            content: "";
            height: 6px;
            width: 0;
            position: absolute;
            left: 0;
            bottom: 4px;
            background-color: $theme-color;
            z-index: -1;
            transition: all 0.3s ease 0s;
        }
        &:hover, &.active {
            &::before {
                width: 100%;
            }
        }
    }
    
    &.center {
        justify-content: center;
        & button {
            margin: 0 10px 10px;
        }
    }
    &.left {
        justify-content: flex-start;
        & button {
            margin-left: 0;
            margin-right: 20px;
            margin-bottom: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &.right {
        justify-content: flex-end;
        & button {
            margin-left: 20px;
            margin-right: 0;
            margin-bottom: 10px;
            &:last-child {
                margin-left: 0;
            }
        }
    }
}



.slick-slider {
    // & .slick-arrow {
    //     position: absolute;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     z-index: 9;
    //     border: none;
    //     background-color: $theme-color;
    //     color: $white;
    //     padding: 10px;
    //     width: 44px;
    //     height: 44px;
    //     border-radius: 0;
    //     text-align: center;
    //     box-shadow: 0 5px 10px rgba($black, 0.15);
    //     opacity: 0;
    //     &::before {
    //         content: "";
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         width: 100%;
    //         height: 100%;
    //         border: 2px solid $theme-color;
    //         background-color: transparent;
    //         transition: all 0.3s ease 0s;
    //     }
    //     & i {
    //         font-size: 24px;
    //         line-height: 20px;
    //         display: block;
    //     }
    //     &.slick-prev {
    //         left: 0;
    //         margin-left: 40px;
    //     }
    //     &.slick-next {
    //         right: 0;
    //         margin-right: 40px;
    //     }
    //     &:hover {
            
    //     }
    // }
    // &:hover {
    //     & .slick-arrow {
    //         opacity: 1;
    //         &.slick-prev {
    //             margin-left: 20px;
    //         }
    //         &.slick-next {
    //             margin-right: 20px;
    //         }
    //     }
    // }
    
    & .slick-dots {
        width: 100%;
        padding: 0;
        margin: 20px 0 0;
        display: flex;
        justify-content: flex-end;
        padding-right: 15px;
        & li {
            margin: 0 5px;
            & button {
                display: block;
                padding: 0;
                width: 30px;
                height: 5px;
                border: none;
                background-color: $body-color-light;
                text-indent: -9999px;
            }
            &.slick-active {
                & button {
                    background-color: $theme-color;
                }
            }
        }
    }
}


.gallery-item {
    position: relative;
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: $heading-color;
        z-index: 1;
        opacity: 0;
        transition: all 0.3s ease 0s;
    }
    & img {
        width: 100%;
    }
    & .plus {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 3;
        opacity: 0;
        &::before, &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-color: $white;
            transition: all 0.3s ease 0s;
        }
        &::before {
            width: 150px;
            height: 1px;
        }
        &::after {
            width: 1px;
            height: 150px;
        }
    }
    &:hover {
        &::before {
            opacity: 0.75;
        }
        & .plus {
            opacity: 1;
            &::before {
                width: 40px;
            }
            &::after {
                height: 40px;
            }
        }
    }
}


blockquote.blockquote {
    background-color: #f1f2f3;
    padding: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    &::before {
        position: absolute;
        content: '';
        left: -5px;
        top: -10px;
        z-index: -1;
        opacity: 0.07;
    }
    & p {
        font-size: 18px;
        font-style: italic;
    }
    & .author {
        font-size: 14px;
        display: block;
        line-height: 18px;
    }
}