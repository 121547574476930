/*----------------------------------------*/
/*  13. Offer CSS
/*----------------------------------------*/

.about-offer{
    padding-top: 100px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    background-image: url("../images/bg/offer-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // Responsive
    @media #{$tablet-device}{
        padding-top: 55px;
        padding-bottom: 60px;
        padding-left: 0;
        padding-right: 0;
    }
    @media #{$large-mobile}{
        padding-top: 45px;
        padding-bottom: 50px;
        padding-left: 0;
        padding-right: 0;
    }
    & h2{
        font-size: 32px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 30px;
        // Responsive
        @media #{$desktop-device}{
            font-size: 28px;
            margin-bottom: 20px;
        }
        @media #{$large-mobile}{
            font-size: 20px;
            margin-bottom: 15px;
        }
    }
    & p{
        line-height: 1.5;
        margin-bottom: 35px;
        // Responsive
        @media #{$desktop-device}{
            margin-bottom: 25px;
        }
        @media #{$large-mobile}{
            margin-bottom: 15px;
        }
    }
}

.offer-wrap{
    background-color: $theme-color;
    padding-bottom: 30px;
    text-align: center;
    & .offer-image{
        margin-bottom: 10px;
        & img{
            position: relative;
            z-index: 9;
            margin-top: -40px;
            width: 100%;
            // Responsive
            @media #{$tablet-device}{
                margin-top: 0;
            }
            @media #{$large-mobile}{
                margin-top: 0;
            }
        }
    }
    & .offer-content{
        & h3{
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            text-align: center;
            margin-bottom: 40px;
            border-bottom: 1px solid #e6c109;
            padding-top: 10px;
            padding-bottom: 30px;
            // Responsive
            @media #{$desktop-device}{
                font-size: 18px;
            }
            @media #{$large-mobile}{
                font-size: 18px;
            }
        }
        & a{
            background-color: #111111;
            color: $theme-color;
        }
    }
}

/* Single Offer CSS */
.single-offer{
    display: flex;
    align-items: center;
    position: relative;
    // Responsive
    @media #{$tablet-device}{
        justify-content: center;
        margin-bottom: 30px;
    }
    @media #{$large-mobile}{
        margin-bottom: 30px;
    }
    &::before{
        content: '';
        position: absolute;
        height: 300px;
        width: 1000px;
        top: -100%;
        left: -500px;
        background-color: rgba(255,255,255,.1);
        // Responsive
        @media #{$desktop-device}{
            display: none;
        }
        @media #{$tablet-device}{
            display: none;
        }
        @media #{$large-mobile}{
            display: none;
        }
    }
    &::after{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 80px 0 80px 40px;
        border-color: transparent transparent transparent rgba(255,255,255,.1);
        top: 50%;
        right: 30px;
        transform: translate(0,-50%);
        // Responsive
        @media #{$desktop-device}{
            display: none;
        }
        @media #{$tablet-device}{
            display: none;
        }
        @media #{$large-mobile}{
            display: none;
        }
    }
    & .offer-icon{
        padding: 0 15px;
        // Responsive
        @media #{$large-mobile}{
            padding: 0;
            padding-right: 15px;
        }
        & i{
            font-size: 47px;
            color: $theme-color;
        }
    }
    & .offer-content{
        padding: 0 15px;
        // Responsive
        @media #{$large-mobile}{
            padding: 0;
        }
        & .title{
            font-size: 20px;
            line-height: 1.2;
            font-weight: 700;
            color: $white;
            margin-bottom: 0;
            // Responsive
            @media #{$small-mobile}{
                font-size: 16px;
            }
            
        }
    }
}