/*----------------------------------------*/
/*  27. Footer CSS
/*----------------------------------------*/
.footer-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1;
    
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba(17,17,17,.9);
        z-index: -1;
    }
}
.footer-top {
	position: relative;
}

/*-- Footer Widget --*/
.footer-widget {
    overflow: hidden;
    // Title
    & .title {
        position: relative;
        font-size: 20px;
        padding-bottom: 20px;
        margin-bottom: 25px;
        color: $white;
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 20px;
            height: 2px;
            background-color: $theme-color;
        }
        &::after{
            content: '';
            height: 2px;
            position: absolute;
            bottom: 0;
            width: calc(100% - 30px);
            left: 30px;
            background-color: $theme-color;
        }
        & .text {
            margin-right: 10px;
        }
        & .shape {
            height: 5px;
            display: block;
            background-color: rgba($white, 0.3);
            flex: 1 0 auto;
        }
    }
    & img {
        margin-bottom: 20px;
    }
    & p {
        font-size: 14px;
        color: rgba(255,255,255,.8);
        line-height: 2;
    }
    & ul {
        & li {
            color: rgba(255,255,255,.8);
            display: flex;
            font-size: 14px;
            line-height: 1.5;
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
            
            & i {
                margin-right: 20px;
                line-height: 1.5;
                font-size: 16px;
            }
            & span {
                overflow: hidden;
            }
            & a {
                display: block;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}
/*-- Footer Social --*/
.social-menu-right{
    background-color: $theme-color;
    position: absolute;
    left: 0;
    padding-left: 4%;
    bottom: -29px;
    z-index: 1;
}
.social-menu-bottom{
    position: relative;
    display: flex;
    &::before, &::after{
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 25px 0 25px 15px;
        position: absolute;
        top: 0;
        z-index: 1;
    }
    &::before{
        border-color: transparent transparent transparent rgba(255,255,255,.2);
        left: 2px;
    }
    &::after{
        border-color: transparent;
        border-left-color: $theme-color;
        left: 0;
    }
    & li{
        position: relative;
        width: auto;
        background-color: $theme-color;
        padding: 14px 15px 14px 25px;
        font-size: 14px;
        line-height: 1.5;
        margin: 0;
        transition: all .5s cubic-bezier(.215,.61,.355,1);
        &::before, &::after{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 25px 0 25px 15px;
            position: absolute;
            top: 0;
            z-index: 1;
            transition: all .5s cubic-bezier(.215,.61,.355,1);
        }
        &::before{
            border-color: transparent transparent transparent rgba(255,255,255,.2);
            right: -17px;
        }
        &::after{
            border-color: transparent;
            border-left-color: $theme-color;
            right: -15px;
        }
        & a{
            display: block;
            color: #111;
            font-size: 16px;
            line-height: 21px;
            &::before{
                content: '';
                margin-right: 10px;
            }
        }
        &:hover{
            background-color: #111111;
            &::after{
                border-left-color: #111111;
            }
            & a{
                color: $theme-color;
            }
        }
    }
}
/*-- Footer News Letter --*/
.footer-newsletter {
    display: flex;
    flex-wrap: wrap;
    & input {
        width: calc(100% - 40px);
        border: 1px solid rgba($white, 0.3);
        background-color: transparent;
        color: $white;
        line-height: 24px;
        padding: 7px 15px;
        font-size: 13px;
    }
    & button {
        width: 40px;
        height: 40px;
        border: none;
        background-color: $theme-color;
        color: $white;
    }
}

/*-- Footer Bottom --*/
.footer-bottom {
    padding: 50px 0;
    background-color: #111111;
    padding-bottom: 30px;
}
/*-- Copyright --*/
.copyright {
    & p {
        line-height: 30px;
        color: rgba(255,255,255,.6);
        & a {
            &:hover {
                color: $theme-color;
            }
        }
    }
}