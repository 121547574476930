/*---------------------------------------
    11. Cta CSS
-----------------------------------------*/


/* CTA CSS */
.cta-section{
    position: relative;
    padding-top: 65px;
    padding-bottom: 68px;
    z-index: 99;
    overflow: hidden;
    // Responsive
    @media #{$desktop-device}{
        position: static;
        z-index: 0;
    }
    @media #{$tablet-device}{
        position: static;
        z-index: 0;
        padding-top: 55px;
        padding-bottom: 55px;
    }
    @media #{$large-mobile}{
        position: static;
        z-index: 0;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .row{
        &::after{
            content: '';
            display: block !important;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 1000px;
            z-index: 0;
            background-color: #fff;
            z-index: -1;
            // Responsive
            @media #{$desktop-device}{
                display: none;
            }
            @media #{$tablet-device}{
                display: none;
            }
            @media #{$large-mobile}{
                display: none;
            }
        }
    }
    .col-md-8{
        position: relative;
        z-index: 1;
        &::before{
            content: '';
            position: absolute;
            top: -200%;
            right: 50px;
            width: 100%;
            height: 1000%;
            background-color: #eee;
            z-index: -1;
            // Responsive
            @media #{$desktop-device}{
                display: none;
            }
            @media #{$tablet-device}{
                display: none;
            }
            @media #{$large-mobile}{
                display: none;
            }
        }
        &::after{
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 100px 0 100px 60px;
            border-color: transparent transparent transparent #eee;
            position: absolute;
            top: -68px;
            right: -10px;
            z-index: 2;
            // Responsive
            @media #{$desktop-device}{
                display: none;
            }
            @media #{$tablet-device}{
                display: none;
            }
            @media #{$large-mobile}{
                display: none;
            }
        }
    }
}
.cta-section-two{
    position: relative;
    padding-top: 65px;
    padding-bottom: 68px;
    z-index: 99;
    overflow: hidden;
    // Responsive
    @media #{$tablet-device}{
        padding-top: 55px;
        padding-bottom: 55px;
    }
    @media #{$large-mobile}{
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.cta-icon{
    position: relative;
    z-index: 9;
    & .icon{
        position: relative;
        width: 40px;
        height: 60px;
        margin: 0 15px;
        // Responsive
        @media #{$large-mobile}{
           margin: auto;
           margin-bottom: 15px;
        }
        & i{
            font-size: 26px;
            color: $heading-color;
        }
    }
}

.cta-content{
    // Responsive
    @media #{$desktop-device}{
        padding-left: 15px;
    }
    @media #{$tablet-device}{
        padding-left: 30px;
    }
    &.color-white{
        & h2{
            color: $white;
        }
        & p{
            color: #aaaaaa;
            margin-bottom: 0;
        }
    }
    & h2{
        font-size: 32px;
        font-weight: 700;
        text-align: left;
        margin-bottom: 0;
        // Responsive
        @media #{$tablet-device}{
            font-size: 28px;
        }
        @media #{$large-mobile}{
            font-size: 24px;
            text-align: center;
        }
    }
    & p{
        // Responsive
        @media #{$large-mobile}{
            text-align: center;
            line-height: 1.3;
            margin-bottom: 20px;
        }
    }
}